import  React ,{useState,useEffect} from 'react';
import { Box, TextField, Typography,MenuItem,Button ,IconButton}  from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import contries from './Country.json';
import {companyId} from '../../../utils/companyId';
import axios from 'axios';
import {api} from '../../../utils/api';

function Add(props) {
  //     const [state, setState] = useState({
  //   // top: false,
  //   // left: false,
  //   // bottom: false,
  //   right: false,
  // });

  // console.log("companyId",companyId,)
  const roleID = localStorage.getItem("roleId");
  const [facility,setFacility] = useState(["Tap to Select"]);
  const [roles,setRole] = useState(["Tap to Select"]);
  const [FunctionalityRole,setFunctinalityRole] = useState([]);
  const [alreadyUser,setAlreadyUser] = useState(false);
  const [selectedEmail , setEmail] = useState("");
  const [fromValue,setFromValue]= useState({
    company:companyId,
    country:"India",
    facility:facility,
    createdBy: roleID,
    companyName:"",
    name:"",
    email:"",
    phone:"",
    role:roles,
    functionlityOfRole:FunctionalityRole,
   remark:""
    
  })
  const handleEmailChange = (e)=>{
    const {name,value}= e.target
    setEmail(value)
    const trimmedValue = value.trim();
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Check if the email matches the pattern
    console.log(emailPattern.test(trimmedValue),trimmedValue);
    if (emailPattern.test(trimmedValue)) {
      setFromValue({
        ...fromValue,
        [name]: trimmedValue,
      });
      // setErrorMessage(""); // Clear the error message if the email is valid
    } else {
      // setErrorMessage("Invalid email format");
      console.log("Invalid email format")
    }
    
  }
  const handleChange =(e)=>{
    const {name,value} = e.target
    setFromValue({
      ...fromValue,
      [name]: value
    })
  }
  useEffect(() => {
    // if (role.length < 6) {
    //   setRole((prevRole) => [
    //     ...prevRole,
    //     `Role Title${prevRole.length + 1}`,
    //   ]);
    // }
    if (facility.length < 6) {
      setFacility((prevFacility) => [
        ...prevFacility,
        `facility${prevFacility.length + 1}`,
      ]);
    }
  }, [facility]); 
  
  useEffect(()=>{
    // console.log("hiiii")
    if(companyId){
      axios.get(`${api}/company/companies/${companyId}`).then(response => {
        console.log(response.data.companyName)
        setFromValue({...fromValue,
           companyName:response.data.companyName
        })
      
      }
    
    )
      .catch(error => {
        console.error('Error details:', error);
        console.log('URL being requested:', );  // Log the URL to check if it's undefined
      });

     
    }
    const fetchRole = async()=>{
      await  axios.get(`${api}/auth/getRoles`).then((res)=>{
        // console.log(res.data.data[0].allowed_permissions[0].name)
        setFunctinalityRole(res.data.data)
        setRole(res.data.data)
      }).catch((err)=>{
        console.log(err)
      })

    }
    fetchRole();

  },[])
  // console.log(fromValue)
  useEffect(()=>{
    axios.get(
      `${api}/auth/getUserById`,{
      params: {
        Id: props.index // Pass as query parameter
      }
    }).then((res)=>{
      console.log("data after fetch roleId",res.data.data,res.data.data.role.name)
      setFromValue({
         company:companyId,
        country:res.data.data.country,
        facility:facility,
        createdBy: roleID,
        // companyName:"",
        email:res.data.data.email,
        name:res.data.data.name,
        phone:res.data.data.phone,
        role:res.data.data.role.name,
        functionlityOfRole:FunctionalityRole,
       remark:""
        
      })
      setEmail(res.data.data.email)
      setAlreadyUser(true);
      // setRole([res.data.data.role.name])
    }).catch((err)=>{
      console.log(err)
    })
  },[props.index])

  const Submit =()=>{
    console.log("fromValue",fromValue)
    // axios({method:alreadyUser?'put':'post',
    //   url:`${api}/governance/Board-composition${alreadyUser ? `/${form.companyId}` : ""}`,
    //   data:`${alreadyUser?"":fromValue}`,
    //   ${alreadyUser? params:
    //     Id: props.index :""}
    //   })
  // axios.post(`${api}/auth/createUser`,fromValue)
  axios({
    method: alreadyUser ? 'put' : 'post',
    url: alreadyUser?`${api}/auth/updateUser`:`${api}/auth/createUser`,
    data:  fromValue,  // Send data only if it's a POST request
    params: alreadyUser ? { Id: props.index } : null  // Send params only for PUT request
  })
  .then((res)=>{console.log(res)
    props.setValue(false)
  }).catch(err=>console.log(err))
  }
  const handleDropdownChange =(e)=>{
   const {name,value}=e.target
   console.log("nnn",value)
   setFromValue({
    ...fromValue,
    [name]:value
   })
  }
  const handleFunChange =(e)=>{
    
    const{name,value}=e.target
    console.log("vhh",e,name,value)
    setFromValue({
      ...fromValue,
      [name]:value
     })
  }
    const handleCountryChange = (event) => {
      console.log("event.target.value",event.target.value)
      setFromValue({
        ...fromValue,
        country : event.target.value});
    };
    const handlePhoneChange =(e)=>{
      const {name,value}=e.target
      // const numericValue = parseInt(value, /^[0-9]{10}$/) || 0;
      // const phoneNumberPattern = /^[0-9]{10}$/; 
      const phoneNumberPattern = /^[0-9]*$/;
      console.log("phoneNumberPattern.test(value)",
        phoneNumberPattern.test(value)
      )
      if (phoneNumberPattern.test(value) && value.length <= 10) {
       setFromValue({
        ...fromValue,
        [name]:value
       })
      }
    }


  const list = (anchor) => (
 
    <Box display="flex" gap={3} sx={{width:420, display:"flex",justifyContent:"space-btween",
        backgroundColor:"transparent"
    }}>
        <Box sx={{backgroundColor:"#fff",height:"3%",padding:"13px",margin:"30px 0 0 0",display:"flex",justifyContent:"center",alignItems:"center",
            borderRadius:"80%", width:"5%"}}>
        <IconButton onClick={() => props.setValue(false)} sx={{color:"black"}}>
          <CloseIcon sx={{fontSize:"1.2rem"}} />
        </IconButton>
        </Box>
    <Box sx={{width:"90%",p:3, backgroundColor:"#fff"}}>
      <Typography sx={{ textDecoration: 'underline',
          fontWeight: 'bold', 
          
          mb: 2 
       }}>Add New User</Typography>  
    
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Country
          </Typography>
         
          <CustomTextField
      variant="outlined"
       select
    //   label="Select Country"
      value={fromValue.country}
      onChange={handleCountryChange}
      fullWidth
      name="country"
      placeholder="Select Country"
      
    >
      {contries.countries.map((country) => (
        // console.log(country.name,country.code)
        <MenuItem key={country.code} value={country.name}>
          {country.name}
        </MenuItem>
      ))}
    </CustomTextField>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Company Name
          </Typography>
         
          <CustomTextField
      variant="outlined"
      value={fromValue.companyName}
      // onChange={handleCountryChange}
      fullWidth
      name="companyName"
      placeholder="Prefilled"
    />
     <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Employee Name
          </Typography>
         
          <CustomTextField
      variant="outlined"
      value={fromValue.name}
      onChange={handleChange}
      fullWidth
      name="name"
      placeholder="Enter Name"
    />
     <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
             Company Email
          </Typography>
         
          <CustomTextField
      variant="outlined"
      value={selectedEmail}
      onChange={handleEmailChange}
      fullWidth
      name="email"
      placeholder="Enter Email"
    />
     <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Mobile No.
          </Typography>
         
          <CustomTextField
      variant="outlined"
      value={fromValue.phone}
      onChange={handlePhoneChange}
      fullWidth
      name="phone"
      placeholder="Type Here"
    />
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Country of Operation
         </Typography>
         
          <CustomTextField
      variant="outlined"
      select
      value=""
      onChange={handleCountryChange}
      fullWidth
      name="countryOperation"
      placeholder="Tap to Select"
    />
     <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
        Facility     
          </Typography>
         
          <CustomTextField
          select
      variant="outlined"
      value = {fromValue.facility}
      onChange={handleDropdownChange}
      fullWidth
      name="facility"
      placeholder="Tap to Select"
    >
        {facility.map((item,i) => (
        <MenuItem key={i} value={item}>
          {item}
        </MenuItem>
      ))}
    </CustomTextField>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
        Name of Role     
          </Typography>
         
          <CustomTextField
          select
      variant="outlined"
      value={fromValue.role}
      onChange={handleDropdownChange}
      fullWidth
      name="role"
      placeholder="Tap to Select"
    >
        {roles.map((item,i) => (
        <MenuItem key={i} value={item._id}>
          {item.name}
        </MenuItem>
      ))}
    </CustomTextField>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
        Functionality of Role     
          </Typography>

{/* <CustomTextField
  select
  variant="outlined"
  value={fromValue.functionlityOfRole}
  onChange={(event) => handleFunChange(event.target.value)}
  // onChange={handleFunChange}  // Correct way to pass the function
  fullWidth
  name="functionlityOfRole"
  placeholder="Tap to Select"
>
  {FunctionalityRole.length > 0 && FunctionalityRole.map((item, i) => (
    <>
      {item.allowed_permissions.map((permission, index) => (
        <MenuItem key={permission._id} value={permission._id}>
          {permission.name}
        </MenuItem>
      ))}
    </>
  ))}
</CustomTextField> */}
<CustomTextField
  select
  variant="outlined"
  value={fromValue.functionlityOfRole}
  onChange={handleFunChange}  // Correctly passing selected value
  fullWidth
  name="functionlityOfRole"
  placeholder="Tap to Select"
>
  {FunctionalityRole.length > 0 && FunctionalityRole.flatMap((item) =>
    item.allowed_permissions.map((permission) => (
      <MenuItem key={permission._id} value={permission._id}>
        {permission.name}
      </MenuItem>
    ))
  )}
</CustomTextField>

  {/* {FunctionalityRole.length > 0 && FunctionalityRole.map((item, i) => (
    <CustomTextField
    select
    variant="outlined"
    value={fromValue.functionlityOfRole}
    // onChange={(event) => handleFunChange(event.target.value)}
    onChange={handleFunChange}  // Correct way to pass the function
    fullWidth
    name="functionlityOfRole"
    placeholder="Tap to Select"
  >
      {item.allowed_permissions.map((permission, index) => (
        <MenuItem key={permission._id} value={permission._id}>
          {permission.name}
        </MenuItem>
      ))}
 
    </CustomTextField>
  ))} */}


    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
        Remark  
          </Typography>
         
          <TextField
          // select
          rows={2}
      variant="outlined"
      value={fromValue.remark}
       onChange={handleDropdownChange}
      fullWidth
      name="remark"
      placeholder="Brief"
      multiline
    >
      
    </TextField>
       <Box>
       <Button variant="contained" sx={{backgroundColor:"#0a3f73",textTransform:"none",    marginTop: "10%"}} 
            onClick={Submit}

            >
              Submit
            </Button>
       </Box>
    </Box>
    </Box>
  );
 
    return (
        <div>
              <Drawer
               sx={{
                backgroundColor: "transparent",
            '& .MuiDrawer-paper': { // This targets the paper component within the Drawer
                   backgroundColor: "transparent", // Make paper transparent
                   boxShadow: 'none', // Optional: Remove any shadow
                 }
             }}
                anchor="right"
                open={props.value}
                onClose={() => props.setValue(false)}
              >
                {list()}
              </Drawer>
        </div>
      );
}

export default Add;





//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      height: '40px', // Adjust height as needed
    },
    '& .MuiSelect-select': {
      height: '56px', // Adjust height as needed
      display: 'flex',
      alignItems: 'center',
    },
  }));
  