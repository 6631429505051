import React, { useEffect, useState } from 'react';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Select,
  MenuItem,
  FormControl,
  OutlinedInput
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './Cybersecurity.css';
import axios from 'axios';
import { api } from '../../../utils/api';
import { companyId } from "../../../utils/companyId";

const Cybersecurity = () => {
 const navigate = useNavigate();
 const[value,setValue]=useState([{
  high:'Number of Breaches',
  medium: 'Number of Breaches', 
  low:'Number of Breaches'}])
 const [isExist,setisExist] = useState(false); 
 const[formData,setFromData]=useState({
  companyId:companyId,
  cybersecurityIncidents:{
    high:0,
    medium:0,
    low:0,
    total:0
  },
  dataPrivacyBreaches:{
    high:0,
    medium:0,
    low:0,
    total:0
  },
  implementationCybersecurityMeasure:{
    radio:"yes",
    Scope:""
  }
 });
 const handleChangeCybersecurity = (e)=>{
  console.log(e.target.name)
  const {name,value}=e.target
  setFromData({
    ...formData,
    implementationCybersecurityMeasure:{
      ...formData.implementationCybersecurityMeasure,
      [name]:value
    }
  })
 
 }
 const handleChangeDataPrivacy =(e)=>{
  const {name,value}=e.target
  const numericValue = parseInt(value, 10) || 0;

  // Calculate the new total
  const newCybersecurityIncidents = {
    ...formData.dataPrivacyBreaches,
    [name]: numericValue,
  };

  newCybersecurityIncidents.total =
    newCybersecurityIncidents.high +
    newCybersecurityIncidents.medium +
    newCybersecurityIncidents.low;

    setFromData({
    ...formData,
    dataPrivacyBreaches: newCybersecurityIncidents,
 });
 }
 const handleImplementation =(e)=>{
  console.log(e.target.name,e.target.value)
  const {name,value}=e.target
  // setFromData({
  //   ...formData,
  //   cybersecurityIncidents:{
  //     ...formData.cybersecurityIncidents,
  //    [name]:value
  //   }
  // })
  const numericValue = parseInt(value, 10) || 0;

  // Calculate the new total
  const newCybersecurityIncidents = {
    ...formData.cybersecurityIncidents,
    [name]: numericValue,
  };

  newCybersecurityIncidents.total =
    newCybersecurityIncidents.high +
    newCybersecurityIncidents.medium +
    newCybersecurityIncidents.low;

    setFromData({
    ...formData,
    cybersecurityIncidents: newCybersecurityIncidents,
 });
 }
 console.log("data",formData)
useEffect(()=>{
  // const companyId = companyId
  axios.get(`${api}/governance/cybersecurity-dataprivacy/${companyId}`).then((res)=>{
    console.log(res.data)
    setFromData({
      cybersecurityIncidents:{
        high:res.data.cybersecurityIncidents.high,
        medium:res.data.cybersecurityIncidents.medium,
        low:res.data.cybersecurityIncidents.low,
        total:res.data.cybersecurityIncidents.total
      },
      dataPrivacyBreaches:{
        high:res.data.dataPrivacyBreaches.high,
        medium:res.data.dataPrivacyBreaches.medium,
        low:res.data.dataPrivacyBreaches.low,
        total:res.data.dataPrivacyBreaches.total
      },
      implementationCybersecurityMeasure:{
        radio:res.data.implementationCybersecurityMeasure.radio===true?"yes":"no",
        Scope:res.data.implementationCybersecurityMeasure.Scope
      }
     })
     setisExist(true)
  }).catch((err)=>{
    console.log(err)
  })
},[])
console.log("isExist",isExist)
 const Save =()=>{
  // const companyId = companyId
  axios({method: isExist ?'put':'post',
    url:`${api}/governance/cybersecurity-dataprivacy${isExist?`/${companyId}`:""}`,
    data:formData
  }).then((res)=>{
    console.log(res,res.data)
  alert(res.data.message)
  }).catch((err)=>{
    console.log(err)
  // console.log(err)
  })
 }
  const Back = () => {
    navigate('/governance/stakeholder');
  };

  return (
    <Box display="flex" width="100%" className="customer">
      <Sidebar title="Cybersecurity and data Privacy" />
      <Box width="100%" sx={{ p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          GOVERNANCE: CYBERSECURITY AND DATA PRIVACY
        </Typography>

        <Box className="mainEthics">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }} className="heading">
            Cybersecurity Incidents
          </Typography>
          <Box sx={{ display: 'flex', width: '80%', gap: '50px', mb: 3 }}>
          <Box sx={{ display: 'flex',flexDirection: 'column', gap: 2, width: '245px',textTransform:"uppercase" }}>
              {value.map((severity, index) => (
                // console.log("inside map",severity,index,
              <>    {Object.keys(severity).map((item,i)=>{
                  //  {console.log("item",item,i)}
                  return  <TextField
                         key={i}
                        variant="outlined"
                        value={item}
                        // name={}
                        // onChange={handleChangeValue}
                        InputProps={{
                          readOnly: true,
                          
                          // disableUnderline: true,
                          sx: {
                            '& input':{
                            textAlign: 'start',
                            textTransform:'capitalize',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor:'none' ,
                            height: '26px', // Adjust height as needed
                           } },
                          '&:hover': {
                              backgroundColor: 'transparent',
                              border: 'none'
                            },
                            '&:focus': {
                              outline: 'none',
                              border: 'none'
                            }
                          // inputComponent: OutlinedInput,
                        }}
                        sx={{ textAlign: 'center',
                          fontWeight: 'bold',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',cursor:'default' }}
                      />
                  })
                  }  </>
                )
             

              )}
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: 2, width: '80%' }}>
              {value.map((placeholder, index) => (
                //  console.log(Object.values(placeholder),Object(placeholder))
                 <>
                 {
                  Object.keys(placeholder).map(i=>(
                    <TextField
                  key={i}
                  variant="outlined"
                  placeholder={placeholder[i]}
                   name={i}
                  value={formData.cybersecurityIncidents[i]===0?"":formData.cybersecurityIncidents[i]} 
                  className="inputfieldCustomer"
                  onChange={handleImplementation}
                  aria-label={placeholder}
                  fullWidth
                />
                  ))
                 }
                 
                <TextField
                  key={index}
                  variant="outlined"
                  placeholder='Total Breaches'
                  value={formData.cybersecurityIncidents.total===0?"":formData.cybersecurityIncidents.total}
                  className="inputfieldCustomer"
                  // onChange={handleChangeValue}
                  aria-label={placeholder}
                  fullWidth
                />
                </>
              ))}
            </Box>
          </Box>
        </Box>

        <Box className="mainEthics">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 2 }} className="heading">
            Data Privacy Breaches
          </Typography>
          <Box sx={{ display: 'flex', width: '80%', gap: '50px', mb: 3 }}>
              <Box sx={{ display: 'flex',flexDirection: 'column', gap: 2, width: '245px' }}>
              {/* {['High', 'Medium', 'Low'].map((severity, index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  value={severity}
                  InputProps={{
                    readOnly: true,
                    // disableUnderline: true,
                    sx: {
                      textAlign: 'center',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor:'none' ,
                      height: '56px', // Adjust height as needed
                    },
                    '&:hover': {
                        backgroundColor: 'transparent',
                        border: 'none'
                      },
                      '&:focus': {
                        outline: 'none',
                        border: 'none'
                      }
                    // inputComponent: OutlinedInput,
                  }}
                  sx={{ textAlign: 'center',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',cursor:'default' }}
                />
              ))} */}
                {value.map((severity, index) => (
                // console.log("inside map",severity,index,
              <>    {Object.keys(severity).map((item,i)=>{
                  //  {console.log("item",item,i)}
                  return  <TextField
                         key={i}
                        variant="outlined"
                        value={item}
                        // name={}
                        // onChange={handleChangeValue}
                        InputProps={{
                          readOnly: true,
                          
                          // disableUnderline: true,
                          sx: {'& input':{
                            textAlign: 'start',
                            fontWeight: 'bold',
                            textTransform:"capitalize",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor:'none' ,
                            height: '26px', // Adjust height as needed
                          }},
                          '&:hover': {
                              backgroundColor: 'transparent',
                              border: 'none'
                            },
                            '&:focus': {
                              outline: 'none',
                              border: 'none'
                            }
                          // inputComponent: OutlinedInput,
                        }}
                        sx={{ textAlign: 'center',
                          fontWeight: 'bold',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',cursor:'default' }}
                      />
                  })
                  }  </>
                ))}
            </Box>


            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', gap: 2, width: '80%' }}>
            {value.map((placeholder, index) => (
                //  console.log(Object.values(placeholder),Object(placeholder))
                 <>
                 {
                  Object.keys(placeholder).map(i=>(
                    <TextField
                  key={i}
                  variant="outlined"
                  placeholder={placeholder[i]}
                   name={i}
                  value={formData.dataPrivacyBreaches[i]===0?"":formData.dataPrivacyBreaches[i]} 
                  className="inputfieldCustomer"
                  onChange={handleChangeDataPrivacy}
                  aria-label={placeholder}
                  fullWidth
                />
                  ))
                 }
                 
                <TextField
                  key={index}
                  variant="outlined"
                  placeholder='Total Breaches'
                  value={formData.dataPrivacyBreaches.total===0?"":formData.dataPrivacyBreaches.total}
                  className="inputfieldCustomer"
                  // onChange={handleChangeValue}
                  aria-label={placeholder}
                  fullWidth
                />
                </>
              ))}
            </Box>
          </Box>
        </Box>

        <Box className="mainEthics">
          <Typography variant="subtitle1"
           sx={{ fontWeight: 'bold', mb: 2 }} className="heading">
            Implementation of Cybersecurity Measure
          </Typography>
          <RadioGroup row  
          name="radio"
          value={formData.implementationCybersecurityMeasure.radio} 
          onChange={handleChangeCybersecurity}
          sx={{ mb: 2 }}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
       {formData.implementationCybersecurityMeasure.radio==="yes"?  <TextField
            variant="outlined"
            placeholder="Scope..."
            className="brief"
            name='Scope'
            value={formData.implementationCybersecurityMeasure.Scope}
            onChange={handleChangeCybersecurity}
            multiline
            rows={3}
            sx={{ width: '80%' }}
          />:""}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4, width: '80%' }}>
          <Button
            variant="outlined"
            sx={{color:"#0a3f73",borderColor:"#0a3f73",textTransform:"none"}}
            onClick={Back}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={{backgroundColor:"#0a3f73",textTransform:"none"}}
            onClick={Save}
            endIcon={<ArrowForwardIcon />}
          >
            Save and continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Cybersecurity;
