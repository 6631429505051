import React from "react";
import Progressbar from "../Progressbar.js";
 import "./side.css";
import NirantaraLogo from "../Assests/Nirantara-logo.jsx";
function Sidebar(props) {
  return (
    <div className="d-flex flex-column  w-25  sidebar">
      <div className="px-4">
      <NirantaraLogo/>
      </div>
  
 
      <div className="d-flex flex-column gap-2  w-50 progressbar "  style={{fontSize:"14px"}}>
        <div className="d-flex gap-1">
       <Progressbar title={props.title}/>  
        </div>
      </div>
    </div>
  );
}
 
export default Sidebar;

// import React from 'react';
// import { Box, Stack } from '@mui/material';
// import './side.css';
// import NirantaraLogo from '../Assests/Nirantara-logo.jsx'; // Adjust the import path as needed
// import Progressbar from '../Progressbar'; // Adjust the import path as needed

// const Sidebar = (props) => {
//   return (
//     <Box 
//       display="flex" 
//       flexDirection="column" 
//       width="25%" 
//       className="sidebar"
//     >
//       <Box px={4}>
//         <NirantaraLogo />
//       </Box>

//       <Stack 
//         direction="column" 
//         spacing={2} 
//         width="50%" 
//         className="progressbar"
//         sx={{ fontSize: '14px' }}
//       >
//         <Box display="flex" gap={1}>
//           <Progressbar title={props.title} />
//         </Box>
//       </Stack>
//     </Box>
//   );
// };

// export default Sidebar;
