import  React ,{useState,useEffect} from 'react';
import { Box, TextField, Typography,MenuItem,Button ,IconButton}  from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import axios from 'axios';
import { api } from '../../../utils/api';
import { companyId } from '../../../utils/companyId';

function AddRole(props) {
      const [moduleName, setmoduleName] = useState([]);
      const [alreadyRole,setAlreadyUser] = useState(false);
  const [fromValue,setFromValue]= useState({
    company:companyId,
    remark:"",
    name:"",
    allowed_permissions:"Tap to Select"
  })
  useEffect(() => {
axios.get(`${api}/auth/getPermissions`).then((res)=>{
  console.log(res.data.data,"res")
   setmoduleName(res.data.data)
}).catch((err)=>{
  console.log(err,"err")
})
  }, []); 
  useEffect(()=>{
    axios.get(`${api}/auth/getRoleById`,{
      params: {
        Id: props.index // Pass as query parameter
      }
    }).then((res)=>{
      console.log("data after fetch roleId",res.data.data)
      setFromValue({
        company:res.data.data.company,
        remark:"",
        name:res.data.data.name,
        allowed_permissions:res.data.data.allowed_permissions.map((item) => item.name).join(',')       
      })
      setAlreadyUser(props.index===0? false: true)
    }).catch((err)=>{
      console.log(err)
    })
  },[props.index,0])
  console.log("role",props.index)
  const handleChange =(e)=>{
    const {name,value}=e.target
    setFromValue({
      ...fromValue,
      [name]:value
    })
  }
  const handleModule =(e)=>{
    const {name,value}=e.target
  console.log(e.key,value,"forModule")
    setFromValue({
      ...fromValue,
      [name]:value
    })
  }

const Save =()=>{
  console.log(fromValue)
  axios({
    method: alreadyRole ? 'put' : 'post',
    url: alreadyRole?`${api}/auth/updateRole`:`${api}/auth/createRole`,
    data:  fromValue,  // Send data only if it's a POST request
    params: alreadyRole ? { Id: props.index } : null  // Send params only for PUT request
  })
  // axios.post(`${api}/auth/createRole`,fromValue)
  .then((res)=>{
    console.log(res,res.data.data)
    props.setValue(false)
     localStorage.setItem("roleId",res.data.data._id)
  }).catch(err=>{
    console.log(err)
  })
}


  const list = () => (
    <Box display="flex" gap={3} sx={{width:420,height:"100%", display:"flex",justifyContent:"space-btween",
        backgroundColor:"transparent"
    }}>
        <Box sx={{backgroundColor:"#fff",height:"3%",padding:"13px",margin:"30px 0 0 0",display:"flex",justifyContent:"center",alignItems:"center",
            borderRadius:"80%", width:"5%"}}>
        <IconButton onClick={()=>props.setValue(false)} sx={{color:"black"}}>
          <CloseIcon sx={{fontSize:"1.2rem"}} />
        </IconButton>
        </Box>
    <Box sx={{width:"90%",p:3, backgroundColor:"#fff"}}>
      <Typography sx={{ textDecoration: 'underline',
          fontWeight: 'bold', 
          
          mb: 2 
       }}>Add New Role</Typography>  
       
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
        Name of Role     
          </Typography>
         
          <CustomTextField
        //   select
      variant="outlined"
      value={fromValue.name}
      onChange={handleChange}
      fullWidth
      name="name"
      placeholder="Enter Name"
    >
    </CustomTextField>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
       Module's Name    
          </Typography>
         {console.log("console.log(fromValue.allowed_permissions)",fromValue.allowed_permissions)}
          <CustomTextField
           select
      variant="outlined"
       value={fromValue.allowed_permissions}
      onChange={handleModule}
      fullWidth
      name="allowed_permissions"
      placeholder="Tap to Select"
    >
       {moduleName.map((item,i) => (
        // console.log("item",item.name,i)
        <MenuItem key={item._id} value={item._id}>
          {item.name}
        </MenuItem>
      ))}
    </CustomTextField>

    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
        Remark  
          </Typography>
         
          <TextField
          // select
          rows={2}
      variant="outlined"
      value={fromValue.remark}
       onChange={handleChange}
      fullWidth
      name="remark"
      placeholder="Brief"
      multiline
    >
      
    </TextField>
 
       <Box>
       <Button variant="contained" sx={{backgroundColor:"#0a3f73",textTransform:"none",    marginTop: "10%"}} 
             onClick={Save}

            >
              Submit
            </Button>
       </Box>
    </Box>
    </Box>
  );
 
    return (
        <div>
            

              <Drawer
               sx={{
                backgroundColor: "transparent",
            '& .MuiDrawer-paper': { // This targets the paper component within the Drawer
                   backgroundColor: "transparent", // Make paper transparent
                   boxShadow: 'none', // Optional: Remove any shadow
                 }
             }}
                anchor="right"
                open={props.value}
                onClose={()=>props.setValue(false)}
              >
                {list()}
              </Drawer>
              
      
      
        </div>
      );
}

export default AddRole;





//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      height: '40px', // Adjust height as needed
    },
    '& .MuiSelect-select': {
      height: '56px', // Adjust height as needed
      display: 'flex',
      alignItems: 'center',
    },
  }));
  