import { Box, TextField, Typography, MenuItem, Select, InputAdornment } from '@mui/material';
import React from 'react';

const InputCard = ({ 
  title, 
  placeholder, 
  value, 
  name, 
  handleChange, 
  adornmentText, 
  units, 
  unitValue, 
  handleUnitChange ,
}) => {
  return (
    <Box sx={{ mb: 2,width:"100%" }}>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
      <TextField
        fullWidth
        type="text"
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        variant="outlined"
        sx={{
          borderRadius: 1,
          backgroundColor: '#d6d7d8',
          mt: 1,
          '& .MuiInputBase-root': {
            height: 45,
          },
          '& .MuiOutlinedInput-input': {
            padding: '14px',
          },
        }}
        InputProps={{
          endAdornment: (
            <>
              {units && (
                <InputAdornment position="end">
                  <Select
                    name={"totalEnergyConsumption"}
                    value={unitValue} 
                    onChange={(e) => handleUnitChange(e, "totalEnergyConsumption")} 
                    displayEmpty
                    variant="outlined"
                    sx={{
                      height: 45,
                      marginRight: '-14px',
                      borderTopLeftRadius: 0,
                      width: "140px",
                      borderBottomLeftRadius: 0,
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      },
                    }}
                  >
                    <MenuItem  value={""}>Select Unit</MenuItem>
                    {units.map((unit) => (
                      <MenuItem key={unit} value={unit}>{unit}</MenuItem>
                    ))}
                  </Select>
                </InputAdornment>
              )}
              {adornmentText && (
                <InputAdornment position="end" sx={{ borderLeft: '1px solid black', height: '100%' }}>
                  <Typography
                    sx={{
                      px: 2,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      whiteSpace: 'nowrap',
                      marginLeft: '-1px',
                    }}
                  >
                    {adornmentText}
                  </Typography>
                </InputAdornment>
              )}
            </>
          ),
        }}
      />
    </Box>
  );
};

export default InputCard;
