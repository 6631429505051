import React from "react";
import {  Routes, Route } from "react-router-dom";
import User from "../../../Pages/Masters/Roles and Permissions/User";
import Facility from "../../../Pages/Masters/Facility Master/Facility";

function MasterRoutes() {
  return (
          <Routes>
            {/* <Route path="/masters" element={<MasterFilterPage/>} /> */}
            <Route path="/masters/roles-permission" element={<User/>} />
            {/* <Route path="/masters/roles-permission" element={<User/>} /> */}

            <Route path="/masters/facility-master" element={<Facility />} />
            {/* Scope1  */}
            <Route path="/masters/scope1/industrial-process" element={""} />
            <Route path="/masters/scope1/industrial-equipment" element={""} />
            <Route path="/masters/scope1/vehicle-master" element={""} />
            <Route path="/masters/scope1/machinery-master" element={""} />
            {/* Scope2 */}
            <Route path="/masters/scope2/electricity-master/equipment-master" element={""} />
            <Route path="/masters/scope2/heat-master/equipment-master" element={""} />
            <Route path="/masters/scope2/heat-master/vendor-master" element={""} />
            <Route path="/masters/scope2/steam-master/equipment-master" element={""} />
            <Route path="/masters/scope2/steam-master/vendor-master" element={""} />
            {/* Scope3 */}
            <Route path="/masters/scope3/transportation-distribution/logistics-vendor-master" element={""} />
            <Route path="/masters/scope3/transportation-distribution/vehicle-master" element={""} />
            <Route path="/masters/scope3/transportation-distribution/driver-master" element={""} />
            <Route path="/masters/scope3/waste-generated" element={""} />
            <Route path="/masters/scope3/purchased-goods" element={""} />
          </Routes>
  );
}

export default MasterRoutes;
