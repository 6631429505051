



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Checkbox, FormControlLabel } from '@mui/material';
import { api } from '../../../../../utils/api';

const CompanyFilter = ({ onFilterChange }) => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const loadCompanies = async () => {
      try {
        const response = await axios.get(`${api}/auth/getAllUsers`);
        const users = response.data.users;

        const companyNames = users
          .map(user => user.company?.companyName)
          .filter(name => name);
        setCompanies([...new Set(companyNames)]); // Remove duplicates and set state
      } catch (error) {
        console.error('Error fetching companies:', error.response ? error.response.data : error.message);
      }
    };
    loadCompanies();
  }, []);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    onFilterChange(value, checked); // Update the parent component's state on checkbox change
  };

  return (
    <div>
      <h5 style={{margin: '20px 0px 0px 30px', fontWeight: 'bold'}}>Company Name</h5>
      <div style={{ display: 'flex', flexDirection: 'column', margin: '30px' }}>
      {companies.length > 0 ? (
        companies.map((company, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                value={company}
                onChange={handleCheckboxChange}
              />
            }
            label={company || 'Unknown Company'} // Ensure there's a label
          />
        ))
      ) : (
        <p>No companies available.</p>
      )}
      </div>
    </div>
  );
};

export default CompanyFilter;
