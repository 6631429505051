
import React, { useState, useContext, useEffect } from 'react';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ProgressContext } from '../ContextAPI';
import { Button, Box, Typography, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { companyId } from "../../../utils/companyId";
import { api } from "../../../utils/api"; 

const Question = () => {
  const { setUpdate, setTriggerGetRequest } = useContext(ProgressContext);
  const navigate = useNavigate();

  // State for the form fields
  const [challenges, setChallenges] = useState('');
  const [concerns, setConcerns] = useState('');
  const [isExist, setIsExist] = useState(false);


 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api}/company/challenges_and_concerns/${companyId}`);
        console.log('API response:', response.data);
        
        // Update state only if data is available
        if (response.data) {
          setChallenges(response.data.challenges || ''); // Directly set the data
          setConcerns(response.data.concerns || ''); // Directly set the data
          setIsExist(true); // Set existence flag
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []); 
  

  useEffect(() => {
    console.log('Challenges:', challenges);
    console.log('Concerns:', concerns);
  }, [challenges, concerns]);
  

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `${api}/company/future-plans/${companyId}`
  //       );
  //       setChallenges(response.data.challengesAndConcernsData.challenges);
  //       setConcerns(response.data.challengesAndConcernsData.concerns);
  //       setIsExist(true);
  //     } catch (err) {
  //       console.error("Error:", err);
  //       alert(err);
  //     }
  //   };
  //   fetchData();
  // }, []);
  



  // Handle the back button (navigate to the previous page)
  const Back = () => {
      navigate('/sustainability');
      };


  const Save = async () => {
    try {
        const data = {
            challenges,
            concerns,
            companyId: companyId,
        };

        let response;
        if (isExist) {
            // PUT request to update the challenges and concerns data
            response = await axios.put(`${api}/company/challenges_and_concerns/${companyId}`, data);
            alert('Data updated successfully!'); // Show alert for PUT request
        } else {
            // POST request to save the challenges and concerns data
            response = await axios.post(`${api}/company/challenges_and_concerns`, data);
            alert('Data saved successfully!'); // Show alert for POST request
        }

        if (response.status === 200 || response.status === 201) {
            // setUpdate("MATERIAL");
            navigate("/future-plans"); // Navigate to the next page after saving
            // navigate("/sustainability");

        }
    } catch (error) {
        console.error('Error saving data:', error);
        alert('Failed to save data. Please try again.');
    }
};


  return (
    <Box display="flex" width="100%" className="question" sx={{ backgroundColor: "#d6d7d9", minHeight: '100vh' }}>
      <Sidebar title="Challenges & Concern" />

      <Box width="100%" p={3} className="challenge">
        <Typography variant="h5" component="h3" sx={{ mt: -2, mb: 2, fontWeight: 'bold' }}>
          CHALLENGES & CONCERNS
        </Typography>

        <Box mb={3}>
          <Typography variant="body1" sx={{ mb: 1, mt: '25px', width: '70%', fontWeight: 'bold' }}>
            What do you perceive as the main challenges or barriers to implementing sustainable practices within your company?
          </Typography>
          <TextField
            variant="outlined"
            multiline
            rows={3}
            placeholder="Brief"
            name="challenges"
            fullWidth
            value={challenges}
            onChange={(e) => setChallenges(e.target.value)}
            sx={{ backgroundColor: '#d6d7d9', width: '70%', mb: '20px', borderRadius: 1 }}
          />
        </Box>

        <Box mb={3}>
          <Typography variant="body1" sx={{ mb: 1, width: '70%', fontWeight: 'bold' }}>
            Are there any specific concerns or limitations regarding sustainability initiatives that your company faces?
          </Typography>
          <TextField
            variant="outlined"
            multiline
            rows={3}
            placeholder="Brief"
            name="concerns"
            fullWidth
            value={concerns}
            onChange={(e) => setConcerns(e.target.value)}
            sx={{ backgroundColor: '#d6d7d9', width: '70%', mb: '10px', borderRadius: 1 }}
          />
        </Box>

        <Box display="flex" justifyContent="space-between" mt={5}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={Back}
            sx={{ textTransform: 'none' }}
          >
            Back
          </Button>

          <Button
            variant="contained"
            sx={{ bgcolor: "#1C486B", textTransform: "none" }}
            onClick={Save}
          >
            Save and Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Question;
