import React, { useEffect, useState } from 'react';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './Risk.css';
import axios from 'axios';
import { api } from '../../../utils/api';
import { companyId } from "../../../utils/companyId";

const Stakeholder = () => {
  const navigate = useNavigate();
  const[isExist,setisExist]=useState(false);
 const[formData,setFromData]=useState({
  companyId:companyId,
  stakeholderIdentificationEngagementProcesses:{
  radio:"yes",
  Scope:""
  },
  indentificationMitigationEsgRisk:{
    esg_risk:[""],
    mitigation:[""]
  }

 });
 const handleStakeholder=(e)=>{
const{name,value}=e.target;
setFromData({
  ...formData,
  stakeholderIdentificationEngagementProcesses:{
    ...formData.stakeholderIdentificationEngagementProcesses,
    [name]:value
  }
})
 }
 const handleIndentification=(e,index,fieldType)=>{
  const{name,value}=e.target;
//  setFromData({
//   ...formData,
//     indentificationMitigationEsgRisk:{
//    ...formData.indentificationMitigationEsgRisk,
//    [name]:value
//  }
// })
const updatedCommittees = { ...formData.indentificationMitigationEsgRisk };
updatedCommittees[fieldType][index] = value;
setFromData({
  ...formData,
  indentificationMitigationEsgRisk: updatedCommittees,
});
 }
 console.log(formData)
 useEffect(()=>{
  const comapnyId = companyId
  axios.get(`${api}/governance/stakeholder-engagement/${comapnyId}`).then((res)=>{
console.log(res.data)
    setFromData({
  stakeholderIdentificationEngagementProcesses:{
  radio:res.data.stakeholderIdentificationEngagementProcesses.radio===true?"yes":"no",
  Scope:res.data.stakeholderIdentificationEngagementProcesses.Scope
  },
  indentificationMitigationEsgRisk:{
    esg_risk:res.data.indentificationMitigationEsgRisk.esg_risk,
    mitigation:res.data.indentificationMitigationEsgRisk.mitigation
  }

 })
 setisExist(true)
  }).catch(err=>console.log(err))
 },[])
 const AddTextfieldIndentification =(e)=>{
  const {name,value}=e.target
  const updatedCommittees = { ...formData.indentificationMitigationEsgRisk };
  updatedCommittees.esg_risk.push('');
  updatedCommittees.mitigation.push('');

  setFromData({
    ...formData,
    indentificationMitigationEsgRisk: updatedCommittees,
  });
 }
  const Save = () => {
    // const companyId = companyId
    axios({method:isExist?'put':'post',
      url:`${api}/governance/stakeholder-engagement${isExist?`/${companyId}`:""}`,
      data:formData}).then((res)=>{
     console.log(res)
     alert(res.data.message)
     navigate('/governance/cybersecurity');
    }).catch((err)=>{
      console.log(err)
      alert(err.data.message)
    })
    // 
  };

  const Back = () => {
    navigate('/governance/risk-management');
  };

  return (
    <Box display="flex" width="100%" className="risk">
      <Sidebar title="Stakeholder Engagement" />
      <Box width="100%" sx={{ p: 3, mt: 2 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          GOVERNANCE: STAKEHOLDER ENGAGEMENT
        </Typography>

        <Box className="mainEthics">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }} className="heading">
            Stakeholder Identification and Engagement Processes
          </Typography>
          <RadioGroup row
            name="radio" 
          value={formData.stakeholderIdentificationEngagementProcesses.radio}
          onChange={handleStakeholder}
          sx={{ mb: 2 }}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>

     {formData.stakeholderIdentificationEngagementProcesses.radio==='yes'?
      <TextField
      variant="outlined"
      placeholder="Scope..."
      className="brief"
      name='Scope'
      value={formData.stakeholderIdentificationEngagementProcesses.Scope}
      onChange={handleStakeholder}
      multiline
      rows={3}
      sx={{ mb: 3, width: '70%' }}
    />:""}    

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }} className="heading">
            Identification and Mitigation of ESG Risks
          </Typography>
          <Grid container spacing={2} sx={{ mb: 3 ,width:"70%"}}>
      {formData.indentificationMitigationEsgRisk.esg_risk.map((item,i)=>{
        return <>
         <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                placeholder="Description of ESG Risk"
                className="riskText"
                name='esg_risk'
                value={item}
               onChange={(e)=>handleIndentification(e,i,'esg_risk')}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                placeholder="Mitigation"
                className="riskText"
                name='mitigation'
                value={formData.indentificationMitigationEsgRisk.mitigation[i]}
               onChange={(e)=>handleIndentification(e,i,'mitigation')}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
        </>
      })}     
            
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                endIcon={<AddIcon />}
                sx={{ color: "#0a3f73",textTransform:"none" }}
                className="add"
                onClick={AddTextfieldIndentification}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box display="flex" justifyContent="space-between" width="70%" sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            sx={{color:"#0a3f73",borderColor:"#0a3f73",textTransform:"none"}}
            onClick={Back}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={{backgroundColor:"#0a3f73",textTransform:"none"}}
            onClick={Save}
            endIcon={<ArrowForwardIcon />}
          >
            Save and continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Stakeholder;
