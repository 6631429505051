import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import { api } from "../../../utils/api";
import Sidebar from "../sidebar";
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import { companyId } from "../../../utils/companyId";
import InputCard from "./InputCard";
import { getNestedValue } from "../../../utils/getNestedValue";
import AddButton from "./AddButton";

const Pollution = () => {
  const navigate = useNavigate();
  const [isExist, setIsExist] = useState(false);
  const [formData, setFormData] = useState({
    airEmissions: {
      nox: { value: "" },
      sox: { value: "" },
      vocs: { value: "" },
      pm25: { value: "" },
      pm10: { value: "" },
    },
    complianceWithEnvironmentalRegulations: [
      { regulations: "", finesPaid: "" },
    ],
    spillsAndAccidents: [{ spills: "", accidents: "" }],
    companyId,
  });

  // AirEmissions Data used for creating input fields
  const airEmissionsData = [
    {
      title: "Air Emissions",
      placeholder: "NOx",
      name: "airEmissions.nox",
    },
    {
      placeholder: "SOx",
      name: "airEmissions.sox",
    },
    {
      placeholder: "VOCs",
      name: "airEmissions.vocs",
    },
    {
      placeholder: "PM 2.5",
      name: "airEmissions.pm25",
    },
    {
      placeholder: "PM 10",
      name: "airEmissions.pm10",
    },
  ];

  // Navigate back to the biodiversity
  const Back = () => {
    navigate("/environment/biodiversity");
  };

  // Validate the input fields
  const validateForm = () => {
    const {
      airEmissions,
      complianceWithEnvironmentalRegulations,
      spillsAndAccidents,
    } = formData;

    // Validate air emissions
    const areAirEmissionsValid = Object.values(airEmissions).every(
      (item) => item.value !== "" && !isNaN(parseFloat(item.value))
    );

    // Validate compliance with environmental regulations
    const isComplianceValid = complianceWithEnvironmentalRegulations.every(
      (item) => item.regulations !== "" && !isNaN(parseFloat(item.finesPaid))
    );

    // Validate spills and accidents
    const areSpillsAndAccidentsValid = spillsAndAccidents.every(
      (item) => item.spills !== "" && !isNaN(parseFloat(item.accidents))
    );
    // Check if all validations are true
    if (
      areAirEmissionsValid &&
      isComplianceValid &&
      areSpillsAndAccidentsValid
    ) {
      return true;
    } else {
      alert("Please fill in all fields with valid numeric values.");
      return false;
    }
  };

  // Fetching Data if already exists
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/environment/pollution/${companyId}`
        );
        setFormData(response.data.pollutionData || formData);
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
      }
    };
    fetchData();
  }, []);

  // Save the data and make post request
  const Save = async () => {
    console.log(formData);
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/environment/pollution${isExist ? `/${companyId}` : ""}`,
        data: formData,
      });
      // alert(response.data.message);
      navigate("/environment/sustainable-sourcing");
    } catch (err) {
      console.log(err.response.data.message);
      alert(err.response.data.message);    }
  };

  // Handling the input value
  const handleInputChange = (e, index, fieldName) => {
    const { name, value } = e.target;
    if (name.includes("airEmissions")) {
      const [prefix, key] = name.split(".");
      setFormData((prevFormData) => ({
        ...prevFormData,
        airEmissions: {
          ...prevFormData.airEmissions,
          [key]: {
            ...prevFormData.airEmissions[key],
            value: value.trim() || Number(value) || "",
          },
        },
      }));
    } else if (
      name.includes("complianceWithEnvironmentalRegulations") ||
      name.includes("spillsAndAccidents")
    ) {
      const [prefix] = name.split(".");
      setFormData((prevFormData) => {
        const updatedArray = (prevFormData[prefix] || []).map((item, i) => {
          if (i === index) {
            return {
              ...item,
              [fieldName]:
                   value.trim() || Number(value) || "",

            };
          }
          return item;
        });

        return {
          ...prevFormData,
          [prefix]: updatedArray,
        };
      });
    }
  };

  // Handles Add New Array Field
  const handleAddField = (field) => {
    setFormData((prevFormData) => {
      const existingData = prevFormData[field] || [];
      const newItem =
        field === "complianceWithEnvironmentalRegulations"
          ? { regulations: "", finesPaid: "" }
          : { spills: "", accidents: "" };

      return {
        ...prevFormData,
        [field]: [...existingData, newItem],
      };
    });
  };

  return (
    <Box sx={{ display: "flex", backgroundColor: "rgb(214, 215, 216)" }}>
      <Sidebar title="Pollution and Environmental Compliance" />
      <Box
        sx={{
          p: 2,
          ml: 2,
          backgroundColor: "rgb(214, 215, 216)",
          width: "100%",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 5 }}>
          ENVIRONMENT: POLLUTION & ENVIRONMENTAL COMPLIANCE
        </Typography>
        <Box sx={{ mt: 5, width: "70%" }}>
          {/* Maps with the air Emissions Data */}
          {airEmissionsData.map((item, index) => (
            <InputCard
              key={index}
              title={item.title}
              placeholder={item.placeholder}
              name={item.name}
              value={getNestedValue(formData, item.name).value || ""}
              handleChange={handleInputChange}
              adornmentText="metric tons"
            />
          ))}
          <Typography variant="body1" sx={{ fontWeight: "bold", mt: 3 }}>
            Compliance with Environmental Regulations
          </Typography>
          {/* Maps the compliance with Environemnetal regualtions data */}
          {formData.complianceWithEnvironmentalRegulations.map((el, index) => (
            <Box key={index} sx={{ display: "flex", width: "100%", gap: 1 }}>
              <InputCard
                placeholder="Enter the Regulation"
                name={`complianceWithEnvironmentalRegulations.${index}.regulations`}
                value={el.regulations || ""}
                handleChange={(e) => handleInputChange(e, index, "regulations")}
              />
              <InputCard
                placeholder="Enter number of fines paid"
                name={`complianceWithEnvironmentalRegulations.${index}.finesPaid`}
                value={el.finesPaid || ""}
                handleChange={(e) => handleInputChange(e, index, "finesPaid")}
              />
            </Box>
          ))}
          {/* Add buttons for adding array input fields for compliance */}
          <AddButton
            onClick={() =>
              handleAddField("complianceWithEnvironmentalRegulations")
            }
          />
          <Typography variant="body1" sx={{ fontWeight: "bold", mt: 3 }}>
            Spills and Accidents
          </Typography>
          {/* Maps the spills And Accidents data */}
          {formData.spillsAndAccidents.map((item, index) => (
            <Box key={index} sx={{ display: "flex", width: "100%", gap: 1 }}>
              <InputCard
                placeholder="Enter the spills"
                name={`spillsAndAccidents.${index}.spills`}
                value={item.spills || ""}
                handleChange={(e) => handleInputChange(e, index, "spills")}
              />
              <InputCard
                placeholder="Enter number of accidents"
                name={`spillsAndAccidents.${index}.accidents`}
                value={item.accidents || ""}
                handleChange={(e) => handleInputChange(e, index, "accidents")}
              />
            </Box>
          ))}
          {/* Add buttons for adding array input fields for spills and accidents */}
          <AddButton onClick={() => handleAddField("spillsAndAccidents")} />
          {/* Back, Save Buttons */}
          <ActionButtons Back={Back} Move={Save} />
        </Box>
      </Box>
    </Box>
  );
};
export default Pollution;
