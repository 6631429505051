import React from 'react';
import { FormControl, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { Box, Chip, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const MaterialCategory = ({ category, formData, handleChange, renderChips, open, setOpen }) => {
  const selected = Array.isArray(formData[category.key]) ? formData[category.key] : [];

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h8" sx={{ fontWeight: "bold" }}>
        {category.title}
      </Typography>
      {renderChips(selected, category.key)}
      <FormControl
        sx={{
          width: "80%",
          backgroundColor: "rgb(214, 215, 216)",
          border: "1px solid rgb(43, 43, 44)",
          borderRadius: 1,
          mt: 2,
        }}
      >
        <Select
          multiple
          input={<OutlinedInput />}
          value={selected}
          onChange={(e) => handleChange(e, category.key)}
          renderValue={(selectedItems) => {
            if (!Array.isArray(selectedItems)) {
              return null;
            }
            // If there are selected items other than "Select", render them
            const nonSelectItems = selectedItems.filter(item => item !== "Select");
            return nonSelectItems.length > 0 ? nonSelectItems.join(", ") : "Select";
          }}
          open={open[category.key]}
          onOpen={() => setOpen(prev => ({ ...prev, [category.key]: true }))}
          onClose={() => setOpen(prev => ({ ...prev, [category.key]: false }))}
        >
          {category.list.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MaterialCategory;
