import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { api } from "../../../utils/api";
import Sidebar from "../sidebar";
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import { companyId } from "../../../utils/companyId";
import InputCard from "./InputCard";
import { getNestedValue } from "../../../utils/getNestedValue";

const SustainableSourcing = () => {
  const navigate = useNavigate();
  const [isExist, setIsExist] = useState(false);
  const [formData, setFormData] = useState({
    percentageOfSustainableMaterialUsed: "",
    supplierEnvironmentalAssesments: { number: "", percentage: "" },
    initiativesforSustainableSourcing: { number: "", impact: "" },
    companyId,
  });

  // Sustainable data for craeting
  const sustainableSourcingData = [
    {
      title: "Percentage of Sustainable Materials Used",
      placeholder: "10",
      name: "percentageOfSustainableMaterialUsed",
      adornmentText: "%",
    },
    {
      title: "Supplier Environmental Assessments",
      nestedData: [
        {
          name: "supplierEnvironmentalAssesments.number",
          placeholder: "Number of suppliers assessed",
          // adornmentText: "Number",
        },
        {
          name: "supplierEnvironmentalAssesments.percentage",
          placeholder: "% of suppliers assessed",
          adornmentText: "%",
        },
      ],
    },
    {
      title: "Initiatives for Sustainable Sourcing",
      nestedData: [
        {
          placeholder: "Impact of Projects",
          name: "initiativesforSustainableSourcing.impact",
          // adornmentText: "Impact",
        },
        {
          placeholder: "Number of Projects",
          name: "initiativesforSustainableSourcing.number",
          // adornmentText: "Number",
        },
      ],
    },
  ];

  // Navigating back
  const Back = () => {
    navigate("/environment/pollution");
  };

  // validating input fields
  const validateForm = () => {
    const {
      percentageOfSustainableMaterialUsed,
      supplierEnvironmentalAssesments,
      initiativesforSustainableSourcing,
    } = formData;

    if (
      percentageOfSustainableMaterialUsed === "" ||
      isNaN(percentageOfSustainableMaterialUsed) ||
      supplierEnvironmentalAssesments.number === "" ||
      isNaN(supplierEnvironmentalAssesments.number) ||
      supplierEnvironmentalAssesments.percentage === "" ||
      isNaN(supplierEnvironmentalAssesments.percentage) ||
      initiativesforSustainableSourcing.number === "" ||
      isNaN(initiativesforSustainableSourcing.number) ||
      initiativesforSustainableSourcing.impact === ""
    ) {
      alert("Please fill in all fields with valid values.");
      return false;
    }
    return true;
  };

  // fetching data if already exists
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/environment/sustainable-sourcing-and-supply-chain/${companyId}`
        );
        setFormData(response.data.sustainableSourcingData);
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
      }
    };
    fetchData();
  }, []);

  // Save the formData and make the post request
  const Save = async () => {
    console.log(formData);
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/environment/sustainable-sourcing-and-supply-chain${
          isExist ? `/${companyId}` : ""
        }`,
        data: { ...formData, companyId },
      });
      // alert(response.data.message);
      navigate("/social/employee-development");
    } catch (err) {
      console.log(err.response.data.message);
      alert(err.response.data.message);    }
  };

  // Handling the input value
  const handleChange = (e) => {
    const { name, value } = e.target;
    const [prefix, key] = name.split(".");

    setFormData((prevFormData) => {
      if (name === "percentageOfSustainableMaterialUsed")
        return {
          ...prevFormData,
          [name]: value.trim() === "" ? "" : parseFloat(value),
        };
      else
        return {
          ...prevFormData,
          [prefix]: {
            ...prevFormData[prefix],
            [key]: value.trim() || Number(value) || "",

          },
        };
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "rgb(214, 215, 216)",
        height: "100vh",
      }}
    >
      <Sidebar title="Sustainable Sourcing and Supply Chain" />
      <Box sx={{ p: 2, ml: 2, width: "100%" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 5 }}>
          ENVIRONMENT:SUSTAINABLE SOURCING & SUPPLY CHAIN
        </Typography>
        <Box sx={{ mt: 5, width: "70%" }}>
          {/* Maps the sustainable data */}
          {sustainableSourcingData.map((item, index) => (
            <React.Fragment key={index}>
              {item.nestedData ? (
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {item.title}
                  </Typography>
                  <Box sx={{ display: "flex", width: "100%",gap:1 }}>
                    {/* Maps the nested Data */}
                    {item.nestedData.map((el, i) => (
                      <InputCard
                        key={i}
                        placeholder={el.placeholder}
                        name={el.name}
                        value={getNestedValue(formData, el.name)}
                        handleChange={handleChange}
                        adornmentText={el.adornmentText}
                      />
                    ))}
                  </Box>
                </Box>
              ) : (
                <InputCard
                  title={item.title}
                  placeholder={item.placeholder}
                  name={item.name}
                  value={getNestedValue(formData, item.name)}
                  handleChange={handleChange}
                  adornmentText={item.adornmentText}
                />
              )}
            </React.Fragment>
          ))}
          {/* Back, Save Buttons */}
          <ActionButtons Back={Back} Move={Save} />
        </Box>
      </Box>
    </Box>
  );
};

export default SustainableSourcing;
