import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import {
  Description as ScopeIcon,
  SaveAs as SaveAsIcon,
  Close as CloseIcon,
  ExpandMore as ExpandMoreIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
  CheckCircle as CheckCircleIcon,
} from "@mui/icons-material";
import "./MasterSidebar.css";
import { useLocation } from "react-router-dom";


const MasterSidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [openSections, setOpenSections] = useState({
    electricity: false,
    heat: false,
    steam: false,
    transportation: false,
  });

  const [selectedItem, setSelectedItem] = useState(null); // State to keep track of selected item

  const sidebarRoutes = [
    {
      module: "Roles and Permission",
      icon: <SaveAsIcon />,
      path: "/masters/roles-permission",
    },
    {
      module: "Facility Master",
      icon: <SaveAsIcon />,
      path: "/masters/facility-master",
    },
    {
      title: "Scope 1",
      children: [
        {
          module: "Industrial Process Master",
          icon: <ScopeIcon />,
          path: "/masters/scope1/industrial-process",
        },
        {
          module: "Industrial Equipment Master",
          icon: <ScopeIcon />,
          path: "/masters/scope1/industrial-equipment",
        },
        {
          module: "Vehicle Master",
          icon: <ScopeIcon />,
          path: "/masters/scope1/vehicle-master",
        },
        {
          module: "Machinery Master",
          icon: <ScopeIcon />,
          path: "/masters/scope1/machinery-master",
        },
      ],
    },
    {
      title: "Scope 2",
      children: [
        {
          module: "Electricity Master",
          icon: <ScopeIcon />,
          sectionKey: "electricity",
          path: "/masters/scope2/electricity-master",
          children: [
            {
              module: "Equipment Master",
              path: "/masters/scope2/electricity-master/equipment-master",
              parentPath: "/masters/scope2/electricity-master",
            },
          ],
        },
        {
          module: "Heat Master",
          icon: <ScopeIcon />,
          sectionKey: "heat",
          path: "/masters/scope2/heat-master",
          children: [
            {
              module: "Equipment Master",
              path: "/masters/scope2/heat-master/equipment-master",
              parentPath: "/masters/scope2/heat-master",
            },
            {
              module: "Vendor Master",
              path: "/masters/scope2/heat-master/vendor-master",
              parentPath: "/masters/scope2/heat-master",
            },
          ],
        },
        {
          module: "Steam Master",
          icon: <ScopeIcon />,
          sectionKey: "steam",
          path: "/masters/scope2/steam-master",
          children: [
            {
              module: "Equipment Master",
              path: "/masters/scope2/steam-master/equipment-master",
              parentPath: "/masters/scope2/steam-master",
            },
            {
              module: "Vendor Master",
              path: "/masters/scope2/steam-master/vendor-master",
              parentPath: "/masters/scope2/steam-master",
            },
          ],
        },
      ],
    },
    {
      title: "Scope 3",
      children: [
        {
          module: "Transportation and Distribution",
          icon: <ScopeIcon />,
          sectionKey: "transportation",
          path: "/masters/scope3/transportation-distrubution",
          children: [
            {
              module: "Logistics Vendor Master",
              path: "/masters/scope3/transportation-distribution/logistics-vendor-master",
              parentPath: "/masters/scope3/transportation-distribution",
            },
            {
              module: "Vehicle Master",
              path: "/masters/scope3/transportation-distribution/vehicle-master",
              parentPath: "/masters/scope3/transportation-distribution",
            },
            {
              module: "Driver Master",
              path: "/masters/scope3/transportation-distribution/driver-master",
              parentPath: "/masters/scope3/transportation-distribution",
            },
          ],
        },
        {
          module: "Waste Generated in Operations",
          icon: <ScopeIcon />,
          path: "/masters/scope3/waste-generated",
        },
        {
          module: "Purchased Goods and Services",
          icon: <ScopeIcon />,
          path: "/masters/scope3/purchased-goods",
        },
      ],
    },
  ];

console.log(location.pathname)
  const handleSectionClick = (section) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [section]: !prevOpenSections[section],
    }));
  };

  const handleItemClick = (route) => {
    console.log(route);
    if (selectedItem === route.path) {
      setSelectedItem(null);
      if (route.parentPath) {
        console.log(route.parentPath);
        navigate(route.parentPath); // Navigate to parent route
      }
    } else {
      // select the item and navigate to the current route
      setSelectedItem(route.path);
      navigate(route.path);
    }
  };

  const handleCloseIconClick = (parentPath) => {
    if (parentPath) {
      navigate(parentPath); // Navigate to the upper route when clicking close icon
    }
  };

  const getClassName = (route) => {
    return selectedItem === route.path && !route.parentPath ? "active" : "";
  };

  const renderSidebarItems = (routes) => {
    return routes.map((route) => {
      // Handle sections with nested items
      if (route.children) {
        const hasSectionKey = route.sectionKey;

        return (
          <div key={route.module} sx={{ width: "80%", py: "5px" }}>
            {route.title && (
              <Typography
                variant="body2"
                sx={{ color: "#ffffff",fontSize:"10px",       // Adjust line height to avoid extra space
                mt: 2, ml: 3,mb:0 }}
              >
                {route.title}
              </Typography>
            )}
            <ListItem
              button
              onClick={() =>
                hasSectionKey && handleSectionClick(route.sectionKey)
              }
              className={`list-item ${
                openSections[route.sectionKey] ? "active" : ""
              }`}
              sx={{mt:0}}

            >
              <ListItemIcon
                sx={{
                  minWidth: "35px",
                  fontSize: "15px",
                  color: openSections[route.sectionKey] ? "#488257" : "white",
                   }}
                 
              >
                {route.icon}
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ fontSize: "13px" }}
                primary={route.module}
                sx={{
                  color: openSections[route.sectionKey] ? "#488257" : "white",
                  mt: 1,
                  fontSize: "15px",
                }} // Apply fontSize here
              />
              {hasSectionKey &&
                (openSections[route.sectionKey] ? (
                  <CloseIcon
                    sx={{
                      color: "#488257",
                      fontSize: "15px",
                      mt: 1,
                      minWidth: "30px",
                    }}
                    onClick={() => handleCloseIconClick(route.parentPath)}
                  />
                ) : (
                  <ExpandMoreIcon
                    sx={{
                      color: "#ffffff",
                      fontSize: "20px",
                      minWidth: "30px",
                    }}
                  />
                ))}
            </ListItem>
            <Collapse
              in={hasSectionKey ? openSections[route.sectionKey] : true}
              timeout="auto"
              unmountOnExit
              sx={{mb:0}}
            >
              <List component="div" disablePadding>
                {renderSidebarItems(route.children)}{" "}
                {/* Recursively render nested items */}
              </List>
            </Collapse>
          </div>
        );
      }

      // Render normal menu items
      return (
        <ListItem
          component={Link}
          to={route.path}
          key={route.module}
          className={`list-item ${
            ((selectedItem === route.path  || location.pathname===route.path) && !route.parentPath )? "active" : ""
          }`}
          onClick={() => handleItemClick(route)}
        
        >
          <ListItemIcon
            sx={{
              minWidth: "40px",
              color:selectedItem === route.path || location.pathname===route.path? "#488257" : "white"
            }}
            >
            {route.icon ? (
              route.icon
            ) : selectedItem === route.path || location.pathname===route.path? (
              <CheckCircleIcon
                sx={{ fontSize: "15px", color: "#ffffff", minWidth: "30px" }}
              />
            ) : (
              <RadioButtonUncheckedIcon
                sx={{ fontSize: "15px", minWidth: "30px" }}
               />
            )}
          </ListItemIcon>
          <ListItemText
            primary={route.module}
            primaryTypographyProps={{ fontSize: "13px" }}
            className={`list-item-text ${getClassName(route)}`}
          />
        </ListItem>
      );
    });
  };

  return (
    <nav>
      <Drawer
        variant="permanent"
        open
        className="sidebar"
        sx={{
          "& .MuiDrawer-paper": {
            width: "18%",
            backgroundColor: "#488257",
            // px:1,
            ml: "50px",
          },
        }}
      >
         <Box sx={{ width: "80%",ml:"10%" ,mt:"20%" }}>
        <Typography
          variant="h6"
          sx={{
            color: "white",
            borderBottom: "2px solid white",
            // paddingBottom: "10px",
            fontSize: "18px", // Font size for "Masters" heading
          }}
        >
          Master
        </Typography>
      </Box>
      <List>{renderSidebarItems(sidebarRoutes)}</List>
      </Drawer>
    </nav>
  );
};

export default MasterSidebar;
