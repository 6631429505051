import { Box } from "@mui/material";
import { useState } from "react";
import FilterSidebar from "./FilterSidebar";
import CheckboxDrawer from "../../../Pages/Masters/Roles and Permissions/Filters/Checkbox";
// import DateRangePickerComponent from "../../../Pages/Masters/Roles and Permissions/Filters/Date";
import DateRangePickerComponent from "../../../Pages/Masters/Roles and Permissions/Filters/Date";

const FilterPage = ({ setFilteredRoles, setDrawerFilterOpen,handleApplyFilter}) => {
  const [selectedFilter, setSelectedFilter] = useState("Date"); // Default filter is "Date"
  
  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent 
            setDrawerFilterOpen={setDrawerFilterOpen}
            setFilteredRoles={setFilteredRoles}
          />
        ); // Date range picker component
      case "Role":
        return <CheckboxDrawer   setDrawerFilterOpen={setDrawerFilterOpen}
        setFilteredRoles={setFilteredRoles}/>; // Checkbox filter for roles
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: "flex", mt: 0, width: "100%" }}>
      {/* Sidebar for selecting the filter */}
      <FilterSidebar onSelect={setSelectedFilter} />
      
      {/* Filter content area */}
      <Box sx={{ flexGrow: 1, backgroundColor: "#F9F9F9", padding: "20px" }}>
        {renderSelectedFilter()}
      </Box>
    </Box>
  );
};

export default FilterPage;
