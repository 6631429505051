import React, { useState, useContext, useEffect} from 'react';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import { ProgressContext } from '../ContextAPI';
import { TextField, Button, Box, Typography, TextareaAutosize } from '@mui/material';
import { Add as AddIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import EastIcon from '@mui/icons-material/East';
import axios from 'axios';
import { companyId } from "../../../utils/companyId";
import { api } from "../../../utils/api";

const SupplyChain = () => {
    const { setUpdate } = useContext(ProgressContext);
    const navigate = useNavigate();

    // State to manage form data
    const [numberOfSuppliersAssessed, setNumberOfSuppliersAssessed] = useState('');
    const [percentageOfSuppliersAssessed, setPercentageOfSuppliersAssessed] = useState('');

    // State for fair labor practices
    const [fairLaborPractices, setFairLaborPractices] = useState([{ having: '', notHaving: '' }]);

    // State for child labor practices
    const [childLaborPractices, setChildLaborPractices] = useState([{ having: '', notHaving: '' }]);
    const [isExist, setIsExist] = useState(false);

    useEffect(() => {
        const fetchSupplyChainData = async () => {
            try {
                const response = await axios.get(`${api}/social/supply_chain/${companyId}`);
                const supplyChainData = response.data;
    
                // Populate the form fields with the data from the API
                setNumberOfSuppliersAssessed(supplyChainData.supplierSocialAssessments.numberOfSuppliersAssessed);
                setPercentageOfSuppliersAssessed(supplyChainData.supplierSocialAssessments.percentageOfSuppliersAssessed.value);
                setFairLaborPractices(supplyChainData.fairLaborPractices.map(practice => ({
                    having: practice.numberOfSuppliersHavingFairLaborPractices,
                    notHaving: practice.numberOfSuppliersNotHavingFairLaborPractices
                })));
                setChildLaborPractices(supplyChainData.childLaborPractices.map(practice => ({
                    having: practice.numberOfSuppliersHavingFairLaborPractices,
                    notHaving: practice.numberOfSuppliersNotHavingFairLaborPractices
                })));
                setIsExist(true);
            } catch (error) {
                console.error('Error fetching Supply Chain Social Responsibility data:', error);
                // You may want to handle this error or show an alert to the user.
            }
        };
        // Only fetch data if a companyId exists
        if (companyId) {
            fetchSupplyChainData();
        }
    }, [companyId]);
    

    // Function to handle adding new fields
    const addFairLaborPractice = () => {
        setFairLaborPractices([...fairLaborPractices, { having: '', notHaving: '' }]);
    };

    const addChildLaborPractice = () => {
        setChildLaborPractices([...childLaborPractices, { having: '', notHaving: '' }]);
    };

    // Function to handle Save and Continue
    const Save = async () => {
        const payload = {
            supplierSocialAssessments: {
                numberOfSuppliersAssessed: Number(numberOfSuppliersAssessed),
                percentageOfSuppliersAssessed: {
                    value: Number(percentageOfSuppliersAssessed),
                    unit: '%'
                }
            },
            fairLaborPractices: fairLaborPractices.map(practice => ({
                numberOfSuppliersHavingFairLaborPractices: Number(practice.having),
                numberOfSuppliersNotHavingFairLaborPractices: Number(practice.notHaving)
            })),
            childLaborPractices: childLaborPractices.map(practice => ({
                numberOfSuppliersHavingFairLaborPractices: Number(practice.having),
                numberOfSuppliersNotHavingFairLaborPractices: Number(practice.notHaving)
            })),
            companyId: companyId
        };

        try {
          if (isExist) {
              // PUT request
              const response = await axios.put(`${api}/social/supply_chain/${companyId}`, payload);
              alert('Supply Chain Social Responsibility record updated successfully.');
              console.log('Updated Record:', response.data);
          } else {
              // POST request
              const response = await axios.post(`${api}/social/supply_chain`, payload);
              alert('Supply Chain Social Responsibility record created successfully.');
              console.log('Created Record:', response.data);
          }
          navigate("/governance/board-composition");
          setUpdate("GOVERNANCE");
      } catch (error) {
          console.error('Error saving Supply Chain Social Responsibility data:', error);
          alert('Error saving Supply Chain Social Responsibility data. Please try again.');
      }


    };

    

    const Back = () => {
        navigate("/social/customer-relation");
    };

    return (
        <Box display="flex" sx={{ height: '100%', backgroundColor: 'rgb(214, 215, 216)' }}>
            <Sidebar title="Supply chain social responsibility" />
            <Box sx={{ width: '100%', padding: '20px' }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', margin: '32px 0px' }}>
                    SOCIAL: SUPPLY CHAIN SOCIAL RESPONSIBILITY
                </Typography>

                {/* Supplier Social Assessments */}
                <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', margin: '2px' }}>
                    Supplier Social Assessments
                </Typography>
                <Box display="flex" gap={2} mb={2}>
                    <TextField
                        variant="outlined"
                        placeholder="Number of suppliers assessed"
                        value={numberOfSuppliersAssessed}
                        onChange={(e) => setNumberOfSuppliersAssessed(e.target.value)}
                        sx={{
                            width: '36%',
                            backgroundColor: '#d6d7d8',
                            borderRadius: '4px',
                            borderColor: '#6b6c6d',
                            '& .MuiOutlinedInput-root': {
                                height: '50px',
                                '& fieldset': {
                                    borderColor: '#6b6c6d',
                                },
                            },
                        }}
                        InputProps={{
                            sx: {
                                padding: '0 10px',
                            },
                        }}
                    />
                    <TextField
                        variant="outlined"
                        placeholder="% of suppliers assessed"
                        value={percentageOfSuppliersAssessed}
                        onChange={(e) => setPercentageOfSuppliersAssessed(e.target.value)}
                        sx={{
                            width: '36%',
                            backgroundColor: '#d6d7d8',
                            borderRadius: '4px',
                            borderColor: '#6b6c6d',
                            '& .MuiOutlinedInput-root': {
                                height: '50px',
                                '& fieldset': {
                                    borderColor: '#6b6c6d',
                                },
                            },
                        }}
                        InputProps={{
                            sx: {
                                padding: '0 10px',
                            },
                        }}
                    />
                </Box>

                {/* Fair Labor Practices */}
                <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', margin: '20px 0px 2px 0px' }}>
                    Fair Labor Practices in Supply Chain
                </Typography>
                {fairLaborPractices.map((practice, index) => (
                    <Box key={index} display="flex" gap={2} mb={2}>
                        <TextareaAutosize
                            minRows={3}
                            placeholder="No. of suppliers having fair labor practices"
                            value={practice.having}
                            onChange={(e) => {
                                const updatedPractices = [...fairLaborPractices];
                                updatedPractices[index].having = e.target.value;
                                setFairLaborPractices(updatedPractices);
                            }}
                            style={{
                                width: '36%',
                                padding: '8px',
                                backgroundColor: '#d6d7d8',
                                borderRadius: '4px',
                                borderColor: '#6b6c6d',
                            }}
                        />
                        <Box display="flex" flexDirection="column" gap={2} alignItems="flex-end" width="36%">
                            <TextareaAutosize
                                minRows={3}
                                placeholder="No. of suppliers not having fair labor practices"
                                value={practice.notHaving}
                                onChange={(e) => {
                                    const updatedPractices = [...fairLaborPractices];
                                    updatedPractices[index].notHaving = e.target.value;
                                    setFairLaborPractices(updatedPractices);
                                }}
                                style={{
                                    width: '100%',
                                    padding: '8px',
                                    backgroundColor: '#d6d7d8',
                                    borderRadius: '4px',
                                    borderColor: '#6b6c6d',
                                }}
                            />
                        </Box>
                    </Box>
                ))}
                <Button variant="outlined" startIcon={<AddIcon />}
                    onClick={addFairLaborPractice}
                    sx={{
                        alignSelf: 'flex-start',
                        color: '#204769',
                        borderColor: '#204769',
                        marginLeft: '65%',
                        '&:hover': {
                            backgroundColor: 'rgba(25, 118, 210, 0.04)',
                            color: '#115293',
                            borderColor: '#115293',
                        },
                    }}
                >
                    Add
                </Button>

                {/* Child Labor Practices */}
                <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', margin: '20px 0px 2px 0px' }}>
                    Child Labor Practices in Supply Chain
                </Typography>
                {childLaborPractices.map((practice, index) => (
                    <Box key={index} display="flex" gap={2} mb={2}>
                        <TextareaAutosize
                            minRows={3}
                            placeholder="No. of suppliers having fair labor practices"
                            value={practice.having}
                            onChange={(e) => {
                                const updatedPractices = [...childLaborPractices];
                                updatedPractices[index].having = e.target.value;
                                setChildLaborPractices(updatedPractices);
                            }}
                            style={{
                                width: '36%',
                                padding: '8px',
                                backgroundColor: '#d6d7d8',
                                borderRadius: '4px',
                                borderColor: '#6b6c6d',
                            }}
                        />
                        <Box display="flex" flexDirection="column" gap={2} alignItems="flex-end" width="36%">
                            <TextareaAutosize
                                minRows={3}
                                placeholder="No. of suppliers not having fair labor practices"
                                value={practice.notHaving}
                                onChange={(e) => {
                                    const updatedPractices = [...childLaborPractices];
                                    updatedPractices[index].notHaving = e.target.value;
                                    setChildLaborPractices(updatedPractices);
                                }}
                                style={{
                                    width: '100%',
                                    padding: '8px',
                                    backgroundColor: '#d6d7d8',
                                    borderRadius: '4px',
                                    borderColor: '#6b6c6d',
                                }}
                            />
                        </Box>
                    </Box>
                ))}
                <Button variant="outlined" startIcon={<AddIcon />}
                    onClick={addChildLaborPractice}
                    sx={{
                        alignSelf: 'flex-start',
                        color: '#204769',
                        borderColor: '#204769',
                        marginLeft: '65%',
                        '&:hover': {
                            backgroundColor: 'rgba(25, 118, 210, 0.04)',
                            color: '#115293',
                            borderColor: '#115293',
                        },
                    }}
                >
                    Add
                </Button>

                {/* Buttons for Save and Back */}
                <Box display="flex" justifyContent="space-between" marginTop="20px">
                    <Button
                        variant="outlined"
                        startIcon={<ArrowBackIcon />}
                        onClick={Back}
                        sx={{
                            color: '#204769',
                            borderColor: '#204769',
                            '&:hover': {
                                backgroundColor: 'rgba(25, 118, 210, 0.04)',
                                color: '#115293',
                                borderColor: '#115293',
                            },
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        variant="contained"
                        endIcon={<EastIcon />}
                        onClick={Save}
                        sx={{
                            backgroundColor: '#1C486B',
                            '&:hover': {
                                backgroundColor: '#163B57',
                            },
                        }}
                    >
                        Save and Continue
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default SupplyChain;
