


// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Button, TextField, Box, Typography, InputAdornment } from '@mui/material';
// import AddIcon from '@mui/icons-material/Add';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import EastIcon from '@mui/icons-material/East';
// import Sidebar from '../sidebar';
// import { useNavigate } from 'react-router-dom';
// import { companyId } from "../../../utils/companyId";
// import { api } from "../../../utils/api";

// const Community = () => {
//   const [csrSpending, setCsrSpending] = useState('');
//   const [communityPrograms, setCommunityPrograms] = useState([{ number: '', scope: '' }]);
//   const [impactPrograms, setImpactPrograms] = useState([{ beneficiaries: '', outcomes: '' }]);
//   const [volunteerHours, setVolunteerHours] = useState([{ total_hours: '', total_volunteers: '' }]);
//   const navigate = useNavigate();
//   // Replace with your actual company ID

//   // Fetch data from the backend when the component mounts
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`${api}/social/community_engagement/${companyId}`);
//         const data = response.data;

//         // Populate the state with fetched data
//         setCsrSpending(data.csrSpending.value || '');
//         setCommunityPrograms(data.communityInvestmentPrograms || [{ number: '', scope: '' }]);
//         setImpactPrograms(data.impactOfCommunityPrograms || [{ beneficiaries: '', outcomes: '' }]);
//         setVolunteerHours(data.volunteerHours || [{ total_hours: '', total_volunteers: '' }]);
//       } catch (error) {
//         console.error("Error fetching community engagement data:", error.message);
//       }
//     };

//     fetchData();
//   }, [companyId]);

//   // Handlers for form fields
//   const handleCommunityProgramChange = (index, field, value) => {
//     const updatedPrograms = [...communityPrograms];
//     updatedPrograms[index][field] = value;
//     setCommunityPrograms(updatedPrograms);
//   };

//   const handleImpactProgramChange = (index, field, value) => {
//     const updatedImpactPrograms = [...impactPrograms];
//     updatedImpactPrograms[index][field] = value;
//     setImpactPrograms(updatedImpactPrograms);
//   };

//   const handleVolunteerHoursChange = (index, field, value) => {
//     const updatedVolunteerHours = [...volunteerHours];
//     updatedVolunteerHours[index][field] = value;
//     setVolunteerHours(updatedVolunteerHours);
//   };


//   const Save = async () => {
//     const postData = {
//       csrSpending: { value: parseFloat(csrSpending), unit: '%' },
//       communityInvestmentPrograms: communityPrograms.map((program) => ({
//         number: parseInt(program.number, 10),
//         scope: program.scope,
//       })),
//       impactOfCommunityPrograms: impactPrograms.map((program) => ({
//         beneficiaries: program.beneficiaries,
//         outcomes: program.outcomes,
//       })),
//       volunteerHours: volunteerHours.map((hours) => ({
//         total_hours: parseInt(hours.total_hours, 10),
//         total_volunteers: parseInt(hours.total_volunteers, 10),
//       })),
//       companyId: companyId, // Use the actual company ID
//     };
  
//     try {
//       // Check if the record exists by making a GET request
//       const existingRecordResponse = await axios.get(
//         `${api}/social/community_engagement/${companyId}`
//       );
  
//       if (existingRecordResponse.status === 200 && existingRecordResponse.data) {
//         // Record exists, perform PUT request to update
//         const putResponse = await axios.put(
//           `${api}/social/community_engagement/${companyId}`,
//           postData
//         );
  
//         if (putResponse.status === 200) {
//           console.log("Community Engagement record updated successfully");
//           window.alert("Community Engagement record updated successfully!"); // Show alert for update
//           navigate("/humanRight");
//         }
//       } else {
//         // Record does not exist, perform POST request to create
//         const postResponse = await axios.post(
//           "${api}/social/community_engagement",
//           postData
//         );
  
//         if (postResponse.status === 201) {
//           console.log("Community Engagement record created successfully");
//           window.alert("Community Engagement record saved successfully!"); // Show alert for save
//           navigate("/humanRight");
//         }
//       }
//     } catch (error) {
//       console.error("Error saving community engagement data:", error.message);
  
//       // If the error is due to the record not existing, make a POST request
//       if (error.response && error.response.status === 404) {
//         try {
//           const postResponse = await axios.post(
//             "${api}/social/community_engagement",
//             postData
//           );
  
//           if (postResponse.status === 201) {
//             console.log("Community Engagement record created successfully");
//             window.alert("Community Engagement record saved successfully!"); // Show alert for save
//             navigate("/humanRight");
//           }
//         } catch (postError) {
//           console.error(
//             "Error creating community engagement data:",
//             postError.message
//           );
//         }
//       }
//     }
//   };
  

//   const Back = () => {
//     navigate("/Diversity");
//   };

//   return (
//     <Box display="flex" sx={{ height: '100vh', backgroundColor: '#d6d7d8' }}>
//       <Sidebar title="Community Engagement" />
//       <Box sx={{ width: '100%', padding: '16px'}}>
//         <Typography variant="h5" sx={{ fontWeight: 'bold', margin: '32px 0px' }}>
//           SOCIAL: COMMUNITY ENGAGEMENT
//         </Typography>

//         {/* CSR Spending */}
//         <Box sx={{ marginBottom: '16px', width: '70%' }}>
//           <Typography sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '2px' }}>
//             Corporate Social Responsibility (CSR) Spending
//           </Typography>
//           <TextField
//             variant="outlined"
//             placeholder="25,000"
//             value={csrSpending}
//             onChange={(e) => setCsrSpending(e.target.value)}
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                     <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
//                     %
//                   </Box>
//                 </InputAdornment>
//               )
//             }}
//             fullWidth
//             sx={{ backgroundColor: '#d6d7d8', marginBottom: '15px', borderRadius: '4px' }}
//           />
//         </Box>

//         {/* Community Investment Programs */}
//         <Box sx={{ marginBottom: '20px', width: '70%' }}>
//           <Typography sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '2px' }}>
//             Community Investment Programs
//           </Typography>
//           <Box display="flex" gap="11px">
//             <TextField
//               variant="outlined"
//               placeholder="Number"
//               value={communityPrograms[0].number}
//               onChange={(e) => handleCommunityProgramChange(0, 'number', e.target.value)}
//               fullWidth
//               sx={{ backgroundColor: '#d6d7d8', borderRadius: '4px' }}
//             />
//             <TextField
//               variant="outlined"
//               placeholder="Scope"
//               value={communityPrograms[0].scope}
//               onChange={(e) => handleCommunityProgramChange(0, 'scope', e.target.value)}
//               fullWidth
//               sx={{ backgroundColor: '#d6d7d8', borderRadius: '4px' }}
//             />
//           </Box>
//         </Box>

//         {/* Impact of Community Programs */}
//         <Box sx={{ marginBottom: '16px', width: '70%' }}>
//           <Typography sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '2px' }}>
//             Impact of Community Programs
//           </Typography>
//           <Box display="flex" flexDirection="column" gap="10px">
//             <Box display="flex" gap="11px">
//               <TextField
//                 variant="outlined"
//                 placeholder="Beneficiaries"
//                 value={impactPrograms[0].beneficiaries}
//                 onChange={(e) => handleImpactProgramChange(0, 'beneficiaries', e.target.value)}
//                 fullWidth
//                 sx={{ backgroundColor: '#d6d7d8', borderRadius: '4px' }}
//               />
//               <TextField
//                 variant="outlined"
//                 placeholder="Outcomes"
//                 value={impactPrograms[0].outcomes}
//                 onChange={(e) => handleImpactProgramChange(0, 'outcomes', e.target.value)}
//                 fullWidth
//                 sx={{ backgroundColor: '#d6d7d8', borderRadius: '4px' }}
//               />
//             </Box>
//             <Button
//               variant="outlined"
//               startIcon={<AddIcon />}
//               sx={{
//                 alignSelf: 'flex-end',
//                 color: '#204769',
//                 borderColor: '#204769',
//                 '&:hover': {
//                   backgroundColor: 'rgba(25, 118, 210, 0.04)',
//                   color: '#115293',
//                   borderColor: '#115293',
//                 },
//               }}
//             >
//               Add
//             </Button>
//           </Box>
//         </Box>

//         {/* Volunteer Hours */}
//         <Box sx={{ marginBottom: '16px', width: '70%' }}>
//           <Typography sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '2px' }}>
//             Volunteer Hours
//           </Typography>
//           <Box display="flex" gap="11px">
//             <TextField
//               variant="outlined"
//               placeholder="Total Hours"
//               value={volunteerHours[0].total_hours}
//               onChange={(e) => handleVolunteerHoursChange(0, 'total_hours', e.target.value)}
//               fullWidth
//               sx={{ backgroundColor: '#d6d7d8', borderRadius: '4px' }}
//             />
//             <TextField
//               variant="outlined"
//               placeholder="Total number of volunteers"
//               value={volunteerHours[0].total_volunteers}
//               onChange={(e) => handleVolunteerHoursChange(0, 'total_volunteers', e.target.value)}
//               fullWidth
//               sx={{ backgroundColor: '#d6d7d8', borderRadius: '4px' }}
//             />
//           </Box>
//         </Box>

//         {/* Save and Back Buttons */}
//         <Box display="flex" justifyContent="space-between" sx={{ marginTop: '70px' }}>
//           <Button
//             variant="outlined"
//             startIcon={<ArrowBackIcon />}
//             onClick={Back}
//             sx={{
//               color: '#204769',
//               borderColor: '#204769',
//               '&:hover': {
//                 backgroundColor: 'rgba(25, 118, 210, 0.04)',
//                 color: '#115293',
//                 borderColor: '#115293',
//               },
//             }}
//           >
//             Back
//           </Button>
//           <Button
//             variant="contained"
//             endIcon={<EastIcon />}
//             onClick={Save}
//             sx={{
//               backgroundColor: '#204769',
//               '&:hover': {
//                 backgroundColor: '#115293',
//               },
//             }}
//           >
//             Save and Continue
//           </Button>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default Community;







import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Typography, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EastIcon from '@mui/icons-material/East';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import { companyId } from "../../../utils/companyId";
import { api } from '../../../utils/api';

const Community = () => {
  const [csrSpending, setCsrSpending] = useState('');
  const [communityPrograms, setCommunityPrograms] = useState([{ number: '', scope: '' }]);
  const [impactPrograms, setImpactPrograms] = useState([{ beneficiaries: '', outcomes: '' }]);
  const [volunteerHours, setVolunteerHours] = useState([{ total_hours: '', total_volunteers: '' }]);
  
  const navigate = useNavigate();

  // Fetch data from the backend when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api}/social/community_engagement/${companyId}`);
        const data = response.data;

        // Populate the state with fetched data
        setCsrSpending(data.csrSpending.value || '');
        setCommunityPrograms(data.communityInvestmentPrograms || [{ number: '', scope: '' }]);
        setImpactPrograms(data.impactOfCommunityPrograms || [{ beneficiaries: '', outcomes: '' }]);
        setVolunteerHours(data.volunteerHours || [{ total_hours: '', total_volunteers: '' }]);
      } catch (error) {
        console.error("Error fetching community engagement data:", error.message);
      }
    };

    fetchData();
  }, [companyId]);

  // Handlers for form fields
  const handleAddImpactProgram = () => {
    setImpactPrograms([
      ...impactPrograms,
      { beneficiaries: "", outcomes: "" }, // New empty program
    ]);
  };

  const handleCommunityProgramChange = (index, field, value) => {
    const updatedPrograms = [...communityPrograms];
    updatedPrograms[index][field] = value;
    setCommunityPrograms(updatedPrograms);
  };

  const handleImpactProgramChange = (index, field, value) => {
    const updatedImpactPrograms = [...impactPrograms];
    updatedImpactPrograms[index][field] = value;
    setImpactPrograms(updatedImpactPrograms);
  };

  const handleVolunteerHoursChange = (index, field, value) => {
    const updatedVolunteerHours = [...volunteerHours];
    updatedVolunteerHours[index][field] = value;
    setVolunteerHours(updatedVolunteerHours);
  };

  const Save = async () => {
    // Prepare the data to be sent to the backend
    const postData = {
      csrSpending: { value: parseFloat(csrSpending), unit: '%' },
      communityInvestmentPrograms: communityPrograms.map((program) => ({
        number: parseInt(program.number, 10),
        scope: program.scope,
      })),
      impactOfCommunityPrograms: impactPrograms.map((program) => ({
        beneficiaries: program.beneficiaries,
        outcomes: program.outcomes,
      })),
      volunteerHours: volunteerHours.map((hours) => ({
        total_hours: parseInt(hours.total_hours, 10),
        total_volunteers: parseInt(hours.total_volunteers, 10),
      })),
      companyId: companyId,
    };
    try {
      // Check if the record exists by making a GET request
      const existingRecordResponse = await axios.get(
        `${api}/social/community_engagement/${companyId}`
      );
  
      if (existingRecordResponse.status === 200 && existingRecordResponse.data) {
        // Record exists, perform PUT request to update
        const putResponse = await axios.put(
          `${api}/social/community_engagement/${companyId}`,
          postData
        );
  
        if (putResponse.status === 200) {
          console.log("Community Engagement record updated successfully");
          window.alert("Community Engagement record updated successfully!"); // Show alert for update
          navigate("/social/human-right");
        }
      } else {
        // Record does not exist, perform POST request to create
        const postResponse = await axios.post(
          `${api}/social/community_engagement`,
          postData
        );
  
        if (postResponse.status === 201) {
          console.log("Community Engagement record created successfully");
          window.alert("Community Engagement record saved successfully!"); // Show alert for save
          navigate("/social/human-right");
        }
      }
    } catch (error) {
      console.error("Error saving community engagement data:", error.message);
  
      // If the error is due to the record not existing, make a POST request
      if (error.response && error.response.status === 404) {
        try {
          const postResponse = await axios.post(
            `${api}/social/community_engagement`,
            postData
          );
  
          if (postResponse.status === 201) {
            console.log("Community Engagement record created successfully");
            window.alert("Community Engagement record saved successfully!"); // Show alert for save
            navigate("/social/human-right");
          }
        } catch (postError) {
          console.error(
            "Error creating community engagement data:",
            postError.message
          );
        }
      }
    }
  };
  

  const Back = () => {
    navigate("/social/diversity");
  };

  return (
    <Box display="flex" sx={{ height: '100%', backgroundColor: '#d6d7d8' }}>
      <Sidebar title="Community Engagement" />
      <Box sx={{ width: '100%', padding: '16px'}}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', margin: '32px 0px' }}>
          SOCIAL: COMMUNITY ENGAGEMENT
        </Typography>

        {/* CSR Spending */}
        <Box sx={{ marginBottom: '16px', width: '70%' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '2px' }}>
            Corporate Social Responsibility (CSR) Spending
          </Typography>
          <TextField
            variant="outlined"
            placeholder="25,000"
            value={csrSpending}
            onChange={(e) => setCsrSpending(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
                    %
                  </Box>
                </InputAdornment>
              )
            }}
            fullWidth
            sx={{ backgroundColor: '#d6d7d8', marginBottom: '15px', borderRadius: '4px' }}
          />
        </Box>

        {/* Community Investment Programs */}
        <Box sx={{ marginBottom: '20px', width: '70%' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '2px' }}>
            Community Investment Programs
          </Typography>
          <Box display="flex" gap="11px">
            <TextField
              variant="outlined"
              placeholder="Number"
              value={communityPrograms[0].number}
              onChange={(e) => handleCommunityProgramChange(0, 'number', e.target.value)}
              fullWidth
              sx={{ backgroundColor: '#d6d7d8', borderRadius: '4px' }}
            />
            <TextField
              variant="outlined"
              placeholder="Scope"
              value={communityPrograms[0].scope}
              onChange={(e) => handleCommunityProgramChange(0, 'scope', e.target.value)}
              fullWidth
              sx={{ backgroundColor: '#d6d7d8', borderRadius: '4px' }}
            />
          </Box>
        </Box>

        {/* Impact of Community Programs */}
        <Box sx={{ marginBottom: '16px', width: '70%' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '2px' }}>
            Impact of Community Programs
          </Typography>
          <Box display="flex" flexDirection="column" gap="10px">
            {impactPrograms.map((program, index) => (
              <Box key={index} display="flex" gap="11px">
                <TextField
                  variant="outlined"
                  placeholder="Beneficiaries"
                  value={program.beneficiaries}
                  onChange={(e) => handleImpactProgramChange(index, 'beneficiaries', e.target.value)}
                  fullWidth
                  sx={{ backgroundColor: '#d6d7d8', borderRadius: '4px' }}
                />
                <TextField
                  variant="outlined"
                  placeholder="Outcomes"
                  value={program.outcomes}
                  onChange={(e) => handleImpactProgramChange(index, 'outcomes', e.target.value)}
                  fullWidth
                  sx={{ backgroundColor: '#d6d7d8', borderRadius: '4px' }}
                />
              </Box>
            ))}
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                alignSelf: 'flex-end',
                color: '#204769',
                borderColor: '#204769',
                '&:hover': {
                  backgroundColor: 'rgba(25, 118, 210, 0.04)',
                  color: '#115293',
                  borderColor: '#115293',
                },
              }}
              onClick={handleAddImpactProgram}
            >
              Add
            </Button>
          </Box>
        </Box>

        {/* Volunteer Hours */}
        <Box sx={{ marginBottom: '16px', width: '70%' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '600', marginBottom: '2px' }}>
            Volunteer Hours
          </Typography>
          <Box display="flex" gap="11px">
            <TextField
              variant="outlined"
              placeholder="Total Hours"
              value={volunteerHours[0].total_hours}
              onChange={(e) => handleVolunteerHoursChange(0, 'total_hours', e.target.value)}
              fullWidth
              sx={{ backgroundColor: '#d6d7d8', borderRadius: '4px' }}
            />
            <TextField
              variant="outlined"
              placeholder="Total Volunteers"
              value={volunteerHours[0].total_volunteers}
              onChange={(e) => handleVolunteerHoursChange(0, 'total_volunteers', e.target.value)}
              fullWidth
              sx={{ backgroundColor: '#d6d7d8', borderRadius: '4px' }}
            />
          </Box>
        </Box>

        {/* Save Button */}
        <Box display="flex" justifyContent="space-between" sx={{ marginTop: '70px' }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={Back}
            sx={{
              color: '#204769',
              borderColor: '#204769',
              '&:hover': {
                backgroundColor: 'rgba(25, 118, 210, 0.04)',
                color: '#115293',
                borderColor: '#115293',
              },
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            endIcon={<EastIcon />}
            onClick={Save}
            sx={{
              backgroundColor: '#204769',
              '&:hover': {
                backgroundColor: '#115293',
              },
            }}
          >
            Save and Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Community;
