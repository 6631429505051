import { Button } from '@mui/material'
import React from 'react'
import { Add as AddIcon } from '@mui/icons-material';

const AddButton = ({onClick}) => {
  return (
    <Button variant="outlined" startIcon={<AddIcon />}
    onClick={onClick}
    sx={{
        alignSelf: 'flex-end',
        color: '#74757E',
        borderColor: '#74757E',
        marginLeft: '87%',
        '&:hover': {
            backgroundColor: 'rgba(25, 118, 210, 0.04)',
            color: '#115293',
            borderColor: '#115293',
        },
    }}
>
    Add
</Button> 
  )
}

export default AddButton