import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { api } from "../../../utils/api";
import Sidebar from "../sidebar";
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import { companyId } from "../../../utils/companyId";
import { ProgressContext } from "../ContextAPI";
import InputCard from "./InputCard";

const EnergyManagement = () => {
  const navigate = useNavigate();
  const { setUpdate } = useContext(ProgressContext);

  const [isExist, setIsExist] = useState(false);
  const [formData, setFormData] = useState({
    totalEnergyConsumption: { value: "", unit: "" },
    renewableEnergyConsumption: { value: "" },
    energyIntensity: { value: "" },
    energyEfficiencyImprovements: { value: "" },
  });

  // Energy Data cards for adding input fields
  const energyData = [
    {
      title: "Total Energy Consumption",
      placeholder: "1000",
      name: "totalEnergyConsumption",
      units: ["MJ", "GJ", "MWh", "GWh"],
    },
    {
      title: "Renewable Energy Consumption",
      placeholder: "% of total",
      name: "renewableEnergyConsumption",
      adornmentText:"%"
    },
    {
      title: "Energy Intensity",
      placeholder: "Energy per unit of output",
      name: "energyIntensity",
      adornmentText: "MWh per product unit",
    },
    {
      title: "Energy Efficiency Improvements",
      placeholder: "% reduction year over year",
      name: "energyEfficiencyImprovements",
      adornmentText:"%"
    },
  ];

  // Navigating back
  const Back = () => {
    navigate("/material-topics");
  };

  // validating input values
  const validateForm = () => {
    const {
      totalEnergyConsumption,
      renewableEnergyConsumption,
      energyIntensity,
      energyEfficiencyImprovements,
    } = formData;
    if (
      totalEnergyConsumption.value === "" ||
      isNaN(totalEnergyConsumption.value) ||
      totalEnergyConsumption.unit === "" ||
      renewableEnergyConsumption.value === "" ||
      isNaN(renewableEnergyConsumption.value) ||
      energyIntensity.value === "" ||
      isNaN(energyIntensity.value) ||
      energyEfficiencyImprovements.value === "" ||
      isNaN(energyEfficiencyImprovements.value)
    ) {
      alert("Please fill in all fields with valid numeric values.");
      return false;
    }
    return true;
  };

  // fetching data if exists
  useEffect(() => {
    setUpdate("ESG");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/environment/energy-management/${companyId}`
        );
        setFormData(response.data.energyData);
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
      }
    };
    fetchData();
  }, []);

  // Save the formData and make the post request
  const Save = async () => {
    console.log(formData);
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/environment/energy-management${
          isExist ? `/${companyId}` : ""
        }`,
        data: { ...formData, companyId },
      });
      // alert(response.data.message);
      navigate("/environment/ghg-emissions");
    } catch (err) {
      console.log(err.response.data.message);
      alert(err.response.data.message);    }
  };

  // Handling the input value
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: {
          ...prevFormData[name],
          value: value.trim() || Number(value) || "",
        },
      };
    });
  };
  // Handling unit input
  const handleUnitChange = (e, name) => {
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: {
        ...prevState[name],
        unit: value,
      },
    }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "rgb(214, 215, 216)",
        height: "120vh",
      }}
    >
      <Sidebar title="Energy Management" />
      <Box sx={{ p: 2, ml: 2, width: "100%" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 5 }}>
          ENVIRONMENT: ENERGY MANAGEMENT
        </Typography>
        <Box sx={{ mt: 5, width: "70%" }}>
          {/* Maps the energy data */}
          {energyData.map((item, index) => (
            <InputCard
              key={index}
              title={item.title}
              placeholder={item.placeholder}
              name={item.name}
              value={
                typeof formData[item.name] === "object"
                  ? formData[item.name].value
                  : formData[item.name]
              }
              unitValue={
                typeof formData[item.name] === "object"
                  ? formData[item.name].unit
                  : ""
              }
              handleChange={handleChange}
              handleUnitChange={handleUnitChange}
              units={item.units}
              adornmentText={item.adornmentText}
            />
          ))}
          {/* Back, Save Buttons */}
          <ActionButtons Back={Back} Move={Save} />
        </Box>
      </Box>
    </Box>
  );
};

export default EnergyManagement;
