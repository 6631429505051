import React from 'react';
import { Box, Chip, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const MaterialChips = ({ selected, category, handleDelete }) => (
  <Box
    sx={{
      display: "flex",
      flexWrap: "wrap",
      gap: 0.5,
      color: "white",
      width: "70%",
      mt: 2,
    }}
  >
    {selected.length>0 && selected
      .filter((value) => value !== "Select")
      .map((value) => (
        <Chip
          key={value}
          label={value}
          onDelete={() => handleDelete(value, category)}
          sx={{
            bgcolor: "#1C486B",
            color: "white",
            "& .MuiChip-deleteIcon": {
              color: "white",
              "&:hover": {
                color: "white",
              },
            },
          }}
          deleteIcon={
            <IconButton sx={{ color: "white" }}>
              <CancelIcon />
            </IconButton>
          }
        />
      ))}
  </Box>
);

export default MaterialChips;