import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { api } from "../../../utils/api";
import Sidebar from "../sidebar";
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import { companyId } from "../../../utils/companyId";
import InputCard from "./InputCard";

const WaterManagement = () => {
  const navigate = useNavigate();
  const [isExist, setIsExist] = useState(false);
  const [formData, setFormData] = useState({
    totalWasteGenerated: { value: "" },
    wasteIntensity: { value: "" },
    wasteDivertedFromLandFill: { value: "" },
    hazardousWasteManagement: { value: "" },
    companyId,
  });

  // waste data for creating input cards
  const wasteData = [
    {
      title: "Total Waste Generated",
      placeholder: "1000",
      name: "totalWasteGenerated",
      adornmentText: "metric Tons",
    },
    {
      title: "Waste Intensity",
      placeholder: "30",
      name: "wasteIntensity",
      adornmentText: "waste per unit of output",
    },
    {
      title: "Waste Diverted from Landfill",
      placeholder: "10",
      name: "wasteDivertedFromLandFill",
      adornmentText: "% of total waste",
    },
    {
      title: "Hazardous Waste Management",
      placeholder: "1000",
      name: "hazardousWasteManagement",
      adornmentText: "metric Tons",
    },
  ];

  // Naviagting back
  const Back = () => {
    navigate("/environment/water-management");
  };

  // validating input fields
  const validateForm = () => {
    const {
      totalWasteGenerated,
      wasteIntensity,
      wasteDivertedFromLandFill,
      hazardousWasteManagement,
    } = formData;
    console.log(formData);
    if (
      totalWasteGenerated.value === "" ||
      isNaN(totalWasteGenerated.value) ||
      wasteIntensity.value === "" ||
      isNaN(wasteIntensity.value) ||
      wasteDivertedFromLandFill.value === "" ||
      isNaN(wasteDivertedFromLandFill.value) ||
      hazardousWasteManagement.value === "" ||
      isNaN(hazardousWasteManagement.value)
    ) {
      alert("Please fill in all fields with valid numeric values.");
      return false;
    }
    return true;
  };

  // fetching data if already exists
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/environment/waste-management/${companyId}`
        );
        console.log(response.data.wasteData);
        setFormData(response.data.wasteData);
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
        // alert(err)
      }
    };
    fetchData();
  }, []);

  // Save the formData and make the post request
  const Save = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/environment/waste-management${
          isExist ? `/${companyId}` : ""
        }`,
        data: { ...formData, companyId },
      });
      // alert(response.data.message);
      navigate("/environment/biodiversity");
    } catch (err) {
      console.log(err.response.data.message);
      alert(err.response.data.message);
    }
  };

  // Handling the input value
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(typeof formData[name]);
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: {
          ...prevFormData[name],
          value: value.trim() || Number(value) || "",
        },
      };
    });
  };

  return (
    <Box sx={{ display: "flex", backgroundColor: "rgb(214, 215, 216)" }}>
      <Sidebar title="Waste Management" />
      <Box sx={{ p: 2, ml: 2, width: "100%" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 5 }}>
          ENVIRONMENT: WASTE MANAGEMENT
        </Typography>
        <Box sx={{ mt: 5, width: "70%" }}>
          {wasteData.map((item, index) => (
            <InputCard
              key={index}
              title={item.title}
              placeholder={item.placeholder}
              name={item.name}
              value={
                typeof formData[item.name] === "object"
                  ? formData[item.name].value
                  : formData[item.name]
              }
              handleChange={handleChange}
              adornmentText={item.adornmentText}
            />
          ))}
          {/* Back, Save Buttons */}
          <ActionButtons Back={Back} Move={Save} />
        </Box>
      </Box>
    </Box>
  );
};

export default WaterManagement;
