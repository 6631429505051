import React from 'react';
import { AppBar, Toolbar, IconButton, Avatar, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';

const Navbar = () => {

  const navbarData=[
    {title:"Search",icon:SearchIcon},
    {title:"Setting",icon:SettingsIcon},
    {title:"Notification",icon:NotificationsIcon}
  ]

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: 'white',
       boxShadow:"none",
        height: '50px',
        // borderBottom: '1px solid #ddd',
        mb:3,
        width: '85%', // Make sure it spans the full width
         // Ensure it sits above other elements
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ flex: 1 }}>
          {/* Placeholder for left side content if needed */}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center',marginTop:"-10px" }}>
         {navbarData.map(el=>
           <IconButton title={el.title} sx={{ color: 'black', fontSize: "10px" }}>
           <el.icon/>
         </IconButton>
         )}
          <Avatar
            src="https://cms.interiorcompany.com/wp-content/uploads/2024/01/aster-attractive-flower-images.jpg"
            alt="Profile"
            sx={{ width: 30, height: 30, ml: 2 }}
          />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;