import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { api } from "../../../utils/api";
import Sidebar from "../sidebar";
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import { companyId } from "../../../utils/companyId";
import InputCard from "./InputCard";

const GHGEmissions = () => {
  const navigate = useNavigate();
  const [isExist, setIsExist] = useState(false);
  const [formData, setFormData] = useState({
    scope1: { value: "" },
    scope2: { value: "" },
    scope3: { value: "" },
    ghgEmissionIntensity: { value: "" },
    reductionInGhgEmissions: { value: "" },
    carbonOffsetting: { value: "" },
    companyId,
  });

  // GHG data for creating input cards
  const ghgData = [
    {
      title: "Scope 1",
      placeholder: "1000",
      name: "scope1",
      adornmentText: "tCO2e",
    },
    {
      title: "Scope 2",
      placeholder: "1000",
      name: "scope2",
      adornmentText: "tCO2e",
    },
    {
      title: "Scope 3",
      placeholder: "1000",
      name: "scope3",
      adornmentText: "tCO2e",
    },
    {
      title: "GHG Emissions Intensity",
      placeholder: "Emissions per unit of output ",
      name: "ghgEmissionIntensity",
      adornmentText: "CO2e per unit",
    },
    {
      title: "Reduction in GHG Emissions",
      placeholder: "% reduction over year",
      name: "reductionInGhgEmissions",
      adornmentText: "%",

    },
    {
      title: "Carbon Offsetting",
      placeholder: "metric tons CO2e offset",
      name: "carbonOffsetting",
      adornmentText:"metric tons CO2e offset",
    },
  ];

  // Naviagting back
  const Back = () => {
    navigate("/environment/energy-management");
  };

  // Validating input fields
  const validateForm = () => {
    const fields = [
      "scope1",
      "scope2",
      "scope3",
      "ghgEmissionIntensity",
      "reductionInGhgEmissions",
      "carbonOffsetting",
    ];

    const isValid = fields.every((field) => {
      const fieldValue = formData[field]?.value;
      return fieldValue !== "" && !isNaN(fieldValue);
    });

    if (!isValid) {
      alert("Please fill in all fields with valid numeric values.");
      return false;
    }
    return true;
  };

  // fetching data if alredy exists
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/environment/ghg-emissions/${companyId}`
        );
        console.log(response);
        setFormData(response.data.ghgEmissionsData || formData);
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
      }
    };
    fetchData();
  }, []);

  // Save the formData and make the post request
  const Save = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/environment/ghg-emissions${
          isExist ? `/${companyId}` : ""
        }`,
        data: formData,
      });
      // alert(response.data.message);
      navigate("/environment/water-management");
    } catch (err) {
      console.log(err.response.data.message);
      alert(err.response.data.message);    }
  };

  // Handling the input value
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: {
        ...prevFormData[name],
        value: value.trim() || Number(value) || "",
      },
    }));
  };

  return (
    <Box sx={{ display: "flex", backgroundColor: "rgb(214, 215, 216)" }}>
      <Sidebar title="Greenhouse Gas Emissions" />
      <Box sx={{ p: 2, ml: 2, width: "100%" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 5 }}>
          ENVIRONMENT: GREENHOUSE GAS EMISSIONS
        </Typography>
        <Box sx={{ mt: 5, width: "80%" }}>
          {/* Maps ghg data */}
          {ghgData.map((item, index) => (
            <InputCard
              key={index}
              title={item.title}
              placeholder={item.placeholder}
              name={item.name}
              value={formData[item.name]?.value || ""}
              handleChange={handleChange}
              adornmentText={item.adornmentText}
            />
          ))}
          {/* Back, Save Buttons */}
          <ActionButtons Back={Back} Move={Save} />
        </Box>
      </Box>
    </Box>
  );
};

export default GHGEmissions;
