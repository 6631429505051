import React, { useContext, useState, useEffect } from 'react';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import { ProgressContext } from "../ContextAPI";
import { Box, Typography, TextField, Button, InputAdornment } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EastIcon from '@mui/icons-material/East';
import { api } from "../../../utils/api"; // Assuming you use a custom API utility
import { companyId } from "../../../utils/companyId";
import axios from "axios";

const Employeedevelopment = () => {
  const { setUpdate } = useContext(ProgressContext);
  const navigate = useNavigate();

  const [turnoverRate, setTurnoverRate] = useState('');
  const [trainingHours, setTrainingHours] = useState('');
  const [healthAndSafety, setHealthAndSafety] = useState('');
  const [isExist, setIsExist] = useState(false);

  useEffect(() => {
    setUpdate("SOCIAL");

    // Fetch data using GET API
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api}/social/employee_well_being_development/${companyId}`);
        if (response.data) {
          setTurnoverRate(response.data.turnoverRate.value);
          setTrainingHours(response.data.trainingHoursPerEmployee.value);
          setHealthAndSafety(response.data.employeeHealthAndSafety.value);
        }
        setIsExist(true);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [setUpdate]);

  

  // const Save = async () => {
  //   const data = {
  //     turnoverRate: { value: turnoverRate, unit: "%" },
  //     trainingHoursPerEmployee: { value: trainingHours, unit: "hours" },
  //     employeeHealthAndSafety: { value: healthAndSafety, metric: "lost time frequency injury rate" },
  //     companyId: companyId,
  //   };

  //   console.log(companyId);
  
  //   try {
  //     let response;
  //     let successMessage;${api}
  
  //     if (companyId) {
  //       // Make a PUT request if companyId is present
  //       response = await fetch(`${api}/social/employee_well_being_development/${companyId}`, {
  //         method: 'PUT',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify(data),
  //       });${api}
  //       successMessage = "Data updated successfully";
  //     } else {
  //       // Make a POST request if companyId is not present
  //       response = await fetch('${api}/social/employee_well_being_development', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify(data),
  //       });
  //       successMessage = "Data saved successfully";
  //     }
  
  //     if (response.ok) {
  //       alert(successMessage);
  //       navigate("/Diversity");
  //     } else {
  //       alert("Please fill all the fields");
  //       console.error("Failed to save data");
  //     }
  //   } catch (error) {
  //     console.error("Error during fetch:", error);
  //   }
  // };
///////////////////////////
  const Save = async () => {
    const data = {
      turnoverRate: { value: turnoverRate, unit: "%" },
      trainingHoursPerEmployee: { value: trainingHours, unit: "hours" },
      employeeHealthAndSafety: { value: healthAndSafety, metric: "lost time frequency injury rate" },
      companyId: companyId,
    };
  
    console.log(data.companyId);
  
    try {
      let response;
      let successMessage;
  
      if (isExist) {
        // Make a PUT request if companyId is present
        response = await axios.put(`${api}/social/employee_well_being_development/${companyId}`, data);
        successMessage = "Data updated successfully";
      } else {
        // Make a POST request regardless of companyId check
        response = await axios.post(`${api}/social/employee_well_being_development`, data);
        successMessage = "Data saved successfully";
      }
  
      if (response.status === 200 || response.status === 201) {
        alert(successMessage);
        navigate("/social/diversity");
      } else {
        alert("Please fill all the fields");
        console.error("Failed to save data");
      }
    } catch (error) {
      console.error("Error during Axios request:", error);
    }
  };
  

  

  const Back = () => {
    setUpdate("ESG");
    navigate("/environment/sustainable-sourcing");
  };

  return (
    <Box display="flex" className="water" sx={{backgroundColor: '#d6d7d9', overflowX: 'hidden'}}>
      <Sidebar title="Employee Well Being And Development" />
      <Box className="challengeWarer" sx={{ width: '100%', p: 3 }}>
        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          SOCIAL: EMPLOYEE WELL BEING AND DEVELOPMENT
        </Typography>

        <Box className="mainWater">
          <Typography variant="body1" sx={{ mb: 1 }}>
            Turnover Rate
          </Typography>
          <TextField
            variant="outlined"
            value={turnoverRate}
            onChange={(e) => setTurnoverRate(e.target.value)}
            sx={{ mb: 3, width: '67%', backgroundColor: '#d6d7d8', borderRadius: '8px' }}
            placeholder="10"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ height: '44px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
                    %
                  </Box>
                </InputAdornment>
              ),
            }}
          />

          <Typography variant="body1" sx={{ mb: 1 }}>
            Training Hours per Employee
          </Typography>
          <TextField
            variant="outlined"
            value={trainingHours}
            onChange={(e) => setTrainingHours(e.target.value)}
            sx={{ mb: 3, width: '67%', backgroundColor: '#d6d7d8', borderRadius: '8px' }}
            placeholder="1000"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ height: '44px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
                    hours
                  </Box>
                </InputAdornment>
              ),
            }}
          />

          <Typography variant="body1" sx={{ mb: 1 }}>
            Employee Health and Safety
          </Typography>
          <TextField
            variant="outlined"
            value={healthAndSafety}
            onChange={(e) => setHealthAndSafety(e.target.value)}
            sx={{ mb: 3, width: '67%', backgroundColor: '#d6d7d8', borderRadius: '8px' }}
            placeholder="10"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ height: '44px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
                    lost time frequency injury rate
                  </Box>
                </InputAdornment>
              ),
            }}
          />

          <Box display="flex" justifyContent="space-between" className="savaAndBack" sx={{ mt: -20, pt: -20, width: '70%' }}>
            <Button variant="outlined" onClick={Back} startIcon={<ArrowBackIcon />}>
              Back
            </Button>
            <Button variant="contained" onClick={Save} endIcon={<EastIcon />} sx={{ bgcolor: '#1C486B'}}>
              Save and Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Employeedevelopment;




