import React, { useState, useEffect, useContext } from "react";
import Sidebar from "../sidebar";
import { useNavigate } from "react-router-dom";
import "./Sustainability.css";
import axios from "axios";
import { api } from "../../../utils/api";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  Container,
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Button,
} from "@mui/material";
import { ProgressContext } from "../ContextAPI";
import { DataArraySharp } from "@mui/icons-material";

const Sustainability = () => {
  const {companyId}=localStorage.getItem("companyId");
  const { setTriggerGetRequest } = useContext(ProgressContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    implementing: "",
    brief1: "",
    brief2: "",
    brief3: "",
    primaryReasons: [],
    customerDemand: "",
    regulatoryCompliance: "",
    other: "",
    governmentRegulations: "",
    reportingStandards: [],
    sustainabilityOverview: '',
    progressMeasurement: '',
    // companyId:companyId,
  });
  const [paragraph,SetPragraph] = useState([]);
  const [currentInput, setCurrentInput] = useState('');
  const [ExistingData,setexistingData]=useState(false);
const [radio,SetRadio]=useState("yes");
console.log("companyId",companyId)

  useEffect( () => {
   const companyId = localStorage.getItem('companyId')
    // if (storedCompanyId) {
    //   setFormData((prevData) => ({
    //     ...prevData,
    //     comany_id: storedCompanyId,
    //   }));
    // }
    // Define the async function inside the effect
    const fetchData = async () => {
    if (companyId) {
      // console.log("storedCompanyId",storedCompanyId)
      const response = axios
        .get(`${api}/api/sustainabilityById/${companyId}`)
        .then(async (data) => {
          console.log("dataof", data.data, data.data.reportingStandards);
          await setFormData({
            implementing:
              data.data.sustainabilityInitiatives.implementing || "",
            brief1: data.data.sustainabilityInitiatives.brief || "",
            brief2: data.data.motivationForIncorporation || "",
            brief3: data.data.sustainabilityGoals || "",
            primaryReasons: data.data.primaryReasons || [],
            customerDemand: data.data.customerDemand || "",
            regulatoryCompliance: data.data.regulatoryCompliance || "",
            other: data.data.other || "",
            governmentRegulations: data.data.governmentRegulations || "",
            reportingStandards: data.data.reportingStandards || [],
            sustainabilityOverview: data.data.sustainabilityOverview || "",
            progressMeasurement: data.data.progressMeasurement || "",
          });
          await (SetPragraph(data.data.reportingStandards),
          setexistingData(true),
          SetRadio(
            data.data.sustainabilityInitiatives.implementing ? "yes" : "no"
          ));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    };
   
    // Call the async function
    fetchData();
    // Cleanup function (if needed)
    // return () => {
    //   // Cleanup logic here (if necessary)
    // };
  }, []);

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setCurrentInput(event.target.value);
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // setCurrentInput(e.target.value)
  };
  const handleChangeRadio = (e) => {
    const { name, value } = e.target;
    // SetData(e.target.value)
    console.log("radiobutton", name, value);
    // setFormData({
    //   ...formData,
    //   [name]: value,
    // });
    SetRadio(e.target.value);
    console.log("for the field", e.target.value);
  };
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    console.log("data of checked", e.target, checked, value);
    setFormData((prevState) => {
      const newPrimaryReasons = checked
        ? [...prevState.primaryReasons, value]
        : prevState.primaryReasons.filter((reason) => reason !== value);
      return {
        ...prevState,
        primaryReasons: newPrimaryReasons,
      };
    });
  };

  const Save = async () => {
     const companyId = localStorage.getItem('companyId');
    try {
      const payload = {
        sustainabilityInitiatives: {
          implementing: radio === "yes" ? true : false,
          brief: formData.brief1,
        },
        motivationForIncorporation: formData.brief2,
        sustainabilityGoals: formData.brief3,
        primaryReasons: formData.primaryReasons,
        governmentRegulations: formData.governmentRegulations,
        reportingStandards: paragraph,
        sustainabilityOverview: formData.sustainabilityOverview,
        progressMeasurement: formData.progressMeasurement,
        companyId: companyId,
        regulatoryCompliance: formData.regulatoryCompliance,
        customerDemand: formData.customerDemand,
        other: formData.other,
        // company_id:companyId
      };
      console.log("ExistingData", ExistingData);
      await (ExistingData
        ? axios.put(`${api}/api/sustainabilityById/${companyId}`, payload)
        : axios.post(`${api}/api/sustainability`, payload));
      console.log("Data saved successfully");
      alert("Data saved successfully");
      navigate("/challenges-concerns");
    } catch (error) {
      console.error("Error saving data:", error);
      alert("Please fill all the fields");
    }
  };
  //  const handleAddReportingStandard = (event)=>{
  //   console.log("paragraph====",)
  //   event.preventDefault();
  //   if (event.key === 'Enter') {
  //    // Prevent form submission or newline in the input
  //     // setFormData((prevData) => ({
  //     //   ...prevData,
  //     //   reportingStandards: [...prevData.reportingStandards, prevData.reportingStandardsInput],
  //     //   reportingStandardsInput: '' // Clear input after adding
  //     // }));
  //     let update = currentInput.trim()
  //     console.log("update",update)
  //      SetPragraph((prevParagraphs) => [...prevParagraphs, currentInput]);
  //     //  SetPragraph([currentInput])
  //     setCurrentInput('');
  //   }

  //   console.log("event",event.target.value)
  //   console.log("paragraph",paragraph)
  // };

  const handleInputChange = (event) => {
    setCurrentInput(event.target.value);
  };

  const handleAddReportingStandard = (event) => {
    if (event.key === "Enter") {
      // Check for Enter key and non-empty input
      event.preventDefault();
      setFormData((prevData) => ({
        ...prevData,
        reportingStandards: [
          ...prevData.reportingStandards,
          currentInput.trim(),
        ], // Add new input to array
      }));
      SetPragraph(
        (prevData) => [...prevData, currentInput.trim()] // Add new input to array
      );
      setCurrentInput(""); // Clear the input field after adding
    }
  };
  const handleDelete = (i) => {
    console.log(i, paragraph);
    // SetPragraph((prev) => {
    const updatedValues = paragraph.filter((value) => value !== i);
    // Check if the array is empty and add "Select" if needed
    // if (updatedValues.length === 0) {
    //   updatedValues.push("Select");
    // }
    SetPragraph(updatedValues);
    console.log("updatedValues", updatedValues);
    // return {
    //   ...prev,
    //   [category]: updatedValues,
    // };
    // });
  };

  // const Back = () => {
  //   navigate('/');
  // };

  const Back = () => {
    setTriggerGetRequest(true); // Set trigger to true
    navigate("/");
  };
  const visible = formData.primaryReasons.filter((i) => i).map((item) => item);
  console.log("visible", visible[visible.length - 1]);
  return (
    // <Container  maxWidth={false} className="sustainability" sx={{ display: 'flex', width: '100%' }}>
    <Box
      sx={{
        display: "flex",
        backgroundColor: "rgb(214, 215, 216)",
      }}
    >
      <Sidebar title="Sustainabilty" />
      <Box className="challenge" sx={{ width: "100%", p: 3 }}>
        <Typography
          variant="h5"
          sx={{ marginBottom: "20px", fontWeight: "bold" }}
        >
          SUSTAINABILITY
        </Typography>
        <Grid container spacing={3} className="mainsustainability">
          <Grid item xs={12}>
            <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
              Are you currently implementing any sustainability initiatives or
              practices within your company?
              <br /> If yes, please provide a brief.
            </Typography>
            <RadioGroup
              row
              aria-label="implementing"
              name="implementing"
              value={radio}
              onChange={handleChangeRadio}
            >
              <FormControlLabel
                value="yes"
                // value={formData.implementing ? 'yes' : 'no'} checked={formData.implementing === true}
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                //  checked={formData.implementing === false}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
            {console.log(
              "datainsidefile",
              formData,
              formData.implementing,
              radio
            )}
            {radio === "yes" ? (
              <TextField
                name="brief1"
                variant="outlined"
                className="brief"
                placeholder="Brief"
                value={formData.brief1}
                multiline
                rows={5}
                onChange={handleChange}
              />
            ) : (
              ""
            )}
          </Grid>

          <Grid item xs={12}>
            <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
              What motivated your company to start incorporating sustainability
              practices?
            </Typography>
            <TextField
              name="brief2"
              variant="outlined"
              className="brief"
              placeholder="Brief"
              multiline
              value={formData.brief2}
              rows={5}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
              Are there any specific sustainability goals your company aims to
              achieve?
            </Typography>
            <TextField
              name="brief3"
              variant="outlined"
              className="brief"
              placeholder="Brief"
              multiline
              rows={3}
              value={formData.brief3}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
              What are the primary reasons your company wants to incorporate
              sustainability into its practices? <br />
              (Select all that apply)
            </Typography>
            <Grid container spacing={2}>
              {[
                "Regulatory Compliance",
                "Customer Demand",
                "Leadership in Sustainability",
                "Cost Savings",
                "Ethical/Moral Responsibilities",
                "Brand Reputation Enhancement",
                "Other",
              ].map((reason, index) => (
                <Grid item xs={6} md={6} key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={reason}
                        onChange={handleCheckboxChange}
                        checked={formData.primaryReasons.includes(reason)}
                      />
                    }
                    label={reason}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          {visible && visible.includes("Other") && (
            <Grid item xs={6}>
              {/* <Typography className="paragraph">
            Is there a desire within your company to become a leader in sustainability within your industry?<br/> If yes, what specific initiatives or strategies are being considered to achieve this goal?
            </Typography> */}
              <TextField
                name="other"
                variant="outlined"
                className="brief"
                placeholder="Text..."
                multiline
                rows={1.5}
                value={formData.other}
                onChange={handleChange}
                // sx={{height:"30px"}}
              />
            </Grid>
          )}
          {visible && visible.includes("Regulatory Compliance") && (
            <Grid item xs={12}>
              <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
                Are there any government regulations or mandates related to
                sustainability that your company needs <br /> to comply with? If
                yes, please provide details.
              </Typography>
              <TextField
                name="regulatoryCompliance"
                variant="outlined"
                className="brief"
                placeholder="Text..."
                multiline
                value={formData.regulatoryCompliance}
                rows={3}
                onChange={handleChange}
              />
            </Grid>
          )}
          {visible && visible.includes("Customer Demand") && (
            <Grid item xs={12}>
              <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
                Have your customers expressed any specific sustainability
                requirements or preferences that influence <br /> your company's
                practices? If yes, please elaborate.
              </Typography>
              <TextField
                name="customerDemand"
                variant="outlined"
                className="brief"
                placeholder="Text..."
                multiline
                value={formData.customerDemand}
                rows={3}
                onChange={handleChange}
              />
            </Grid>
          )}
          {visible && visible.includes("Leadership in Sustainability") && (
            <Grid item xs={12}>
              <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
                Is there a desire within your company to become a leader in
                sustainability within your industry?
                <br /> If yes, what specific initiatives or strategies are being
                considered to achieve this goal?
              </Typography>
              <TextField
                name="governmentRegulations"
                variant="outlined"
                className="brief"
                placeholder="Text..."
                multiline
                value={formData.governmentRegulations}
                rows={3}
                onChange={handleChange}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
              Has your company selected any particular sustainability reporting
              standard or framework? <br /> If yes, please specify.
            </Typography>
            {console.log("formData.reportingStandards", paragraph.length)}
            <div className="updateParagraph">
              {paragraph.length > 0 &&
                paragraph.map((item) => {
                  return (
                    <p
                      style={{
                        display: "flex",
                        backgroundColor: "#0d336b",
                        borderRadius: "5px",
                        padding: "2px",
                      }}
                    >
                      {item}
                      <CloseIcon
                        style={{
                          width: "15px",
                          margin: "3px 0px 0px 4px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDelete(item)}
                      />
                    </p>
                  );
                })}
            </div>
            <TextField
              name="reportingStandards"
              variant="outlined"
              className="brief"
              placeholder="Type here"
              multiline
              value={currentInput}
              rows={3}
              onChange={handleInputChange}
              onKeyUp={handleAddReportingStandard}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
              Can you provide an overview of your company's sustainability?
            </Typography>
            <TextField
              name="sustainabilityOverview"
              variant="outlined"
              className="brief"
              placeholder="Overview..."
              multiline
              value={formData.sustainabilityOverview}
              rows={3}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography className="paragraph" sx={{ fontWeight: "bold" }}>
              How does your company plan to measure and track the progress of
              its sustainability initiatives?
            </Typography>
            <TextField
              name="progressMeasurement"
              variant="outlined"
              className="brief"
              placeholder="Text..."
              multiline
              value={formData.progressMeasurement}
              rows={3}
              onChange={handleChange}
            />
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={1}
            className="savaAnBacksustainability"
          >
            <Grid item>
              <Button
                variant="outlined"
                sx={{
                  color: "#0a3f73",
                  borderColor: "#0a3f73",
                  textTransform: "capitalize",
                }}
                onClick={Back}
              >
                <i class="bi bi-arrow-left arrow"></i>Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#0a3f73", textTransform: "none" }}
                onClick={Save}
              >
                Save and continue <i class="bi bi-arrow-right-short arrow"></i>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Sustainability;
