import React,{useEffect, useState} from 'react';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  InputAdornment,
  Typography,
  TextField,
  Button,
  IconButton,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './Ethics.css';
import axios from 'axios';
import { api } from '../../../utils/api';
import { companyId } from "../../../utils/companyId";

const Ethics = () => {
  const navigate = useNavigate();
  const[IsExist,setISExist]=useState(false);
  const [formData,setFromData]=useState({
    companyId:companyId,
    codeConductViolations:{
      type_violation:[""],
      number_incidence:[0]
    },
    antiCorruptionMeasures:{
      policy:"yes",
      training_program:"yes",
      minyears_experience_policy:0,
      minyears_experience_training:0
    },
    whistleblowerPolicies:{
      existence:"yes",
      reports:0,
      resolution:0

    },
    complianceLawsRegulations:{
      type_compliance:[""],
      frequency:[""],
      fine_paid:[0]
    }


});
const handleChangeConductViolations = (e,index,fieldType)=>{
  const {name,value}=e.target
// setFromData({
//   ...formData,
//   codeConductViolations:{
//     ...formData.codeConductViolations,
//     [name]:value
//   }
// })
const updatedCommittees = { ...formData.codeConductViolations };
updatedCommittees[fieldType][index] = value;
setFromData({
  ...formData,
  codeConductViolations: updatedCommittees,
});
}
const handleChangeAntiCorruption=(e)=>{
  const {name,value}=e.target
  setFromData({
    ...formData,
    antiCorruptionMeasures:{
      ...formData.antiCorruptionMeasures,
      [name]:value
    }
  })
}
const handleWhistle=(e)=>{
  const {name,value}=e.target
  setFromData({
    ...formData,
    whistleblowerPolicies:{
      ...formData.whistleblowerPolicies,
      [name]:value
    }
  })
}
const handleCompliance=(e,index,fieldType)=>{
const {name,value}=e.target
// setFromData({
//   ...formData,
//   complianceLawsRegulations:{
//     ...formData.complianceLawsRegulations,
//     [name]:value
//   }
// })
const updatedCommittees = { ...formData.complianceLawsRegulations };
updatedCommittees[fieldType][index] = value;
setFromData({
  ...formData,
  complianceLawsRegulations: updatedCommittees,
});
}
const AddConductViolations=()=>{
  const updatedCommittees = { ...formData.codeConductViolations };
  updatedCommittees.number_incidence.push('');
  updatedCommittees.type_violation.push('');
  setFromData({
    ...formData,
    codeConductViolations: updatedCommittees,
  });
}
const AddCompliance=()=>{
  const updatedCommittees = { ...formData.complianceLawsRegulations };
  updatedCommittees.type_compliance.push('');
  updatedCommittees.frequency.push('');
  updatedCommittees.fine_paid.push('');
  setFromData({
    ...formData,
    complianceLawsRegulations: updatedCommittees,
  });
}
console.log(formData)
useEffect(()=>{
//  const companyId = companyId
  axios.get(`${api}/governance/ethic-compensation/${companyId}`).then((res)=>{
    console.log(res,res.data.codeConductViolations.type_violation)
    setFromData({
      codeConductViolations:{
        type_violation:res.data.codeConductViolations.type_violation,
        number_incidence:res.data.codeConductViolations.number_incidence
      },
      antiCorruptionMeasures:{
        policy:res.data.antiCorruptionMeasures.policy===true?"yes":"no",
        training_program:res.data.antiCorruptionMeasures.training_program===true?"yes":"no",
        minyears_experience_policy:res.data.antiCorruptionMeasures.minyears_experience_policy,
        minyears_experience_training:res.data.antiCorruptionMeasures.minyears_experience_training
      },
      whistleblowerPolicies:{
        existence:res.data.whistleblowerPolicies.existence===true?"yes":"no",
        reports:res.data.whistleblowerPolicies.reports,
        resolution:res.data.whistleblowerPolicies.resolution.value,
  
      },
      complianceLawsRegulations:{
        type_compliance:res.data.complianceLawsRegulations.type_compliance,
        frequency:res.data.complianceLawsRegulations.frequency,
        fine_paid:res.data.complianceLawsRegulations.fine_paid
      }
    })
    setISExist(true)
  }).catch(err=>console.log(err))
},[])
  const Save = () => {
  // const  companyId= companyId;
  
    axios({method:IsExist?'put':'post',
      url:`${api}/governance/ethic-compensation${IsExist ? `/${companyId}` : ""}`,
      data:formData}).then((res)=>{
      console.log(res)
      alert(res.data.message)
      navigate('/governance/risk-management');
    }).catch((err)=>{
      alert(err.data.message)
      console.log(err)
    })
  };

  const Back = () => {
    navigate('/governance/exclutive-compensation');
  };

  return (
    <Box display="flex" width="100%" className="ethics">
      <Sidebar title="Ethics and Compliance" />
      <Box width="100%" sx={{ p: 3, mt: 2 }}>
        <Typography variant="h5" sx={{ marginBottom: '20px', mt: 3, fontWeight: 'bold' }}>
          GOVERNANCE: ETHICS AND COMPLIANCE
        </Typography>

        <Box className="mainEthics">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }} className="heading" gutterBottom>
            Code of Conduct Violations
          </Typography>
          
       
       <Grid container spacing={2} mb={2} sx={{ width: '70%' }} alignItems="center">
     {formData.codeConductViolations.type_violation.map((item,i)=>{
      return <> <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                placeholder="Types of Violation"
                className="inputfieldEquity"
                name ="type_violation"
                value={item}
                onChange={(e) => handleChangeConductViolations(e, i, 'type_violation')}
                // onChange={handleChangeConductViolations}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                placeholder="Number of Incidence"
                className="inputfieldEquity"
                name='number_incidence'
                onChange={(e) => handleChangeConductViolations(e, i, 'number_incidence')}
                // onChange={handleChangeConductViolations}
                // value={formData.codeConductViolations.number_incidence===0?"":
                //   formData.codeConductViolations.number_incidence}
                value={formData.codeConductViolations.number_incidence[i] === 0 ? '' : formData.codeConductViolations.number_incidence[i]}
                fullWidth
              />
            </Grid></> })}  
            <Grid item xs={12} display="flex" justifyContent="flex-end" sx={{ mt: 1 }}>
  <Button 
    variant="outlined" 
   sx={{color:"#0a3f73",textTransform:"none"}} 
    className="add" 
    onClick={AddConductViolations}
    endIcon={<AddIcon />} // Use startIcon to place the icon before the text
  >
    Add
  </Button>
</Grid>

          </Grid>
      
        

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }} className="heading" gutterBottom>
            Anti-Corruption Measures
          </Typography>
<Grid display="flex" gap="70px">
          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
              Policy
            </Typography>
            <RadioGroup row name="policy"
            value={formData.antiCorruptionMeasures.policy}
            onChange={handleChangeAntiCorruption}  sx={{gap:"20px"}}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
              Training Program
            </Typography>
            <RadioGroup row name="training_program" 
            value={formData.antiCorruptionMeasures.training_program}
            onChange={handleChangeAntiCorruption}  sx={{gap:"20px"}} defaultValue="yes">
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>
          </Grid>
          <Box display="flex" width="69%" gap="13px">
      {formData.antiCorruptionMeasures.policy==="yes"?    <TextField
            variant="outlined"
            placeholder="Min. years of Policy experience"
            className="inputfieldcommittees"
            name = "minyears_experience_policy"
            value={formData.antiCorruptionMeasures.minyears_experience_policy===0?"":
              formData.antiCorruptionMeasures.minyears_experience_policy
            }
            onChange={handleChangeAntiCorruption}
            fullWidth
            sx={{ mb: 3,
              // width:"70%" 
            }}
          />:<></>}
     {formData.antiCorruptionMeasures.training_program ==="yes"?    <TextField
            variant="outlined"
            placeholder="Min. years of Training experience"
            className="inputfieldcommittees"
            name = "minyears_experience_training"
            value={formData.antiCorruptionMeasures.minyears_experience_training===0?"":
              formData.antiCorruptionMeasures.minyears_experience_training
            }
            onChange={handleChangeAntiCorruption}
          fullWidth
            sx={{ mb: 3,
              // width:"70%" 
            }}
          />:
          <></>}
          </Box>
          

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }} className="heading" gutterBottom>
            Whistleblower Policies
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Existence
          </Typography>
          <RadioGroup row name="existence"
          value={formData.whistleblowerPolicies.existence}
          onChange={handleWhistle}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>

          {/* <Box > */}
      {formData.whistleblowerPolicies.existence==="yes"?
       <Grid container xs={12} sx={{width:"70%"}} spacing={2} alignItems="center">
       <Grid item xs={12} md={6}>
       <TextField
        variant="outlined"
        placeholder="Number of Reports"
        name='reports'
        value={formData.whistleblowerPolicies.reports===0?"":
          formData.whistleblowerPolicies.reports
        }
        onChange={handleWhistle}
        className="inputfieldcommittee"
        sx={{ mb: 2 }}
        fullWidth
      />
       </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            variant="outlined"
            placeholder="Resolution"
            name='resolution'
            value={formData.whistleblowerPolicies.resolution===0?"":
              formData.whistleblowerPolicies.resolution
            }
            fullWidth
            onChange={handleWhistle}
            className="inputfieldtw"
            sx={{mb:2}}
            InputProps={{
              endAdornment:  <InputAdornment position="end" sx={{ marginLeft: "8px" }}>
              <div
                style={{
                  borderLeft: "1px solid #000", // Color of the vertical line
                  height: "24px", // Height of the line
                  marginRight: "8px", // Space between the line and the percentage symbol
                }}
              />
              %
            </InputAdornment>
              
              // <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
        {/* <Grid item xs={2}>
          <Typography variant="body1" className='ghenergy'>%</Typography>
        </Grid> */}
      </Grid>
      :""}     
          {/* </Box> */}

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 3, mb: 1 }} className="heading" gutterBottom>
          Compliance with Laws and Regulations
          </Typography>

   {formData.complianceLawsRegulations.type_compliance.map((item,i)=>{
    return <Grid container spacing={2} mb={2} sx={{width:"72%"}}>
    <Grid item xs={12} md={4}>
      <TextField
        variant="outlined"
        placeholder="Type of Compliance"
        name='type_compliance'
        value={item}
        // onChange={handleCompliance}
        onChange={(e) => handleCompliance(e, i, 'type_compliance')}
        className="inputfieldcompliance"
        fullWidth
      />
    </Grid>
    <Grid item xs={12} md={4}>
      <TextField
        variant="outlined"
        placeholder="frequency"
        name='frequency'
        value={formData.complianceLawsRegulations.frequency[i]===0?"":
          formData.complianceLawsRegulations.frequency[i]
        }
        onChange={(e) => handleCompliance(e, i, 'frequency')}
        className="inputfieldcompliance"
        fullWidth
      />
    </Grid>
    <Grid item xs={12} md={4}>
      <TextField
        variant="outlined"
        placeholder="Fine Paid"
        name='fine_paid'
        value={formData.complianceLawsRegulations.fine_paid[i]===0?"":
          formData.complianceLawsRegulations.fine_paid[i]
        }
        onChange={(e) => handleCompliance(e, i, 'fine_paid')}
        className="inputfieldcompliance"
        fullWidth
      />
    </Grid>
  </Grid>
   })}  
        
          <Box sx={{width:"70%",display:"flex",
            justifyContent:"flex-end"
          }}>
          <Button 
    variant="outlined" 
   sx={{color:"#0a3f73",margin:"15px 0px",textTransform:"none"}} 
    className="add" 
    endIcon={<AddIcon />} // Use startIcon to place the icon before the text
 onClick={AddCompliance}
 >
    Add
  </Button >
  </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" width="70%" sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            onClick={Back}
            startIcon={<ArrowBackIcon />}
            sx={{color:"#0a3f73",borderColor:"#0a3f73",textTransform:"none"}}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={{backgroundColor:"#0a3f73",textTransform:"none"}}
            onClick={Save}
            endIcon={<ArrowForwardIcon />}
          >
            Save and continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Ethics;
