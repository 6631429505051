import React, {useContext, useState,useEffect } from 'react';
import  Timeline from '@mui/lab/Timeline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TimelineItem from '@mui/lab/TimelineItem';
import Dot from '@mui/lab/TimelineDot';
import Select from '@mui/material/Select';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BorderStyle, CenterFocusStrong, Check } from '@mui/icons-material';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import { ProgressContext } from './Quetionary/ContextAPI';
import {Container,Company,Kickoff, TimelineSection, Wrapper,Dropdownrender} from './ProgressStyle';
const data =["intial Kickoff","Company Info","Sustainabilty","Challenges & Concern","Future plans","Material Topics","ESG Baseline"]
const material =["intial Kickoff","Material Topics","ESG Baseline","Environment","Social","Goverence"]
const esgdata =["intial Kickoff","Matarial Topics","ESG Baseline","Environment","Energy Management","Greenhouse Gas Emissions","Water Management","Waste Management","Biodiversity and Land Use","Pollution and Environmental Compliance","Sustainable Sourcing and Supply Chain","Social","Goverenance"]
const social = ["intial Kickoff","Matarial Topics","ESG Baseline","Environment","Social","Employee Well Being And Development","Diversity and inclusion","Community Engagement","Human Rights","Customer Relations","Supply chain social responsibility","Goverenance"]
const goverenance =["intial Kickoff","Matarial Topics","ESG Baseline","Environment","Social","Goverenance","board composition & effectiveness","Executive compensation","Ethics and Compliance","Risk Management","Stakeholder Engagement","Cybersecurity and data Privacy"]
function Progressbar(props) {
  const {update} = useContext(ProgressContext);
  const [visible,SetVisible]=useState(data);  

  useEffect(()=>{
    // if(visible[visible.length-2] === props.title){
    // if( update === "ESG" ){
    //   SetVisible(esgdata)
    // }else if(update === "SOCIAL"){
    //   SetVisible(social)
    // }
    switch (update){
      case "MATERIAL":
        return SetVisible(material);
      case "ESG":
        return SetVisible(esgdata);
      case "SOCIAL":
        return SetVisible(social);
      case "GOVERNANCE":
        return SetVisible(goverenance);
      default:
        return SetVisible(data);
    }
    // }
    // {data[data.length-2] === props.title ? SetVisible(esgdata) : ""}
  },[update])
  const renderDot = (item) => {
   
    
    const titleIndex = visible.findIndex(d => d === props.title);
    const itemIndex = visible.findIndex(d => d === item);
   
    
    if (visible === data && (item === "intial Kickoff"|| item === "ESG Baseline" || visible === esgdata && item === "Enviornment" || visible === social && item === "Social" || visible ===goverenance  && item === "Goverenance")) {
      return (
       <Dot
          style={{
            marginTop: "1px",
            borderWidth: '0px',
            borderColor: "#e68b0b",
            backgroundColor: "#e68b0b"
          }}
          variant='outlined'
        />
      );
    } 
  else if (itemIndex < titleIndex) {
      // For previous steps
      return (
        <Dot
          style={{
            marginTop: "1px",
            borderColor: "#e68b0b",
            backgroundColor: "#e68b0b",
            position: "relative",
            justifyContent: "center",
            alignItems: "center"
          }}
          variant='outlined'
        >
          <Check sx={{ color: '#fff', fontSize: 15, position: "absolute" }} />
        </Dot>
      );
    } else if (itemIndex === titleIndex) {
      // For the current title
      return (
        <Dot
          style={{
            marginTop: "1px",
            borderColor: "#e68b0b",
            backgroundColor: "#e68b0b",
            position: "relative",
            justifyContent: "center",
            alignItems: "center"
          }}
          variant='outlined'
        />
      );
    } else {
      // For next steps
      return (
        <Dot
          style={{
            marginTop: "1px",
            borderColor: "#e68b0b"
          }}
          variant='outlined'
        />
      );
    }
  };
  const renderDropdown =(item)=>{

  //   if (item === "intial Kickoff" || item === "ESG Baseline") {
  //     {console.log("before dropdown arrow++ ",item)}
  // return(  <ArrowDropDownIcon onClick ={HideKickoff}/>)
  //   }
  const arrow = visible.includes(update)
  console.log(item,visible,data,update,arrow)
  switch (item) {
    case "intial Kickoff":
      return <div style={{position:"absolute",right:"0px",cursor:"pointer",bottom:"50px"}}>
      <ArrowDropDownIcon  />
     </div>;
    case "Esg Baseline":
    // case visible === data && "ESG Baseline":
      // console.log("before dropdown arrow++ ", item);
      return <div style={{position:"absolute",right:"0px",cursor:"pointer",bottom:"50px"}}>
      <ArrowDropDownIcon  />
   </div>;
   case visible === data && "ESG Baseline" ||  visible === material && "Enviornment":
    console.log("before dropdown arrow++ ", item);
    return <div style={{position:"absolute",right:"0px",cursor:"pointer",bottom:"50px"}}>
    <ArrowDropDownIcon  />
 </div>;
  case arrow && "Goverence":
    console.log("before dropdown arrow++ ", item);
    return <div style={{position:"absolute",right:"0px",cursor:"pointer",bottom:"50px"}}>
    <ArrowDropDownIcon  />
 </div>;
  case visible === material && "Social"  :
    console.log("before dropdown arrow++ ", item);
    return <div style={{position:"absolute",right:"0px",cursor:"pointer",bottom:"50px"}}>
    <ArrowDropDownIcon  />
 </div>;
    
    default:
      // You can handle other cases here if needed, or leave it empty
      return null;  // Or another appropriate fallback component
  }
  
  }

const HideKickoff=()=>{
  SetVisible(esgdata)
  console.log("hide item")
}

  return (
    <Container>
      <Wrapper>
        <TimelineSection>
          <Timeline style={{padding:"0px"}}>
       { visible.map((item,index)=>{
      return <> <TimelineItem key={index}>
             <TimelineSeparator>
                {/* {item==="intial Kickoff"? <Dot style={{marginTop:"1px",borderWidth:'0px',borderColor: "#e68b0b",backgroundColor:"#e68b0b"}} variant='outlined'/>:  <Dot style={{marginTop:"1px",borderColor: "#e68b0b",backgroundColor:"#e68b0b"}} variant='outlined'>  <Check sx={{ color: '#fff', fontSize: 20 }} />
                </Dot>} */}
                 {renderDot(item)}
               
              {index !== visible.length-1 &&( index < visible.findIndex(d => d === props.title) 
              ?  <TimelineConnector style={{backgroundColor:"#e68b0b",
                width:"2px",
                borderColor: "#e68b0b", }}/>
              :<TimelineConnector sx={{backgroundColor:"transparent",
                 borderWidth: "1.5px",
                 width:'0px',
                borderStyle:"dashed",borderColor: "#e68b0b", }}/>
            )}
               
                </TimelineSeparator>
                {item === "intial Kickoff"  || item === "ESG Baseline" ?<Kickoff>{item}</Kickoff>:<Company>{item}</Company>}
            </TimelineItem>
             
             
              <Dropdownrender>{renderDropdown(item)}</Dropdownrender>
             {/* {item === "intial Kickoff" || item === "ESG Baseline" ?<ArrowDropDownIcon onClick ={HideKickoff}/>:""}  */}
             {/* </div> */}
             </>  
            })}   
           
          </Timeline>
        </TimelineSection>
      </Wrapper>
    </Container>
  )
}

export default Progressbar;