import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { companyId } from "../../../../utils/companyId";
import { api } from "../../../../utils/api";
import MaterialCategory from "./MaterialCategory";
import MaterialChips from "./MaterialChips";
import ActionButtons from "./ActionButtons";
import { ProgressContext } from "../../ContextAPI";
import { Box, Typography } from "@mui/material";
import Sidebar from "../../sidebar";

const MaterialTopics = () => {
  const navigate = useNavigate();
  const [isExist, setIsExist] = useState(false);
  const { setUpdate } = useContext(ProgressContext);
  const [formData, setFormData] = useState({
    environment: ["Select"],
    social: ["Select"],
    governance: ["Select"],
  });
  const [open, setOpen] = useState({
    environment: false,
    social: false,
    governance: false,
  });

  //array of dropdown fields
  const data = [
    {
      title: "Environment",
      key: "environment",
      list: [
        "Select", "Carbon Emissions", "Product Carbon Footprint", "Financing Environmental Impact", "Water Stress", 
        "Biodiversity & Land Use", "Raw Material Sourcing", "Toxic Emissions & Waste", "Packaging Material & Waste", 
        "Electronic Waste", "Opportunities in Clean Tech", "Opportunities in Green Building", "Opportunities in Renewable Energy", 
        "Energy Management", "Waste Management", "Ecological Impacts", "Greenhouse Gas (GHG) Emissions", "Air Quality", 
        "Water & Wastewater Management", "Climate Change Adaptation", "Materials Sourcing & Efficiency", 
        "Product Design & Lifecycle Management", "Environmental & Social Impacts of Ingredient Supply Chain", 
        "Management of Energy Infrastructure Integration & Related Regulations", "Hazardous Waste Management", 
        "Environmental Footprint of Operations", "End-Use Efficiency", "Effluent Quality Management", 
        "Drinking Water Quality", "Water Supply Resilience", "Fuel Economy & Emissions in Use-phase","Select All"
      ],
    },
    {
      title: "Social",
      key: "social",
      list: [
        "Select", "Labour Management", "Health & Safety", "Human Capital Development", "Supply Chain Labour Standards", 
        "Product Safety & Quality", "Chemical Safety", "Consumer Financial Protection", "Privacy & Data Security", 
        "Responsible Investment", "Community Relations", "Controversial Sourcing", "Access to Finance", "Access to Health Care", 
        "Opportunities in Nutrition & Health", "Employee Engagement, Diversity & Inclusion", "Human Rights & Community Relations", 
        "Business Model Resilience", "Critical Incident Risk Management", "Competitive Behavior", "Systemic Risk Management", 
        "Physical Impacts of Climate Change", "Customer Welfare", "Workforce Health & Safety", 
        "Employee Recruitment, Development & Retention", "Professional Integrity", "Customer Privacy & Technology Standards", 
        "Affordable & Low-Income Patient Access", "Quality of Care & Patient Satisfaction", "Pricing & Billing Transparency", 
        "Fraud & Unnecessary Procedures", "Patient Health Outcomes", "Patient Privacy & Electronic Health Records", 
        "Product Innovation", "Pricing Integrity & Transparency", "Workforce Conditions, Health & Safety", "Health & Nutrition", 
        "Food Safety", "Public Health", "Safety of Clinical Trial Participants", "Access to Medicines", "Affordability & Pricing", 
        "Drug Safety", "Counterfeit Drugs", "Ethical Marketing", "Management of Controlled Substances", 
        "Climate Change Impacts on Human Health & Infrastructure", "Workforce Diversity & Engagement","Select All"
      ],
    },
    {
      title: "Governance",
      key: "governance",
      list: [
        "Select", "Board", "Pay", "Accounting", "Business Ethics", "Tax Transparency", "Management of the Legal & Regulatory Environment", 
        "Selling Practices & Product Labeling", "Transparent Information & Fair Advice for Customers", 
        "Sustainable Investment Management", "Financed Emissions", "Financial Inclusion & Capacity Building", 
        "Systemic Risk Management", "Policies Designed to Incentivize Responsible Behavior", "Professional Integrity", 
        "Lending Practices", "Discriminatory Lending", "Environmental Risk to Mortgaged Properties", 
        "Promoting Transparent & Efficient Capital Markets", "Managing Conflicts of Interest", 
        "Managing Business Continuity & Technology Risks", "Intellectual Property Protection & Media Piracy", 
        "Management of Leachate & Hazardous Waste", "Recycling & Resource Recovery", "Accident & Safety Management",
        "Select All"
      ],
    },
  ];

  // fetching data if already exists
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/company/material-topics/${companyId}`
        );
        const {social,governance,environment}=response.data.materialTopics;
        // setFormData(response.data.materialTopics);
        setFormData({social:social,environment:environment,governance:governance})
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
      }
    };
    fetchData();
  }, []);

  // handle input change
  const handleChange = (e, category) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [category]: value,
    }));
    setOpen((prevState) => ({ ...prevState, [category]: false }));
  };

  // remove the array values
  const handleDelete = (valueToDelete, category) => {
    setFormData((prev) => {
      const updatedValues = prev[category].filter((value) => value !== valueToDelete);
      // Check if the array is empty and add "Select" if needed
      if (updatedValues.length === 0) {
        updatedValues.push("Select");
      }
      return {
        ...prev,
        [category]: updatedValues,
      };
    });
  };
  
// validate input fields
  const validateForm = (filteredFormData) => {
     // Filter out "Select" values
    
    const { social, governance, environment } = filteredFormData;

    if (
      social.length===0 ||
      governance.length===0 ||
      environment.length===0
    ) {
      alert("All fields are required. Please fill in all fields.");
      return false;
    }
    return true;
  };

  // Navigate back
  const Back = () => {
    navigate("/future-plans");
    setUpdate("");
  };

  // save formdata and make post request
  const Move = async () => {
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).map(([key, value]) => [
        key,
        Array.isArray(value) ? value.filter(item => item !== "Select") :[],
      ])
    );
  
    if (!validateForm(filteredFormData)) {
      return;
    }
    try {
      console.log({...filteredFormData,companyId})
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/company/material-topics${isExist ? `/${companyId}` : ""}`,
        data: {...filteredFormData,companyId},
      });
      // alert(response.data.message);
      setUpdate("ESG");
      navigate("/environment/energy-management");
    } catch (error) {
      console.error("There was an error submitting the data!", error);
      alert("There was an error submitting the data!", error);

    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "rgb(214, 215, 216)",
      }}
    >
      <Sidebar title="Material Topics"/>
      <Box sx={{ p: 2, ml: 2, width:"100%" ,}}>
        <Typography variant="h5" sx={{ fontWeight: "bold" ,mt:5}}>
          MATERIAL TOPICS
        </Typography>
        <Box sx={{ mt: 5 ,width:"100%"}}>
          {/* Maps the data */}
          {data.map((category) => (
            <MaterialCategory
              key={category.key}
              category={category}
              formData={formData}
              handleChange={handleChange}
              renderChips={(selected, key) => (
                <MaterialChips selected={selected} category={key} handleDelete={handleDelete} />
              )}
              open={open}
              setOpen={setOpen}
            />
          ))}
          <ActionButtons Back={Back} Move={Move} />
        </Box>
      </Box>
    </Box>
  );
};

export default MaterialTopics;
