import React from 'react';
import { Box } from '@mui/material';
import Navbar from '../Navbar'; // Import your Navbar component
import DashboardSidebar from '../../DashboardSidebar/DashboardSidebar';
import User from '../../../Pages/Masters/Roles and Permissions/User';


const Layout = () => {
  return (
    <Box sx={{ display: 'flex' }}>
        <DashboardSidebar/>
      <Box sx={{ flexGrow: 1}}>
        <Navbar /> {/* The Navbar is fixed at the top */}
      </Box>
    </Box>
  );
};

export default Layout;
