import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../sidebar';
import { Box, Typography, TextField, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EastIcon from '@mui/icons-material/East';
import axios from 'axios';
import { companyId } from "../../../utils/companyId";
import { api } from "../../../utils/api";

const CustomerRelation = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    netPromoterScore: '',
    customerSatisfactionIndex: '',
    highBreaches: '',
    mediumBreaches: '',
    lowBreaches: '',
    totalBreaches: '',
    product: '',
    numberOfProductsRecalled: ''
  });
  const [isExist, setIsExist] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (companyId) {
        try {
          const response = await axios.get(`${api}/social/customer_relations/${companyId}`);
          setFormData({
            netPromoterScore: response.data.customerSatisfactionScore.netPromoterScore || '',
            customerSatisfactionIndex: response.data.customerSatisfactionScore.customerSatisfactionIndex || '',
            highBreaches: response.data.dataPrivacyAndSecurityIncidents.high.numberOfBreaches || '',
            mediumBreaches: response.data.dataPrivacyAndSecurityIncidents.medium.numberOfBreaches || '',
            lowBreaches: response.data.dataPrivacyAndSecurityIncidents.low.numberOfBreaches || '',
            totalBreaches: response.data.dataPrivacyAndSecurityIncidents.totalBreaches || '',
            product: response.data.productQualityAndSafety.product || '',
            numberOfProductsRecalled: response.data.productQualityAndSafety.numberOfProductsRecalled || ''
            
          });
          setIsExist(true);
        } catch (error) {
          console.error("There was an error fetching the customer relations data!", error);
        }
      }
    };

    fetchData();
  }, [companyId]);

  

  // Calculate total breaches
  useEffect(() => {
    const high = parseInt(formData.highBreaches, 10) || 0;
    const medium = parseInt(formData.mediumBreaches, 10) || 0;
    const low = parseInt(formData.lowBreaches, 10) || 0;
  
    const total = high + medium + low;
  
    setFormData((prevData) => ({
      ...prevData,
      totalBreaches: total
    }));
  }, [formData.highBreaches, formData.mediumBreaches, formData.lowBreaches]);
  

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const Save = async () => {
    const postData = {
      customerSatisfactionScore: {
        netPromoterScore: formData.netPromoterScore,
        customerSatisfactionIndex: formData.customerSatisfactionIndex
      },
      dataPrivacyAndSecurityIncidents: {
        high: {
          numberOfBreaches: parseInt(formData.highBreaches, 10)
        },
        medium: {
          numberOfBreaches: parseInt(formData.mediumBreaches, 10)
        },
        low: {
          numberOfBreaches: parseInt(formData.lowBreaches, 10)
        },
        totalBreaches: parseInt(formData.totalBreaches, 10)
      },
      productQualityAndSafety: {
        product: formData.product,
        numberOfProductsRecalled: parseInt(formData.numberOfProductsRecalled, 10)
      },
      companyId: companyId // Assuming companyId is already set correctly
    };
  
    try {
      if (isExist) {
        // Perform PUT request to update existing record
        await axios.put(`${api}/social/customer_relations/${companyId}`, postData);
        alert("Customer relations data updated successfully!");
      } else {
        // Perform POST request to create a new record
        await axios.post(`${api}/social/customer_relations`, postData);
        window.alert("Customer relations data created successfully!");
      }
      navigate("/social/supply-chain");
    } catch (error) {
      console.error("There was an error saving the customer relations data!", error);
      window.alert("An error occurred while saving the customer relations data. Please try again.");
    }
  };

  const Back = () => {
    navigate("/social/human-right");
  };

  return (
    <Box display="flex" width="100%" bgcolor="rgb(214, 215, 216)" sx={{ height: '100%' }}>
      <Sidebar title="Customer Relations" />
      <Box width="100%" className="challengeCustomer" p={2}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', margin: '-15px 0px 30px 0px' }}>
          SOCIAL: CUSTOMER RELATIONS
        </Typography>

        {/* Customer Satisfaction Score */}
        <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
          Customer Satisfaction Score
        </Typography>
        <Box display="flex" gap={2} mb={2} sx={{ width: '70%' }}>
          <Box sx={{ flex: 1 }}>
            <TextField
              variant="outlined"
              placeholder="Net Promoter Score"
              name="netPromoterScore"
              value={formData.netPromoterScore}
              onChange={handleInputChange}
              sx={{
                width: '100%',
                backgroundColor: '#d6d7d8',
                borderRadius: '4px',
                '& .MuiOutlinedInput-root': {
                  height: '50px',
                }
              }}
              inputProps={{ 'aria-label': 'Net Promoter Score' }}
            />
            <Typography variant="body2">Alphanumeric</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <TextField
              variant="outlined"
              placeholder="Customer Satisfaction Index"
              name="customerSatisfactionIndex"
              value={formData.customerSatisfactionIndex}
              onChange={handleInputChange}
              sx={{
                width: '100%',
                backgroundColor: '#d6d7d8',
                borderRadius: '4px',
                '& .MuiOutlinedInput-root': {
                  height: '50px',
                }
              }}
              inputProps={{ 'aria-label': 'Customer Satisfaction Index' }}
            />
            <Typography variant="body2">Alphanumeric</Typography>
          </Box>
        </Box>
        
        {/* Data Privacy and Security Incidents */}
        <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', marginTop: '20px' }}>
          Data Privacy and Security Incidents
        </Typography>
        <Box display="flex" flexDirection="column" gap={1} mb={2} sx={{ width: '70%' }}>
          {["highBreaches", "mediumBreaches", "lowBreaches"].map((breachType, index) => (
            <Box display="flex" alignItems="center" gap={2} key={index}>
              <TextField
                variant="outlined"
                value={breachType.replace("Breaches", "")}
                sx={{
                  width: '50%', 
                  backgroundColor: '#d6d7d8',
                  borderRadius: '4px',
                  '& .MuiOutlinedInput-root': {
                    height: '50px',
                  }
                }}
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                variant="outlined"
                placeholder={`Number of Breaches (${breachType.replace("Breaches", "")})`}
                name={breachType}
                value={formData[breachType]}
                onChange={handleInputChange}
                sx={{ 
                  width: '50%',
                  backgroundColor: '#d6d7d8',
                  borderRadius: '4px',
                  '& .MuiOutlinedInput-root': {
                    height: '50px',
                  }
                }}
                inputProps={{ 'aria-label': `Number of Breaches (${breachType.replace("Breaches", "")})` }}
              />
            </Box>
          ))}
          <Box display="flex" justifyContent="flex-end">
            <TextField
              variant="outlined"
              placeholder="Total Breaches"
              name="totalBreaches"
              value={formData.totalBreaches}
              onChange={handleInputChange}
              sx={{ 
                width: '48.8%',
                backgroundColor: '#d6d7d8',
                borderRadius: '4px',
                '& .MuiOutlinedInput-root': {
                  height: '50px',
                }
              }}
              inputProps={{ 'aria-label': 'Total Breaches' }}
            />
          </Box>
        </Box>

        {/* Product Quality and Safety */}
        <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', marginTop: '20px' }}>
          Product Quality and Safety
        </Typography>
        <Box display="flex" gap={2} mb={2} sx={{ width: '70%' }}>
          <TextField
            variant="outlined"
            placeholder="What was the product"
            name="product"
            value={formData.product}
            onChange={handleInputChange}
            multiline
            rows={3}
            sx={{ 
              flex: 1,
              backgroundColor: '#d6d7d8',
              borderRadius: '4px',
              '& .MuiOutlinedInput-root': {
                height: '92px',
              }
            }}
          />
          <TextField
            variant="outlined"
            placeholder="Number of products recalled"
            name="numberOfProductsRecalled"
            value={formData.numberOfProductsRecalled}
            onChange={handleInputChange}
            multiline
            rows={3}
            sx={{ 
              flex: 1,
              backgroundColor: '#d6d7d8',
              borderRadius: '4px',
              '& .MuiOutlinedInput-root': {
                height: '92px',
              }
            }}
          />
        </Box>

        {/* Save and Back Buttons */}
        <Box display="flex" justifyContent="space-between" sx={{ marginTop: '70px' }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={Back}
            sx={{
              color: '#204769',
              borderColor: '#204769',
              '&:hover': {
                backgroundColor: 'rgba(25, 118, 210, 0.04)',
                color: '#115293',
                borderColor: '#115293',
              },
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            endIcon={<EastIcon />}
            onClick={Save}
            sx={{
              backgroundColor: '#1C486B',
              '&:hover': {
                backgroundColor: '#163B57',
              },
            }}
          >
            Save and Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerRelation;
