import React, { useState,useEffect } from 'react';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import "./ExcutiveCompensation.css";
import {
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextareaAutosize,
  Grid,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import axios from 'axios';
import {api} from '../../../utils/api';
import { companyId } from "../../../utils/companyId";

const ExclutiveCompensation = () => {
  const navigate = useNavigate();
  const [isExist,setIsExist] = useState(false);
  const [formData,setFormdata] = useState({
    companyId:companyId,
    ceoPayRatio:{
      ceo_pay:0,
      median_emp_pay:0,
    },
    linkageExecutiveCompensationEsgPerformance:{
      radio:"yes",
      brief:""
    }
  });
useEffect(()=>{
//  const companyId=companyId;
  axios.get(`${api}/governance/excutive-compensation/${companyId}`).then((res)=>{
    console.log(res,res.data.linkageExecutiveCompensationEsgPerformance.radio)
    setFormdata({
    ceoPayRatio:{
      ceo_pay:res.data.ceoPayRatio.ceo_pay.value,
      median_emp_pay:res.data.ceoPayRatio.median_emp_pay.value,
    },
    linkageExecutiveCompensationEsgPerformance:{
      radio:res.data.linkageExecutiveCompensationEsgPerformance.radio ===true? "yes":"no",
      brief:res.data.linkageExecutiveCompensationEsgPerformance.brief
    }
    
  });
  setIsExist(true);
  }).catch((err)=>{
     console.log(err)
  })
},[])
  const handleChange = (e)=>{
   const {name,value}=e.target
   setFormdata({
    ...formData,
    ceoPayRatio:{
      ...formData.ceoPayRatio,
      [name]:value
    }
   })
   console.log(name,value)
  }
  const handleChangeRadio = (e)=>{
    const {name,value}=e.target
    setFormdata({
      ...formData,
      linkageExecutiveCompensationEsgPerformance:{
      ...formData.linkageExecutiveCompensationEsgPerformance,
      [name]:value
    }
    })
    console.log(e.target.value)
  }
  console.log(formData)
  const Save = () => {
    // const companyId=companyId
    axios({method:isExist?'put':'post',
      url:`${api}/governance/excutive-compensation${isExist ? `/${companyId}` : ""}`,
      data:formData}).then((res)=>{
    console.log(res)
    alert(res.statusText);
    navigate('/governance/ethics');
   }).catch((err)=>{
    alert(err.message)
   })
  };

  const Back = () => {
    navigate('/governance/board-composition');
  };

  return (
    <Box display="flex" width="100%" 
     sx={{ minHeight: '100vh' }}
     className="Excutive">
      <Sidebar title="Executive compensation" />
      <Box width="100%" className="" sx={{p: 3,mt:2}} >
        <Typography variant="h5" sx={{marginBottom:"20px",mt:3,fontWeight: 'bold'}}>
          GOVERNANCE: EXECUTIVE COMPENSATION
        </Typography>

        <Box className="mainsustainability">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }} className="paragraph" gutterBottom>
            CEO Pay Ratio
          </Typography>

          <Grid item xs={12} md={12} sx={{width:"70%"}} spacing={2} className="CEOPay" alignItems="center">
            {/* <Grid item xs={12} md={6}> */}
              <TextField
                variant="outlined"
                placeholder="CEO Pay"
                className="inputfieldPay"
                name='ceo_pay'
                value={formData.ceoPayRatio.ceo_pay===0?"":
                  formData.ceoPayRatio.ceo_pay
                }
                onChange={handleChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }} sx={{marginRight:"5px"}}
              />:
              <TextField
                variant="outlined"
                placeholder="Median Employee Pay"
                className="inputfieldPay"
                name='median_emp_pay'
                onChange={handleChange}
                value={formData.ceoPayRatio.median_emp_pay===0?"":
                  formData.ceoPayRatio.median_emp_pay
                }
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }} sx={{marginLeft:"5px"}}
              />
            {/* </Grid> */}
          </Grid>

          <Typography variant="subtitle1" className="paragraph" sx={{ fontWeight: 'bold', mb: 1 }} gutterBottom mt={2}>
            Linkage of Executive Compensation to ESG Performance
          </Typography>

          <RadioGroup className="radiobutton" name='radio'
          value={formData.linkageExecutiveCompensationEsgPerformance.radio}
          onChange={handleChangeRadio}
          row>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
          {formData.linkageExecutiveCompensationEsgPerformance.radio==="yes"?
          <TextareaAutosize
            minRows={5}
            placeholder="Brief"
            className="brief"
            name='brief'
            value={formData.linkageExecutiveCompensationEsgPerformance.brief}
            onChange={handleChangeRadio}
            style={{ width: '70%', marginTop: '15px' }}
          />:""}
        </Box>

        <Box className="savaAnBackExcutive"  display="flex" justifyContent="space-between" width="70%">
          <Button variant="outlined" sx={{color:"#0a3f73",borderColor:"#0a3f73",textTransform:"none"}} onClick={Back} startIcon={<ArrowBackIcon />}>
            Back
          </Button>
          <Button variant="contained" sx={{backgroundColor:"#0a3f73",textTransform:"none"}} onClick={Save} endIcon={<ArrowForwardIcon />}>
            Save and continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ExclutiveCompensation;
