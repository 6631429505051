import  React ,{useState,useEffect} from 'react';
import { Box, TextField, Typography,MenuItem,Button ,IconButton}  from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import axios from 'axios';
import AddRole from './AddRole';
import { api } from '../../../utils/api';

function EditRole(props) {
 
  
const [fromValue,setFromValue] = useState({
  Date:""
});
    const [selectedCountry, setSelectedCountry] = useState('');
    const [isDrawerOpen,setIsDrawerOpen] = useState(false);
  const [facility,setFacility] = useState(["Tap to Select"]);
  const [selectedFacility , setSelectedFacility] = useState("Tap to Select");
  const [index,setIndex] = useState(0);
  useEffect(() => {
    if (facility.length < 6) {
      setFacility((prevFacility) => [
        ...prevFacility,
        `facility${prevFacility.length + 1}`,
      ]);
    }
  }, [facility]); 
  const handleFacilityChange =(e)=>{
    // console.log("data",e.target)
    setSelectedFacility(e.target.value)
  }
    const handleCountryChange = (event) => {
      setSelectedCountry(event.target.value);
    };
    useEffect(()=>{
      console.log(props.index,"propsIndex")
      axios.get(`${api}/auth/getRoleById`,{
        params: {
          Id: props.index // Pass as query parameter
        }
      }).then((res)=>{
        console.log("data after fetch viewroleId",res.data.data)
        setFromValue({
         Date:res.data.data.createdAt.split('T')[0],
         name:res.data.data.name,
         allowed_permissions:res.data.data.allowed_permissions.map((item) => item.name).join(',')       
        })
      }).catch((err)=>{
        console.log(err)
      })
    },[props.index])

const Save = ()=>{
  setIsDrawerOpen(true)
  setIndex(props.index);
  // props.setValues(false)

}
  const list = (anchor) => (
    <Box display="flex" gap={3} sx={{width:420,height:"100%",overflow:"hidden", display:"flex",justifyContent:"space-btween",
        backgroundColor:"transparent"
    }}>
      <AddRole value={isDrawerOpen} setValue={setIsDrawerOpen} index={index}/>
        <Box sx={{backgroundColor:"#fff",height:"3%",padding:"13px",margin:"30px 0 0 0",display:"flex",justifyContent:"center",alignItems:"center",
            borderRadius:"80%", width:"5%"}}>
        <IconButton onClick={()=>props.setValues(false)} sx={{color:"black"}}>
          <CloseIcon sx={{fontSize:"1.2rem"}} />
        </IconButton>
        </Box>
    <Box sx={{p:3, width:"100%", backgroundColor:"#fff",height:"100%"}}>
      <Typography sx={{ textDecoration: 'underline',
          fontWeight: 'bold', 
          
          mb: 2 
       }}>View User Details</Typography>  
       <Box>
       
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Date
          </Typography>
         
          <p>
{fromValue.Date}
          </p>
          {/* <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Country
          </Typography>
          <p></p> */}
      
          </Box>
      
        
         
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
        Name of Role     
          </Typography>
          <p>
{fromValue.name}
          </p>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
        Module's Name    
          </Typography>
          <p>
{fromValue.allowed_permissions}
          </p>
 
       <Box>
       <Button variant="contained" sx={{backgroundColor:"#0a3f73",textTransform:"none",    marginTop: "10%"}} 
             onClick={()=>Save()}

            >
              Edit
            </Button>
       </Box>
    </Box>
    </Box>
  );
 
    return (
        <div>
            
         
              <Drawer
               sx={{
                backgroundColor: "transparent",
            '& .MuiDrawer-paper': { // This targets the paper component within the Drawer
                   backgroundColor: "transparent", // Make paper transparent
                   boxShadow: 'none', // Optional: Remove any shadow
                 }
             }}
                anchor="right"
                open={props.values}
                onClose={()=>props.setValues(false)}
              >
                {list()}
              </Drawer>
              
      
        </div>
      );
}

export default EditRole;





//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      height: '40px', // Adjust height as needed
    },
    '& .MuiSelect-select': {
      height: '56px', // Adjust height as needed
      display: 'flex',
      alignItems: 'center',
    },
  }));
  