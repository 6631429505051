import React, { useState, useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, Button } from '@mui/material';
import axios from 'axios';

const CountryFilter = ({ selectedCountries = [], setSelectedCountries,url }) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    // Fetch all countries from the backend
    const fetchCountries = async () => {
      try {
        const response = await axios.get(url);
        // Extract unique country names from the user data
        const uniqueCountries = [...new Set(response.data.users? response.data.users.map :response.data.data.map((user) => user.country))];
        setCountries(uniqueCountries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    fetchCountries();
  }, []);

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    if (event.target.checked) {
      // Add country to selected list
      setSelectedCountries((prevSelected) => [...prevSelected, selectedCountry]);
    } else {
      // Remove country from selected list
      setSelectedCountries((prevSelected) =>
        prevSelected.filter((country) => country !== selectedCountry)
      );
    }
  };

  return (
    <div>
    <h5 style={{margin: '20px 0px 0px 30px', fontWeight: 'bold'}}>Country</h5>
    <div style={{ display: 'flex', flexDirection: 'column', margin: '30px' }}>
      {countries.length > 0 ? (
        countries.map((country, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                value={country}
                checked={selectedCountries?.includes(country)} // Ensure selectedCountries is an array
                onChange={handleCountryChange}
              />
            }
            label={country}
          />
        ))
      ) : (
        <p>No countries found</p>
      )}
    </div>
    </div>
  );
};

export default CountryFilter;