import React, {useEffect,useState, useContext } from 'react';
import Sidebar from '../sidebar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, InputAdornment, Typography, Box,Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ProgressContext } from "../ContextAPI";
import {api} from "../../../utils/api";
import { companyId } from "../../../utils/companyId";

const BoardComposition = () => {
  const navigate = useNavigate();
  const { setUpdate } = useContext(ProgressContext);
  const [isExist,setIsExist] = useState(false);
    const [addTextField,setAddTextField] = useState(['']);
  const [form,setFrom] = useState({
    companyId:companyId||"",
    boardIndependance: 0,
    boardDiversity:{
      Male:"",
      Female:"",
      MinAge:0,
      MaxAge:0,
      MinYears:0,
      MaxYears:0
    },
    boardMeetingAttendance:0,
    boardCommittees:{
      Total:0,
      Type:[''],
      Range:[0]
    }
  });
  const handleChange =(e)=>{
    const {name,value} = e.target;
    setFrom({
      ...form,
      [name]: value
  })
  }
  const handleChangeCommitteesTotal =(e)=>{
    const {name,value} = e.target;
    setFrom({
      ...form,
      boardCommittees:{ 
        ...form.boardCommittees,
        [name]: value}
  })
  }
  const handleChangeCommittees =(e,index,fieldType)=>{
    const {name,value} = e.target;
  //   setFrom({
  //     ...form,
  //     boardCommittees:{ 
  //       ...form.boardCommittees,
  //       [name]: value}
  // })
  const updatedCommittees = { ...form.boardCommittees };
    updatedCommittees[fieldType][index] = value;
    setFrom({
      ...form,
      boardCommittees: updatedCommittees,
    });
  }
  const handleChangeDiversity =(e)=>{
    const {name,value} = e.target;
    setFrom({
      ...form,
      boardDiversity:{ 
        ...form.boardDiversity,
        [name]: value}
  })
  }
  // console.log(form)
  useEffect(()=>{
    setUpdate("GOVERNANCE");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/governance/Board-composition/${form.companyId}`
        );
        console.log(response.data)
        setFrom({
          companyId:companyId||"",
          boardIndependance: response.data.boardIndependance.value,
          boardDiversity:{
            Male:response.data.boardDiversity.Male,
            Female:response.data.boardDiversity.Female,
            MinAge:response.data.boardDiversity.MinAge,
            MaxAge:response.data.boardDiversity.MaxAge,
            MinYears:response.data.boardDiversity.MinYears,
            MaxYears:response.data.boardDiversity.MaxYears},
            boardMeetingAttendance:response.data.boardMeetingAttendance.value,
            boardCommittees:{
              Total:response.data.boardCommittees.Total,
              Type:response.data.boardCommittees.Type,
              Range:response.data.boardCommittees.Range.value,
            }
          });
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
        // alert(err);
      }
    };
    fetchData();
  },[])
  // const AddTextfield = (e, index, fieldType) => {
  //   // const { name, value } = e.target;
  
  //   // // Create a copy of the current boardCommittees
  //   // const updatedCommittees = { ...form.boardCommittees };
  
  //   // // Update the specific field (Type or Range) at the given index
  //   // updatedCommittees[fieldType][index] = value;
  
  //   // // Set the updated state
  //   // setFrom({
  //   //   ...form,
  //   //   boardCommittees: updatedCommittees,
  //   // });

  // };
  const AddTextField = () => {
    const updatedCommittees = { ...form.boardCommittees };
    updatedCommittees.Type.push('');
    updatedCommittees.Range.push('');
    setFrom({
      ...form,
      boardCommittees: updatedCommittees,
    });
  };

  
  const Save = () => {
   
      axios({method:isExist?'put':'post',
        url:`${api}/governance/Board-composition${isExist ? `/${form.companyId}` : ""}`,
        data:form}).then((response)=>{
        console.log(response)
        alert(response.data.message)
         navigate("/governance/exclutive-compensation");
      }).catch((err)=>{
        console.log(err,err.data)
        alert(err.message)
      })

  };

  const Back = () => {
    navigate("/social/supply-chain");
    setUpdate("SOCIAL");
  };

  return (
    <Box display="flex" width="100%" sx={{backgroundColor:"#d6d7d8"}}>
      <Sidebar title="board composition & effectiveness" />
      <Box width="100%" sx={{p: 3,mt:2}} className=''>
      <Typography variant="h5" sx={{marginBottom:"20px",mt:5,fontWeight: 'bold' }}>
          GOVERNANCE: BOARD COMPOSITION & EFFECTIVENESS
        </Typography>

        <Box className='mainWater'>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Board Independence
          </Typography>
          {console.log("boardIndependance",form.boardIndependance)}
          <TextField
            variant="outlined"
            placeholder="12"
            // type='number'
            name='boardIndependance'
            // inputMode="numeric"
            value={form.boardIndependance===0?"":form.boardIndependance}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            sx={{
              // '& input[type=number]': {
              //   MozAppearance: 'textfield',
              //   WebkitAppearance: 'none',
              // },
               mb: 2,width:"70%"
            }}
            onChange = {handleChange}
          />

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Board Diversity
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, mb: 2 ,width:"70%"}}>
            <TextField
              variant="outlined"
              name='Male'
              type='text'
              placeholder="Male"
              value={form.boardDiversity.Male}
             fullWidth
             onChange = {handleChangeDiversity}
            /> :
            <TextField
              variant="outlined"
              name='Female'
              placeholder="Female"
              value={form.boardDiversity.Female}
             fullWidth
             onChange={handleChangeDiversity}
            />
          </Box>
          
          <Box sx={{ display: 'flex', gap: 1, mb: 2,width:"70%" }}>
            <TextField
              variant="outlined"
              placeholder="Minimum Age"
              name="MinAge"
              value={form.boardDiversity.MinAge === 0?"":form.boardDiversity.MinAge}
             fullWidth
             onChange={handleChangeDiversity}
            /> _
            <TextField
              variant="outlined"
              placeholder="Maximum Age"
              name = "MaxAge"
              value={form.boardDiversity.MaxAge===0?"":form.boardDiversity.MaxAge}
              fullWidth
              onChange={handleChangeDiversity}
            />
          </Box>

          <Box sx={{ display: 'flex',width:"70%", gap: 1, mb: 2 }}>
            <TextField
              variant="outlined"
              placeholder="Min. years of experience"
               fullWidth
               name='MinYears'
               value={form.boardDiversity.MinYears===0?"":form.boardDiversity.MinYears}
               onChange={handleChangeDiversity}
            /> _
            <TextField
              variant="outlined"
              placeholder="Max. years of experience"
              fullWidth
              name='MaxYears'
              value={form.boardDiversity.MaxYears===0?"":form.boardDiversity.MaxYears}
              onChange={handleChangeDiversity}
            />
          </Box>

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Board Meeting Attendance
          </Typography>
          <TextField
            variant="outlined"
            placeholder="80"
            name='boardMeetingAttendance'
            value={form.boardMeetingAttendance===0?"":
              form.boardMeetingAttendance
            }
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            sx={{ mb: 2 ,width:"70%"}}
            onChange={handleChange}
          />

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
            Board Committees
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Total number of committees"
            sx={{ mb: 2 ,width:"70%"}}
            name='Total'
            value={form.boardCommittees.Total===0?"":form.boardCommittees.Total}
            onChange={handleChangeCommitteesTotal}
          />

{form.boardCommittees.Type.map((item,i)=>{
 return <Box sx={{ display: 'flex', gap: 1, mb: 2,width:"70%" }}>
  <TextField
    variant="outlined"
    placeholder="Type of committees"
    fullWidth
    name='Type'
    value={item}
    onChange={(e) => handleChangeCommittees(e, i, 'Type')}
  />
  <TextField
    variant="outlined"
    placeholder="Attendance Rate"
    fullWidth
    InputProps={{
      endAdornment: <InputAdornment position="end">%</InputAdornment>,
    }}
    name="Range"
    value={form.boardCommittees.Range[i] === 0 ? '' : form.boardCommittees.Range[i]} 
    sx={{ mb: 2 }}
    // onChange={handleChangeCommittees}
    onChange={(e) => handleChangeCommittees(e, i, 'Range')}
  />

</Box>
})}


           
          <Box sx={{ mb: 3, display: 'flex', justifyContent: 'flex-end', width:"70%"  }}>
            <Button variant="outlined" endIcon={<AddIcon />} sx={{boarderColor:"#0a3f73",
              color:"#0a3f73", textTransform: 'none' }}
              onClick={AddTextField}
              >
              Add
            </Button>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 , margin:"0 20px 10px 0"}}>
          <Button
            variant="outlined"
            onClick={Back}
            startIcon={<ArrowBackIcon />}
            sx={{color:"#0a3f73",borderColor:"#0a3f73",textTransform:"none"}}
          >
            Back
          </Button>
            <Button variant="contained" sx={{backgroundColor:"#0a3f73",textTransform:"none"}} 
            onClick={Save} endIcon={<ArrowForwardIcon />}>
              Save and Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BoardComposition;
