



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
// import { api } from '../../../../../utils/api';

// const RoleFilter = ({ onRoleFilterChange }) => {
//   const [roles, setRoles] = useState([]); // Initialize roles as an empty array
//   const [selectedRoles, setSelectedRoles] = useState([]);

//   useEffect(() => {
//     const loadRoles = async () => {
//       try {
//         const response = await axios.get(`${api}/auth/getAllUsers`);  // Assuming this API returns roles
//         const fetchedRoles = response.data.roles || []; // Ensure roles is an array
//         setRoles(fetchedRoles);
//       } catch (error) {
//         console.error('Error fetching roles:', error.response ? error.response.data : error.message);
//         setRoles([]); // In case of error, set roles to an empty array to avoid undefined access
//       }
//     };
//     loadRoles();
//   }, []);

//   const handleCheckboxChange = (event) => {
//     const { value, checked } = event.target;
//     setSelectedRoles((prevSelectedRoles) =>
//       checked
//         ? [...prevSelectedRoles, value]
//         : prevSelectedRoles.filter((role) => role !== value)
//     );
//   };

//   useEffect(() => {
//     // Pass the selected roles to the parent component
//     onRoleFilterChange(selectedRoles);
//   }, [selectedRoles, onRoleFilterChange]);

//   return (
//     <div>
//       <h5 style={{margin: '20px 0px 0px 30px', fontWeight: 'bold'}}>Role</h5>
//       {roles.length > 0 ? (
//         <FormGroup>
//           {roles.map((role, index) => (
//             <FormControlLabel
//               key={index}
//               control={
//                 <Checkbox
//                   value={role.name}  // Assuming the role object has a 'name' field
//                   onChange={handleCheckboxChange}
//                 />
//               }
//               label={role.name}
//             />
//           ))}
//         </FormGroup>
//       ) : (
//         <p>No roles available.</p> // Display this when there are no roles
//       )}
//     </div>
//   );
// };

// export default RoleFilter;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { api } from '../../../../../utils/api';

const RoleFilter = ({ onRoleFilterChange }) => {
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  useEffect(() => {
    const loadRoles = async () => {
      try {
        const response = await axios.get(`${api}/auth/getAllUsers`); // Adjust endpoint as needed
        const usersData = response.data.users || [];
        
        // Extract unique role names
        const uniqueRoles = [...new Set(usersData.map(user => user.role?.name || 'N/A'))];
        setRoles(uniqueRoles);
      } catch (error) {
        console.error('Error fetching roles:', error);
        setRoles([]);
      }
    };
    loadRoles();
  }, []);


 
  
  

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedRoles((prevSelectedRoles) =>
      checked ? [...prevSelectedRoles, value] : prevSelectedRoles.filter((role) => role !== value)
    );
  };

  useEffect(() => {
    onRoleFilterChange(selectedRoles);
  }, [selectedRoles, onRoleFilterChange]);

  return (
    <div>
      <h5 style={{ margin: '20px 0px 0px 30px', fontWeight: 'bold' }}>Role</h5>
      <div style={{ display: 'flex', flexDirection: 'column', margin: '30px' }}>
      {roles.length > 0 ? (
        <FormGroup>
          {roles.map((role) => (
            <FormControlLabel
              key={role}
              control={
                <Checkbox
                  value={role}
                  checked={selectedRoles.includes(role)}
                  onChange={handleCheckboxChange}
                />
              }
              label={role}
            />
          ))}
        </FormGroup>
      ) : (
        <p>No roles available.</p>
      )}
    </div>
    </div>
  );
};

export default RoleFilter;