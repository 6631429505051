



// import React, { useState } from 'react';
// import { Box, Button, TextField, Typography, Grid } from '@mui/material';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import dayjs from 'dayjs';

// // Import required plugins
// import weekOfYear from 'dayjs/plugin/weekOfYear';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// import localizedFormat from 'dayjs/plugin/localizedFormat';
// import isBetween from 'dayjs/plugin/isBetween';
// import advancedFormat from 'dayjs/plugin/advancedFormat';
// import axios from 'axios';
// import { api } from '../../../../../utils/api';

// // Extend dayjs with the required plugins
// dayjs.extend(weekOfYear);
// dayjs.extend(customParseFormat);
// dayjs.extend(localizedFormat);
// dayjs.extend(isBetween);
// dayjs.extend(advancedFormat);

// const UserDateFilter = (props) => {
//   const [startDate, setStartDate] = useState(null); // Start date state
//   const [endDate, setEndDate] = useState(null); // End date state
//   const [] = useState(); 

//   const handleApply = () => {
//     console.log('Selected Start Date:', startDate);
//     console.log('Selected End Date:', endDate);
//     axios.get(`${api}/auth/getAllUsers`,{
//       params: {
//         startDate: startDate,
//         endDate:endDate
//       }}).then((res)=>{
//        console.log(res)
//        props.setFilteredRoles(res.data.data)
//        props.setDrawerFilterOpen(false);
//     }).catch((err)=>{
//      console.log(err)
//     })
//   };

//   return (
//     <Box
//       sx={{
//         // padding: '20px',
//         // backgroundColor: '#f5f5f5',
//         boxSizing:"border-box",
//         borderRadius: '8px',
//         // boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
//         width: '320px',
//       }}
//     >
//       <Typography
//         variant="h5"
//         gutterBottom
//         sx={{
//           fontWeight: 'bold',
//           marginBottom: '30px',
//           // textAlign: 'left',
//         }}
//       >
//         Date
//       </Typography>
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <Grid container spacing={1} alignItems="center" justifyContent="center">
//           <Grid item xs={5}>
//             <DatePicker
//               label="Start Date"
//               value={startDate}
//               onChange={(newValue) => setStartDate(newValue)}
//               renderInput={(params) => (
//                 <TextField
//                   fullWidth
//                   {...params}
//                   inputProps={{
//                     ...params.inputProps,
//                     placeholder: 'MM/DD/YYYY',
//                   }}
//                 />
//               )}
//             />
//           </Grid>
//           <Grid item>
//             <Typography sx={{ margin: '0 3px', fontWeight: 'bold' }}>to</Typography>
//           </Grid>
//           <Grid item xs={5}>
//             <DatePicker
//               label="End Date"
//               value={endDate}
//               onChange={(newValue) => setEndDate(newValue)}
//               renderInput={(params) => (
//                 <TextField
//                   fullWidth
//                   {...params}
//                   inputProps={{
//                     ...params.inputProps,
//                     placeholder: 'MM/DD/YYYY',
//                   }}
//                 />
//               )}
//             />
//           </Grid>
//         </Grid>
//       </LocalizationProvider>
//       <Button
//         variant="contained"
//         onClick={handleApply}
//         sx={{
//           marginTop: '20px',
//           backgroundColor: '#1c486b',
//           color: '#FFFFFF',
//           '&:hover': { backgroundColor: '#003366' },
//           width: '30%',
//         }}
//       >
//         Apply
//       </Button>
//     </Box>
//   );
// };

// export default UserDateFilter;



import React, { useState } from 'react';
import { Box, TextField, Typography, Grid } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

// Import required plugins
import weekOfYear from 'dayjs/plugin/weekOfYear';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';

// Extend dayjs with the required plugins
dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);

const UserDateFilter = ({ startDate, endDate, setStartDate, setEndDate }) => {

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        borderRadius: '8px',
        width: '320px',
      }}
    >
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          marginBottom: '30px',
        }}
      >
        Date
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item xs={5}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'MM/DD/YYYY',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Typography sx={{ margin: '0 3px', fontWeight: 'bold' }}>to</Typography>
          </Grid>
          <Grid item xs={5}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'MM/DD/YYYY',
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Box>
  );
};

export default UserDateFilter;
