import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { api } from "../../../utils/api";
import Sidebar from "../sidebar";
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import { companyId } from "../../../utils/companyId";
import InputCard from "./InputCard";
import { getNestedValue } from "../../../utils/getNestedValue";

const Biodiversity = () => {
  const navigate = useNavigate();
  const [isExist, setIsExist] = useState(false);
  const [formData, setFormData] = useState({
    impactOnBiodiversity: { value: "" },
    initiativesForBiodiversityPreservation: {
      numberOfProjects: "",
      scopeOfProjects: "",
    },
    landRehabilitation: { value: "" },
    companyId,
  });

  // Biodiversity data for creating input cards
  const biodiversityData = [
    {
      title: "Impact on Biodiversity",
      placeholder: "1000",
      name: "impactOnBiodiversity.value",
      adornmentText: "Hectares of land affected",
    },
    {
      title: "Initiatives for Biodiversity Preservation",
      nestedData: [
        {
          placeholder: "scope of projects",
          name: "initiativesForBiodiversityPreservation.scopeOfProjects",
        },
        {
          placeholder: "number of projects",
          name: "initiativesForBiodiversityPreservation.numberOfProjects",
        },
      ],
    },
    {
      title: "Land Rehabilitation",
      placeholder: "80",
      name: "landRehabilitation.value",
      adornmentText: "% of total waste",
    },
  ];

  // Navigating back
  const Back = () => {
    navigate("/environment/waste-management");
  };

  // validating input values
  const validateForm = () => {
    const {
      impactOnBiodiversity,
      initiativesForBiodiversityPreservation,
      landRehabilitation,
    } = formData;

    if (
      impactOnBiodiversity.value === "" ||
      isNaN(impactOnBiodiversity.value) ||
      !initiativesForBiodiversityPreservation.numberOfProjects ||
      isNaN(initiativesForBiodiversityPreservation.numberOfProjects) ||
      initiativesForBiodiversityPreservation.scopeOfProjects === "" ||
      landRehabilitation.value === "" ||
      isNaN(landRehabilitation.value)
    ) {
      alert("Please fill in all fields with valid values.");
      return false;
    }
    return true;
  };

  // fetching data if alredy exists
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/environment/biodiversity-and-land-use/${companyId}`
        );
        console.log(response.data.biodiversityData);
        setFormData(response.data.biodiversityData);
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
      }
    };
    fetchData();
  }, []);

  // save the form data and make the post request
  const Save = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/environment/biodiversity-and-land-use${
          isExist ? `/${companyId}` : ""
        }`,
        data: { ...formData, companyId },
      });
      // alert(response.data.message);
      navigate("/environment/pollution");
    } catch (err) {
      console.log(err.response.data.message);
      alert(err.response.data.message);    }
  };

  // Handling the input value
  const handleChange = (e) => {
    const { name, value } = e.target;
    const [prefix, key] = name.split(".");
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [prefix]: {
          ...prevFormData[prefix],
          [key]: value.trim() ||  Number(value) || "",
        },
      };
    });
  };

  return (
    <Box sx={{ display: "flex", backgroundColor: "rgb(214, 215, 216)" }}>
      <Sidebar title="Biodiversity and Land Use" />
      <Box sx={{ p: 2, ml: 2, width: "100%" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 5 }}>
          ENVIRONMENT: BIODIVERSITY AND LAND USE
        </Typography>
        <Box sx={{ mt: 5, width: "70%" }}>
          {/* Maps the biodiversity data */}
          {biodiversityData.map((item, index) => (
            <React.Fragment key={index}>
              {item.nestedData ? (
                <Box>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {item.title}
                  </Typography>
                  <Box sx={{ display: "flex", width: "100%", gap: 1 }}>
                    {item.nestedData.map((el, i) => (
                      <InputCard
                        key={i}
                        placeholder={el.placeholder}
                        name={el.name}
                        value={getNestedValue(formData, el.name)}
                        handleChange={handleChange}
                      />
                    ))}
                  </Box>
                </Box>
              ) : (
                <InputCard
                  title={item.title}
                  placeholder={item.placeholder}
                  name={item.name}
                  value={getNestedValue(formData, item.name)}
                  handleChange={handleChange}
                  adornmentText={item.adornmentText}
                />
              )}
            </React.Fragment>
          ))}
          {/* Back, Save Buttons */}
          <ActionButtons Back={Back} Move={Save} />
        </Box>
      </Box>
    </Box>
  );
};

export default Biodiversity;
