import { Button } from '@mui/material'
import React from 'react'

const ApplyButton = ({handleApply}) => {
  return (
    <Button
        variant="contained"
        onClick={handleApply}
        sx={{
          marginTop: '20px',
          backgroundColor: '#1c486b',
          color: '#FFFFFF',
          '&:hover': { backgroundColor: '#003366' },
          width: '10%',
          position:"fixed",
          bottom:"20px"
        }}
      >
        Apply
      </Button>
  )
}

export default ApplyButton