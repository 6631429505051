// src/components/Future.js
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../sidebar";
import { ProgressContext } from "../../ContextAPI";
import axios from "axios";
import { api } from "../../../../utils/api";
import {  Box, Typography } from "@mui/material";
import QuestionCard from "./QuestionCard";
import ActionButtons from "../MaterialTopics/ActionButtons";
import { companyId } from "../../../../utils/companyId";
const FuturePlan = () => {
  const { setUpdate } = useContext(ProgressContext);
  const navigate = useNavigate();
  const [isExist, setIsExist] = useState(false);

  // questions data for creating input fileds
  const questions = [
    {
      question:
        "Where do you envision your company in terms of sustainability practices in the next 5-10 years?",
      name: "sustainabilityVision",
    },
    {
      question:
        "Are there any specific areas or aspects of sustainability that your company plans to focus on in the future?",
      name: "focusAreas",
    },
    {
      question:
        "Is there any additional information you would like to provide regarding your company's approach to sustainability?",
      name: "additionalInfo",
    },
  ];

  const [formData, setFormData] = useState({
    sustainabilityVision: "",
    focusAreas: "",
    additionalInfo: "",
  });

  // fetch data if already exists
  useEffect(() => {
    const companyId=localStorage.getItem("companyId");
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/company/future-plans/${companyId}`
        );
        setFormData(response.data.futurePlans);
        setIsExist(true);
      } catch (err) {
        console.error("Error:", err);
        // alert(err);
      }
    };
    fetchData();
  }, []);

  // handle input value
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // validate input fields
  const validateForm = () => {
    const { sustainabilityVision, focusAreas, additionalInfo } = formData;
    if (
      !sustainabilityVision.trim() ||
      !focusAreas.trim() ||
      !additionalInfo.trim()
    ) {
      alert("All fields are required. Please fill in all fields.");
      return false;
    }
    return true;
  };

  // Save data and make post request
  const Move = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      console.log(formData,companyId)
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/company/future-plans${isExist ? `/${companyId}` : ""}`,
        data:{...formData,companyId:companyId},
      });
      // alert(response.data.message);
      // setUpdate("MATERIAL");
      navigate("/material-topics");
    } catch (error) {
      console.log("Error:", error);
      alert("There was an error submitting the data!", error);
    }
  };
// Navigate back
  const Back = () => {
    navigate("/challenges-concerns");
  };

  return (
    <Box sx={{ display: "flex", backgroundColor: "rgb(214, 215, 216)"}}>
      <Sidebar title="Future plans" />
      <Box sx={{ p: 2, mt: 2, width:"100%" ,ml:3, height: "100vh"}}>
        <Typography variant="h5" sx={{ fontWeight: "bold" ,mt:5}}>
          FUTURE PLANS
        </Typography>
        <Box sx={{ mt: 5 ,width:"70%"}}>
        {questions.map((item, index) => (
          <QuestionCard
            key={index}
            question={item.question}
            name={item.name}
            value={formData[item.name]}
            onChange={handleChange}
          />
        ))}
        <ActionButtons Back={Back} Move={Move} />
      </Box>
      </Box>
    </Box>
  );
};

export default FuturePlan;
