import React,{useEffect, useState} from 'react';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './Risk.css';
import axios from 'axios';
import {api} from '../../../utils/api';
import { companyId } from "../../../utils/companyId";

const RiskManagement = () => {
  const[isExist,setisExist]=useState(false);
  const [formData,setFromData]=useState({
    companyId:companyId,
    enterpriseRiskMangementFramework:{
          radio:"yes",
          Scope:""
        },
        indentificationMitigationEsgRisk:{
          esg_risk:[""],
          mitigation:[""]
        },
        IncidenceResponsePlan:{
          existence:[""],
          effectiveness:[""],

        },

});
const handleChangeRisk = (e)=>{
      const{name,value}=e.target
      setFromData({
        ...formData,
        enterpriseRiskMangementFramework:{
          ...formData.enterpriseRiskMangementFramework,
          [name]:value
        },
      })

}
const handleChangeDesciption=(e,index,fieldType)=>{
  const{name,value}=e.target
  // setFromData({
  //   ...formData,
  //   indentificationMitigationEsgRisk:{
  //     ...formData.indentificationMitigationEsgRisk,
  //     [name]:value
  //   }
  // })
  const updatedCommittees = { ...formData.indentificationMitigationEsgRisk };
  updatedCommittees[fieldType][index] = value;
  setFromData({
    ...formData,
    indentificationMitigationEsgRisk: updatedCommittees,
  });
}
const addIncidenceResponsePlan =()=>{
  const updatedCommittees = { ...formData.IncidenceResponsePlan};
  updatedCommittees.existence.push('');
  updatedCommittees.effectiveness.push('');

  setFromData({
    ...formData,
    IncidenceResponsePlan: updatedCommittees,
  });
}
const handleChangeResponse=(e,index,fieldType)=>{
  const {name,value}=e.target
//   setFromData({
//   ...formData,
//   IncidenceResponsePlan:{
//     ...formData.IncidenceResponsePlan,
//    [name]:value
//   }
// })
const updatedCommittees = { ...formData.IncidenceResponsePlan };
updatedCommittees[fieldType][index] = value;
setFromData({
  ...formData,
  IncidenceResponsePlan: updatedCommittees,
});
}
useEffect(()=>{
  // const companyId = companyId;
  axios.get(`${api}/governance/risk-mangement/${companyId}`).then((res)=>{
  //  console.log(res)
   setFromData({
    enterpriseRiskMangementFramework:{
          radio:res.data.enterpriseRiskMangementFramework.radio===true?
          "yes":"no",
          Scope:res.data.enterpriseRiskMangementFramework.Scope
        },
        indentificationMitigationEsgRisk:{
          esg_risk:res.data.indentificationMitigationEsgRisk.esg_risk,
          mitigation:res.data.indentificationMitigationEsgRisk.mitigation
        },
        IncidenceResponsePlan:{
          existence:res.data.IncidenceResponsePlan.existence,
          effectiveness:res.data.IncidenceResponsePlan.effectiveness,

        },

})
setisExist(true);
  }).catch((err)=>{
    console.log(err)
  })
},[])
console.log(formData)
  const navigate = useNavigate();

  const Save = () => {
    // const companyId = companyId;
    axios({method:isExist?'put':'post',
      url:`${api}/governance/risk-mangement${isExist ? `/${companyId}` : ""}`,
    data:formData}).then((res)=>{
      console.log(res,"data")
      alert(res.data.message)
      navigate('/governance/stakeholder');
    }).catch((err)=>{
      console.log(err,"error data",err.message)
      alert(err.message)
    })
    // 
  };
  
const AddIdentification=()=>{
  const updatedCommittees = { ...formData.indentificationMitigationEsgRisk };
  updatedCommittees.esg_risk.push('');
  updatedCommittees.mitigation.push('');

  setFromData({
    ...formData,
    indentificationMitigationEsgRisk: updatedCommittees,
  });
}
  const Back = () => {
    navigate('/governance/ethics');
  };

  return (
    <Box display="flex" width="100%" className="risk">
      <Sidebar title="Risk Management" />
      <Box width="100%" sx={{ p: 3, mt: 2 }}>
        <Typography variant="h5" sx={{ marginBottom: '20px', mt: 3, fontWeight: 'bold' }}>
          GOVERNANCE: RISK MANAGEMENT
        </Typography>

        <Box className="mainRisk">
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }} className="heading" gutterBottom>
            Enterprise Risk Management Framework
          </Typography>
          <RadioGroup row name="radio"
           value={formData.enterpriseRiskMangementFramework.radio}
           onChange={handleChangeRisk}
           sx={{ mb: 2 }}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>

        {formData.enterpriseRiskMangementFramework.radio==="yes"?
       <TextField
       variant="outlined"
       placeholder="Scope..."
       className="brief"
       name='Scope'
       value={formData.enterpriseRiskMangementFramework.Scope}
       onChange={handleChangeRisk}
       multiline
       rows={3}
       sx={{ mb: 3, width: '70%' }}
     />:""  
      }  
       

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }} className="heading" gutterBottom>
            Identification and Mitigation of ESG Risks
          </Typography>
          <Grid container spacing={2} sx={{ mb: 3,width:"70%" }}>
        {formData.indentificationMitigationEsgRisk.esg_risk.map((item,i)=>{
          return<>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                placeholder="Description of ESG Risk"
                className="riskText"
                name='esg_risk'
                value={item}
                onChange={(e)=>handleChangeDesciption(e,i,'esg_risk')}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                placeholder="Mitigation"
                className="riskText"
                name='mitigation'
                value={formData.indentificationMitigationEsgRisk.mitigation[i]}
                onChange={(e)=>handleChangeDesciption(e,i,'mitigation')}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
          </>
        })}  
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                endIcon={<AddIcon />}
                sx={{ color: "#0a3f73",textTransform:"none" }}
                className="add"
                onClick={AddIdentification}
              >
                Add
              </Button>
            </Grid>
          </Grid>

          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }} className="heading" gutterBottom>
            Identification and Mitigation of ESG Risks
          </Typography>
          <Grid container spacing={2} sx={{ mb: 3 , width:"70%"}}>
      {formData.IncidenceResponsePlan.existence.map((item,i)=>{
        return <>
          <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                placeholder="Existence"
                className="riskText"
                name='existence'
                value={item}
                onChange={(e)=>handleChangeResponse(e,i,'existence')}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                placeholder="Effectiveness"
                className="riskText"
                name='effectiveness'
                value={formData.IncidenceResponsePlan.effectiveness[i]}
               onChange={(e)=>handleChangeResponse(e,i,'effectiveness')}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
        </>
      })}    
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button
                variant="outlined"
                endIcon={<AddIcon />}
                sx={{ color: "#0a3f73", textTransform:"none" }}
                className="add"
                onClick={addIncidenceResponsePlan}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box display="flex" justifyContent="space-between" width="70%" sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            sx={{color:"#0a3f73",borderColor:"#0a3f73",textTransform:"none"}}
            onClick={Back}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Button
            variant="contained"
            sx={{backgroundColor:"#0a3f73",textTransform:"none"}}
            onClick={Save}
            endIcon={<ArrowForwardIcon />}
          >
            Save and continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default RiskManagement;
