import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
  Stack,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Checkbox,
  Drawer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import AddRole from "./AddRole";
import ViewRole from "./ViewRole";
import User from "./User";
import DateRangePickerComponent from "./Filters/Date";
import { api } from "../../../utils/api";
import FilterPage from "../../../Components/Masters/Filter/FilterPage";
import FilterSidebar from "../../../Components/Masters/Filter/FilterSidebar";
import ApplyButton from "./Filters/ApplyButton";
import CheckboxDrawer from "./Filters/Checkbox";

const Role = () => {
  const [showUsers, setShowUsers] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [roleEdit, setRoleEdit] = useState(0);
  const [selectedUserIndex, setSelectedUserIndex] = useState(null);
  const [roles, setRoles] = useState([]); // Initialize roles as an empty array
  const [filteredRoles, setFilteredRoles] = useState([]); // Store filtered roles
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpenEdit, setIsDrawerOpenEdit] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteIndexEdit, setDeleteIndexEdit] = useState(0);
  const [allowPermision, setAllowPermission] = useState(null);
  const [index, setIndex] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [filterRoleNames, setFilterRoleNames] = useState([]); // Track selected roles for filtering
  const [startDate, setStartDate] = useState(null); // Start date state
  const [endDate, setEndDate] = useState(null); // End date state
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [selectedRoles, setSelectedRoles] = useState([]); // Store selected countries
  const [totalPages, setTotalPages] = useState(1);

  const [selectedFilter, setSelectedFilter] = useState("Date");

  const fetchRoles = async (filters = {}) => {
    try {
      console.log(filters);
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
      }).toString();
      console.log("Query Params sent to API:", queryParams, filters); // Debugging line

      const response = await axios.get(`${api}/auth/getRoles?${queryParams}`);

      console.log("Fetched data after filtering:", response.data); // Debugging line
      setRoles(response.data.data.filter((item)=>item.deactive === false));
     setFilteredRoles(response.data.data.filter((item)=>item.deactive === false));
      setTotalPages(response.data.totalPages); // Update total pages for pagination
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, [page, rowsPerPage, sortConfig]);

  const handleAddRole = () => {
    setIsDrawerOpen(true);
  };

  const filters = ["Date", "Role"];

  const handleDelete = (index) => {
    console.log(index, "index");
    setDeleteDialog(true);
    setAnchorEl(null);
    setSelectedUserIndex(null);
  };

  const handleDeleteAggree = (index) => {
    console.log(index, "index++");
    axios
      .put(
        `${api}/auth/DeleteRole`,
        { deactive: true },
        {
          params: {
            Id: index, // Pass as query parameter
          },
        }
      )
      .then((res) => {
        console.log(
          "res",
          res.data.data._id,
          filteredRoles.filter((item) => item._id !== res.data.data._id)
        );
        setDeleteDialog(false);
        setFilteredRoles(
          filteredRoles.filter((item) => item._id !== res.data.data._id)
        );
      })
      .catch((err) => {
        console.log("err", err);
      });
    console.log(index, "index");
    setDeleteDialog(true);
    setAnchorEl(null);
    setIndex(index);
    setSelectedUserIndex(null);
  };
  const handleRoleAssign = () => {
    // axios.put(`${api}/auth/DeleteRole`,{}).then((res)=>{}).catch((err)=>{})
    setIsDrawerOpen(true);
    setDeleteDialog(false);
    setIndex(deleteIndexEdit);
  };

  const handleView = (index) => {
    setIndex(index);
    setAnchorEl(null);
    setSelectedUserIndex(null);
    setIsDrawerOpenEdit(true);
  };

  const handleEdit = (index) => {
    setIndex(index);
    setAnchorEl(null);
    setSelectedUserIndex(null);
    setIsDrawerOpen(true);
  };

  const handleMenuClick = (event, index, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserIndex(index);
    setRoleEdit(data._id);
    setDeleteIndexEdit(
      data.allowed_permissions.map((item) => item.name).join("") ===
        "delete Access"
        ? data._id
        : 0
    );
    setAllowPermission(
      data.allowed_permissions.map((item) => item.name).join("")
    );

    console.log(
      data.allowed_permissions.map((item) => item.name).join(""),
      "data++"
    );
  };
  console.log("roleEdit", roleEdit);
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedUserIndex(null);
  };

  const open = Boolean(anchorEl);

  const handleFilter = () => {
    setDrawerFilterOpen(true);
  };

  // const handleRoleSelection = (roleName) => {
  //   const updatedRoles = filterRoleNames.includes(roleName)
  //     ? filterRoleNames.filter((role) => role !== roleName)
  //     : [...filterRoleNames, roleName];
  //   setFilterRoleNames(updatedRoles);
  // };

  const handleApplyFilters = () => {
    setPage(1); // Reset to first page on filter application
    fetchRoles({
      name: selectedRoles.join(","),
      startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
      endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    });
    setDrawerFilterOpen(false);
  };

  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/auth/getRoles`}
            {...{ startDate, endDate, setStartDate, setEndDate }}
          />
        ); // Date range picker component
      case "Role":
        return <CheckboxDrawer {...{ setSelectedRoles, selectedRoles }} />; // Checkbox filter for roles
      default:
        return (
          <DateRangePickerComponent
            setDrawerFilterOpen={setDrawerFilterOpen}
            setFilteredRoles={setFilteredRoles}
          />
        );
    }
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };

  if (showUsers) {
    return <User />;
  }

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        <AddRole
          value={isDrawerOpen}
          setValue={setIsDrawerOpen}
          index={index}
        />
        <ViewRole
          values={isDrawerOpenEdit}
          setValues={setIsDrawerOpenEdit}
          index={index}
        />

        {/* Filter Drawer */}
        {drawerFilterOpen && (
          <Drawer
            anchor="right"
            open={drawerFilterOpen}
            onClose={() => setDrawerFilterOpen(false)}
            sx={{
              backgroundColor: "transparent",
              "& .MuiDrawer-paper": {
                // This targets the paper component within the Drawer
                backgroundColor: "transparent", // Make paper transparent
                boxShadow: "none", // Optional: Remove any shadow
                width: "50%",
              },
            }}
          >
            <Box
              display="flex"
              gap={3}
              sx={{
                width: "100%",
                display: "flex",
                overflowX: "hidden",
                maxWidth: "100%",
                justifyContent: "space-btween",
                height: "100%",
                backgroundColor: "transparent",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#f5f5f5",
                  height: "3%",
                  padding: "13px",
                  margin: "30px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "80%",
                  width: "3%",
                }}
              >
                <IconButton
                  onClick={() => setDrawerFilterOpen(false)}
                  sx={{ color: "black" }}
                >
                  <CloseIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Box>
              <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
                {/* Sidebar for selecting the filter */}
                <FilterSidebar
                  {...{ setSelectedFilter, selectedFilter, filters }}
                />

                {/* Filter content area */}
                <Box
                  sx={{
                    flexGrow: 1,
                    backgroundColor: "#F9F9F9",
                    padding: "20px",
                  }}
                >
                  {renderSelectedFilter()}
                  <ApplyButton handleApply={handleApplyFilters} />
                </Box>
              </Box>
            </Box>
          </Drawer>
        )}

        {deleteDialog && allowPermision === "delete Access" ? (
          <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this role?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteDialog(false)}>Disagree</Button>
              <Button onClick={() => handleDeleteAggree(deleteIndexEdit)}>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}>
            <DialogContent>
              <DialogContentText>
                Please assign this role before deleting it.
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {console.log("deleteIndexEdit___", deleteIndexEdit)}
              <Button onClick={() => handleRoleAssign()}>Assign</Button>
            </DialogActions>
          </Dialog>
        )}

        <Grid
          container
          alignItems="center"
          sx={{ width: "80%", margin: "5% 4% 0% 22%" }}
        >
          <Grid item xs={6}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: "8px" }}
            >
              Roles and Permission
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={() => setShowUsers(true)}
                sx={{
                  backgroundColor: "#e0e0e0",
                  fontSize: "14px",
                  color: "black",
                  textTransform: "none",
                  "&:hover": { backgroundColor: "#1c486b", color: "#FFFFFF" },
                }}
              >
                List of Users
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#1c486b",
                  fontSize: "14px",
                  color: "#FFFFFF",
                  textTransform: "none",
                }}
              >
                List of Roles
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              endIcon={<FilterListIcon />}
              sx={{
                margin: "10px 10px -29px 0px",
                backgroundColor: "#ffffff",
                color: "black",
                "&:hover": { backgroundColor: "#1c486b", color: "#FFFFFF" },
              }}
              onClick={handleFilter}
            >
              Filter
            </Button>
            <Button
              variant="contained"
              sx={{
                margin: "10px -36px -29px 0px",
                backgroundColor: "#1c486b",
                fontSize: "14px",
                textTransform: "none",
              }}
              endIcon={<AddIcon />}
              onClick={handleAddRole}
            >
              Add New Role
            </Button>
          </Grid>
        </Grid>
      </Toolbar>

      <TableContainer
        component={Paper}
        sx={{ maxWidth: "74%", margin: "0% 5% 0% 23%" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={6}
                sx={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  textAlign: "left",
                  padding: "16px",
                }}
              >
                List of Roles
              </TableCell>
            </TableRow>
            <TableRow>
              {["Date", "Name of Role", "Modules Name", "Action"].map(
                (header, index) => (
                  <TableCell
                    key={header}
                    sx={{
                      padding: "12px",
                      textAlign: "center",
                      borderRight: "1px solid #ddd",
                      "&:first-of-type": {
                        marginLeft: "20px",
                        paddingLeft: "16px",
                      },
                      "&:not(:first-of-type)": {
                        paddingLeft: "16px",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontWeight: "bold", marginRight: "10px" }}
                      >
                        {header}
                      </Typography>
                    </Box>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredRoles.map((role, index) => (
              <TableRow key={index}>
                <TableCell sx={{ borderRight: "1px solid #ddd", width: "10%" }}>
                  {new Date(role.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd", width: "20%" }}>
                  {role.name}
                </TableCell>
                <TableCell sx={{ borderRight: "1px solid #ddd", width: "60%" }}>
                  {role.allowed_permissions.map((perm, permIndex) => (
                    <div key={permIndex}>
                      {perm.name} - {perm.module}
                    </div>
                  ))}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>
                  <IconButton
                    onClick={(event) => handleMenuClick(event, index, role)}
                  >
                    <MoreHorizIcon
                      sx={{ fontWeight: "bold", color: "black" }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Action Menu */}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "120px",
            border: "1px solid #ddd",
            marginLeft: "-47px",
          },
        }}
      >
        {console.log("filteredRoles", filteredRoles, selectedUserIndex)}
        <MenuItem
          onClick={() => handleEdit(roleEdit)}
          sx={{ fontSize: "14px" }}
        >
          <EditIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Edit
        </MenuItem>
        <MenuItem
          onClick={() => handleView(roleEdit)}
          sx={{ fontSize: "14px" }}
        >
          <AssignmentIcon sx={{ marginRight: "8px", fontSize: "large" }} /> View
          Logs
        </MenuItem>
        <MenuItem
          onClick={() => handleDelete(roleEdit)}
          sx={{ fontSize: "14px" }}
        >
          <DeleteIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Role;
