import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// initial kickoff routes
import FuturePlan from "./Components/Quetionary/InitialKickoff/FuturePlan/FuturePlan";
import Question from "./Components/Quetionary/InitialKickoff/question";
import Company from "./Components/Quetionary/InitialKickoff/CompanyInformation";
import Sustainability from "./Components/Quetionary/InitialKickoff/Sustainability";
import MaterialTopics from "./Components/Quetionary/InitialKickoff/MaterialTopics/MaterialTopics";
// social routes
import DevelopmentAndIncusion from "./Components/Quetionary/Social/Diversity";
import Employeedevelopment from "./Components/Quetionary/Social/EmployeeDevelopment";
import Community from "./Components/Quetionary/Social/Community";
import HumanRight from "./Components/Quetionary/Social/HumanRight";
import CustomerRelation from "./Components/Quetionary/Social/CustomerRelation";
import SupplyChain from "./Components/Quetionary/Social/SupplyChain";

// governance routes
import BoardComposition from "./Components/Quetionary/Governance/BoardComposition";
import ExclutiveCompensation from "./Components/Quetionary/Governance/ExcutiveCompensation";
import Stakeholder from "./Components/Quetionary/Governance/Stakeholder";
import Ethics from "./Components/Quetionary/Governance/EthicsCompliance";
import Cybersecurity from "./Components/Quetionary/Governance/Cybersecurity";
import RiskManagement from "./Components/Quetionary/Governance/RiskMangement";

// Environment routes
import WaterManagement from "./Components/Quetionary/EnvironmentTopics/WaterManagement";
import EnergyManagement from "./Components/Quetionary/EnvironmentTopics/EnergyManagement";
import Pollution from "./Components/Quetionary/EnvironmentTopics/Pollution";
import WasteManagement from "./Components/Quetionary/EnvironmentTopics/WasteManagement";
import Biodiversity from "./Components/Quetionary/EnvironmentTopics/Biodiversity";
import SustainableSourcing from "./Components/Quetionary/EnvironmentTopics/SustainableSourcing";
import GHGEmissions from "./Components/Quetionary/EnvironmentTopics/GHGEmissions";
import SidebarRoutes from "./Components/Masters/MasterSidebar/MasterRoutes";

//Masters
import User from "./Pages/Masters/Roles and Permissions/User";
import Role from "./Pages/Masters/Roles and Permissions/Roles";
import CheckboxLabels from "./Pages/Masters/Roles and Permissions/Filters/Checkbox";
import MasterRoutes from "./Components/Masters/MasterSidebar/MasterRoutes";
import DashboardRoutes from "./Components/DashboardSidebar/DashboardRoutes";
import FilterPage from "./Components/Masters/Filter/FilterPage";
// import Facility from "./Pages/Masters/Facility Master/Facility";

function Home() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Company />} />
          <Route path="/sustainability" element={<Sustainability />} />
          <Route path="/challenges-concerns" element={<Question />} />
          <Route path="/future-plans" element={<FuturePlan />} />
          <Route path="/material-topics" element={<MaterialTopics />} />
          {/* environment quetionary routes */}
          <Route
            path="/environment/energy-management"
            element={<EnergyManagement />}
          />
          <Route path="/environment/ghg-emissions" element={<GHGEmissions />} />
          <Route
            path="/environment/water-management"
            element={<WaterManagement />}
          />
          <Route
            path="/environment/waste-management"
            element={<WasteManagement />}
          />
          <Route path="/environment/biodiversity" element={<Biodiversity />} />
          <Route path="/environment/pollution" element={<Pollution />} />
          <Route
            path="/environment/sustainable-sourcing"
            element={<SustainableSourcing />}
          />
          <Route
            path="/social/employee-development"
            element={<Employeedevelopment />}
          />
          <Route
            path="/social/diversity"
            element={<DevelopmentAndIncusion />}
          />
          <Route path="/social/community" element={<Community />} />
          <Route path="/social/human-right" element={<HumanRight />} />
          <Route
            path="/social/customer-relation"
            element={<CustomerRelation />}
          />
          <Route path="/social/supply-chain" element={<SupplyChain />} />

          <Route
            path="/governance/board-composition"
            element={<BoardComposition />}
          />
          <Route
            path="/governance/exclutive-compensation"
            element={<ExclutiveCompensation />}
          />
          <Route path="/governance/ethics" element={<Ethics />} />
          <Route
            path="/governance/risk-management"
            element={<RiskManagement />}
          />
          <Route path="/governance/stakeholder" element={<Stakeholder />} />
          <Route path="/governance/cybersecurity" element={<Cybersecurity />} />

          {/* <Route path="/masters/user" element={<User />} /> */}
          {/* <Route path="/masters/role" element={<Role />} /> */}

          {/* Filters */}
          <Route path="/master/filter/checkbox" element={<CheckboxLabels />} />
          <Route path="master/role/filter" element={<FilterPage/>}/>
          {/* <Route path="masters/facility" element={<Facility />}/> */}

        </Routes>
        {/* Dashboard Sidebar Routes */}
         <DashboardRoutes /> 

        {/* Masters routes */}
        <MasterRoutes />
      </Router>
    </div>
  );
}

export default Home;