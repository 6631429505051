import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { api } from "../../../utils/api";
import Sidebar from "../sidebar";
import ActionButtons from "../InitialKickoff/MaterialTopics/ActionButtons";
import { companyId } from "../../../utils/companyId";
import InputCard from "./InputCard";

const WaterManagement = () => {
  const navigate = useNavigate();
  const [isExist, setIsExist] = useState(false);
  const [formData, setFormData] = useState({
    totalWaterWithdrawal: { value: "" },
    waterIntensity: { value: "" },
    waterRecycled: { value: "" },
    waterDischargeQuality: "",
    companyId,
  });

  // water data for creating input cards
  const waterData = [
    {
      title: "Total Water Withdrawal",
      placeholder: "1000",
      name: "totalWaterWithdrawal",
      adornmentText: "cubic meter",
    },
    {
      title: "Water Intensity",
      placeholder: "0.5",
      name: "waterIntensity",
      adornmentText: "water use per unit of output",
    },
    {
      title: "Water Recycled and Reused",
      placeholder: "64",
      name: "waterRecycled",
      adornmentText: "% of total water use",
    },
    {
      title: "Water Discharge Quality",
      placeholder: "8",
      name: "waterDischargeQuality",
      adornmentText: "compliance with local standards",
    },
  ];

  // navigate back
  const Back = () => {
    navigate("/environment/ghg-emissions");
  };

  // validating input fields
  const validateForm = () => {
    const {
      totalWaterWithdrawal,
      waterIntensity,
      waterRecycled,
      waterDischargeQuality,
    } = formData;

    if (
      totalWaterWithdrawal.value === "" ||
      isNaN(totalWaterWithdrawal.value) ||
      waterIntensity.value === "" ||
      isNaN(waterIntensity.value) ||
      waterRecycled.value === "" ||
      isNaN(waterRecycled.value) ||
      waterDischargeQuality === "" ||
      isNaN(waterDischargeQuality)
    ) {
      alert("Please fill in all fields with valid numeric values.");
      return false;
    }
    return true;
  };

  // fetching data if already exists
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${api}/environment/water-management/${companyId}`
        );
        console.log(response);
        if (response.data && response.data.waterData) {
          setFormData(response.data.waterData);
          setIsExist(true);
        }
      } catch (err) {
        console.error("Error:", err);
      }
    };
    fetchData();
  }, []);

  // Save the formData and make the post request
  const Save = async () => {
    console.log(formData);
    if (!validateForm()) {
      return;
    }
    try {
      const response = await axios({
        method: isExist ? "put" : "post",
        url: `${api}/environment/water-management${
          isExist ? `/${companyId}` : ""
        }`,
        data: { ...formData, companyId },
      });
      // alert(response.data.message);
      navigate("/environment/waste-management");
    } catch (err) {
      console.log(err.response.data.message);
      alert(err.response.data.message);
    }
  };

  // Handling the input value
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      if (name !== "waterDischargeQuality") {
        return {
          ...prevFormData,
          [name]: {
            ...prevFormData[name],
            value: value.trim() || Number(value) || "",
          },
        };
      } else {
        return {
          ...prevFormData,
          [name]:  value.trim() || Number(value) || "",

        };
      }
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "rgb(214, 215, 216)",
        height: "100vh",
      }}
    >
      <Sidebar title="Water Management" />
      <Box sx={{ p: 2, ml: 2, width: "100%", height: "100vh" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mt: 5 }}>
          ENVIRONMENT: WATER MANAGEMENT
        </Typography>
        <Box sx={{ mt: 5, width: "70%" }}>
          {/* Maps the water data */}
          {waterData.map((item, index) => (
            <InputCard
              key={index}
              title={item.title}
              placeholder={item.placeholder}
              name={item.name}
              value={
                typeof formData[item.name] === "object"
                  ? formData[item.name].value
                  : formData[item.name]
              }
              handleChange={handleChange}
              adornmentText={item.adornmentText}
            />
          ))}
          {/* Back, Save Buttons */}
          <ActionButtons Back={Back} Move={Save} />
        </Box>
      </Box>
    </Box>
  );
};

export default WaterManagement;
