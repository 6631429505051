import React, { useState, useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, Button } from '@mui/material';
import axios from 'axios';

const FacilityFilter = ({ selectedFacilities = [], setSelectedFacilities,url }) => {
  const [facilities, setFacilities] = useState([]);

  useEffect(() => {
    // Fetch all Facilities from the backend
    const fetchFacilities = async () => {
      try {
        const response = await axios.get(url);
        // Extract unique Facility names from the user data
        console.log(response.data)
        const uniqueFacilities = [...new Set(response.data.data.map((facility) => facility.facilityName))];
        setFacilities(uniqueFacilities);
      } catch (error) {
        console.error('Error fetching Facilities:', error);
      }
    };

    fetchFacilities();
  }, []);

  const handleFacilityChange = (event) => {
    const selectedFacility = event.target.value;
    if (event.target.checked) {
      // Add Facility to selected list
      setSelectedFacilities((prevSelected) => [...prevSelected, selectedFacility]);
    } else {
      // Remove Facility from selected list
      setSelectedFacilities((prevSelected) =>
        prevSelected.filter((Facility) => Facility !== selectedFacility)
      );
    }
  };

  return (
    <div>
    <h5 style={{margin: '20px 0px 0px 30px', fontWeight: 'bold'}}>Facility Name</h5>
    <div style={{ display: 'flex', flexDirection: 'column', margin: '30px' }}>
      {facilities.length > 0 ? (
        facilities.map((Facility, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                value={Facility}
                checked={selectedFacilities?.includes(Facility)} // Ensure selectedFacilities is an array
                onChange={handleFacilityChange}
              />
            }
            label={Facility}
          />
        ))
      ) : (
        <p>No Facilities found</p>
      )}
    </div>
    </div>
  );
};

export default FacilityFilter;