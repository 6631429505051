import React, { useState, useEffect } from 'react';
import { Button, TextField, TextareaAutosize, Box, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Sidebar from '../sidebar';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EastIcon from '@mui/icons-material/East';
import axios from 'axios';
import { companyId } from "../../../utils/companyId";
import { api } from "../../../utils/api";

const HumanRight = () => {
  const navigate = useNavigate();
  const [numberOfAssessments, setNumberOfAssessments] = useState('');
  const [percentageOperationsAssessed, setPercentageOperationsAssessed] = useState('');
  const [policies, setPolicies] = useState([{ existence: '', implementation: '' }]);
  const [totalIncidents, setTotalIncidents] = useState('');
  const [percentageResolution, setPercentageResolution] = useState('');
  const [getData, setGetData] = useState(true);
  const [isExist, setIsExist] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (companyId) {
        try {
          const response = await axios.get(`${api}/social/human_rights/${companyId}`);
          const data = response.data;

          // Set state with fetched data
          setNumberOfAssessments(data.humanRightsAssessments.numberOfAssessmentsDone || '');
          setPercentageOperationsAssessed(data.humanRightsAssessments.percentageOfOperationsAssessed.value || '');
          setPolicies(data.policiesAndProcedures || [{ existence: '', implementation: '' }]);
          setTotalIncidents(data.incidentsOfHumanRightsViolations.totalIncidentsRaised || '');
          setPercentageResolution(data.incidentsOfHumanRightsViolations.percentageOfResolution.value || '');
          setIsExist(true);
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setGetData(false);
        }
      } else {
        setGetData(false);
      }
    };

    fetchData();
  }, []);



  const Save = async () => {
    const humanRightsData = {
      humanRightsAssessments: {
        numberOfAssessmentsDone: Number(numberOfAssessments),
        percentageOfOperationsAssessed: {
          value: Number(percentageOperationsAssessed),
          unit: "%"
        }
      },
      policiesAndProcedures: policies,
      incidentsOfHumanRightsViolations: {
        totalIncidentsRaised: Number(totalIncidents),
        percentageOfResolution: {
          value: Number(percentageResolution),
          unit: "%"
        }
      },
      companyId: companyId // Replace with actual companyId
    };
  
    try {
      if (isExist) {
        // PUT request if isExist exists
        await axios.put(`${api}/social/human_rights/${companyId}`, humanRightsData);
        window.alert('Human Rights record updated successfully.'); // Alert for update
      } else {
        // POST request if isExist does not exist
        await axios.post(`${api}/social/human_rights`, humanRightsData);
        window.alert('Human Rights record created successfully.'); // Alert for creation
      }
      navigate("/social/customer-relation");
    } catch (error) {
      console.error('Error saving data:', error);
      window.alert('An error occurred while saving data.'); // Alert for error
    }
  };
  

  const handleBack = () => {
    navigate("/social/community");
  };

  const handleAddPolicy = () => {
    setPolicies([...policies, { existence: '', implementation: '' }]);
  };

  return (
    <Box display="flex" sx={{ height: '100%', backgroundColor: 'rgb(214, 215, 216)' }}>
      <Sidebar title="Human Rights" />
      <Box sx={{ width: '100%', padding: '20px' }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', margin: '32px 0px' }}>
          SOCIAL: HUMAN RIGHTS
        </Typography>

        {/* Human Rights Assessments Section */}
        <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', margin: '2px' }}>
          Human Rights Assessments
        </Typography>
        <Box display="flex" gap={2} mb={2}>
          <TextField
            variant="outlined"
            placeholder="Number of assessment done"
            value={numberOfAssessments}
            onChange={(e) => setNumberOfAssessments(e.target.value)}
            sx={{
              width: '36%',
              backgroundColor: '#d6d7d8',
              borderRadius: '4px',
              borderColor: '#6b6c6d',
              '& .MuiOutlinedInput-root': {
                height: '50px',
                '& fieldset': {
                  borderColor: '#6b6c6d',
                },
              },
            }}
            InputProps={{
              sx: {
                padding: '0 10px',
              },
            }}
          />
          <TextField
            variant="outlined"
            placeholder="% of operations assessed"
            value={percentageOperationsAssessed}
            onChange={(e) => setPercentageOperationsAssessed(e.target.value)}
            sx={{
              width: '36%',
              backgroundColor: '#d6d7d8',
              borderRadius: '4px',
              borderColor: '#6b6c6d',
              '& .MuiOutlinedInput-root': {
                height: '50px',
                '& fieldset': {
                  borderColor: '#6b6c6d',
                },
              },
            }}
            InputProps={{
              sx: {
                padding: '0 10px',
              },
            }}
          />
        </Box>

        {/* Policies and Procedures Section */}
        <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', margin: '20px 0px 2px 0px' }}>
          Policies and Procedures on Human Rights
        </Typography>
        {policies.map((policy, index) => (
          <Box display="flex" gap={2} mb={2} key={index}>
            <TextareaAutosize
              minRows={3}
              placeholder="Existence"
              value={policy.existence}
              onChange={(e) => {
                const updatedPolicies = [...policies];
                updatedPolicies[index].existence = e.target.value;
                setPolicies(updatedPolicies);
              }}
              style={{
                width: '36%',
                padding: '8px',
                backgroundColor: '#d6d7d8',
                borderRadius: '4px',
                borderColor: '#6b6c6d',
              }}
            />
            <TextareaAutosize
              minRows={3}
              placeholder="Implementation"
              value={policy.implementation}
              onChange={(e) => {
                const updatedPolicies = [...policies];
                updatedPolicies[index].implementation = e.target.value;
                setPolicies(updatedPolicies);
              }}
              style={{
                width: '36%',
                padding: '8px',
                backgroundColor: '#d6d7d8',
                borderRadius: '4px',
                borderColor: '#6b6c6d',
              }}
            />
          </Box>
        ))}
        {/* <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAddPolicy}
          sx={{
            color: '#204769',
            borderColor: '#204769',
            '&:hover': {
              backgroundColor: 'rgba(25, 118, 210, 0.04)',
              color: '#115293',
              borderColor: '#115293',
            },
          }}
        >
          Add Policy
        </Button> */}
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAddPolicy}
          sx={{
            backgroundColor: 'transparent',
            color: '#204769',
            borderColor: '#204769',
            borderRadius: '4px',
            padding: '6px 16px',
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: 1.75,
            letterSpacing: '0.02857em',
            textTransform: 'uppercase',
            margin: '2px 0px 0px 610px',
            '&:hover': {
              backgroundColor: 'rgba(25, 118, 210, 0.04)',
              color: '#115293',
              borderColor: '#115293',
            },
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'background-color 0.3s, color 0.3s',
          }}
        >
          Add
        </Button>

        {/* Incidents of Human Rights Violations Section */}
        <Typography variant="subtitle1" sx={{ fontSize: '16px', fontWeight: 'bold', margin: '2px' }}>
          Incidents of Human Rights Violations
        </Typography>
        <Box display="flex" gap={2} mb={2}>
          <TextField
            variant="outlined"
            placeholder="Total Number of Incidents Raised"
            value={totalIncidents}
            onChange={(e) => setTotalIncidents(e.target.value)}
            sx={{
              width: '36%',
              backgroundColor: '#d6d7d8',
              borderRadius: '4px',
              borderColor: '#6b6c6d',
              '& .MuiOutlinedInput-root': {
                height: '50px',
                '& fieldset': {
                  borderColor: '#6b6c6d',
                },
              },
            }}
            InputProps={{
              sx: {
                padding: '0 10px',
              },
            }}
          />
          <TextField
            variant="outlined"
            placeholder="Percentage of Resolution"
            value={percentageResolution}
            onChange={(e) => setPercentageResolution(e.target.value)}
            sx={{
              width: '36%',
              backgroundColor: '#d6d7d8',
              borderRadius: '4px',
              borderColor: '#6b6c6d',
              '& .MuiOutlinedInput-root': {
                height: '50px',
                '& fieldset': {
                  borderColor: '#6b6c6d',
                },
              },
            }}
            InputProps={{
              sx: {
                padding: '0 10px',
              },
            }}
          />
        </Box>

        <Box display="flex" justifyContent="space-between" sx={{ marginTop: '70px' }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
            sx={{
              color: '#204769',
              borderColor: '#204769',
              '&:hover': {
                backgroundColor: 'rgba(25, 118, 210, 0.04)',
                color: '#115293',
                borderColor: '#115293',
              },
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            endIcon={<EastIcon />}
            onClick={Save}
            sx={{
              backgroundColor: '#1C486B',
              '&:hover': {
                backgroundColor: '#163B57',
              },
            }}
          >
            Save and Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default HumanRight;
