// import React, {useState, useEffect} from 'react';
// import Sidebar from  '../sidebar';
// import { useNavigate } from 'react-router-dom';
// import {
//   Box,
//   Button,
//   InputAdornment,
//   TextField,
//   Typography,
//   IconButton,
// } from '@mui/material';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import EastIcon from '@mui/icons-material/East';
// import AddIcon from '@mui/icons-material/Add';
// import { ArrowBack, ArrowForward } from '@mui/icons-material';
// import { companyId } from "../../../utils/companyId";

// const DevelopmentAndIncusion= () => {
//   const navigate = useNavigate();

//   const [womenInWorkforce, setWomenInWorkforce] = useState('');
//   const [womenInManagement, setWomenInManagement] = useState('');
//   const [womenInBoard, setWomenInBoard] = useState('');
//   const [minorityEmployees, setMinorityEmployees] = useState('');
//   const [malePayGap, setMalePayGap] = useState('');
//   const [femalePayGap, setFemalePayGap] = useState('');
//   const [minorityPayGap, setMinorityPayGap] = useState('');
//   const [majorityPayGap, setMajorityPayGap] = useState('');
//   const [initiative1, setInitiative1] = useState('');
//   const [effectiveness1, setEffectiveness1] = useState('');
  
//   // const [initiative2, setInitiative2] = useState('');
//   // const [effectiveness2, setEffectiveness2] = useState('');
//   const [isUpdating, setIsUpdating] = useState(false);

//   useEffect(() => {
//     if (companyId) {
//       fetchData();
//     }
//   }, [companyId]);

//   const fetchData = async () => {
//     try {
//       const response = await fetch(`${api}/social/diversity_inclusion/${companyId}`);
//       if (response.ok) {
//         const data = await response.json();
//         // Populate state variables with fetched data
//         setWomenInWorkforce(data.genderDiversity.womenInWorkforce.value || '');
//         setWomenInManagement(data.genderDiversity.womenInManagement.value || '');
//         setWomenInBoard(data.genderDiversity.womenInBoard.value || '');
//         setMinorityEmployees(data.representationOfMinorities.minorityEmployees.value || '');
//         setMalePayGap(data.payEquity.malePayGap || '');
//         setFemalePayGap(data.payEquity.femalePayGap || '');
//         setMinorityPayGap(data.payEquity.minorityPayGap || '');
//         setMajorityPayGap(data.payEquity.majorityPayGap || '');
//         setInitiative1(data.initiativesForDiversityAndInclusion[0]?.initiative || '');
//         setEffectiveness1(data.initiativesForDiversityAndInclusion[0]?.effectiveness || '');
//         setIsUpdating(true); // Mark as updating
//       } else {
//         console.error("Failed to fetch d${api}
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };
  



//   const Save = async () => {
//     const data = {
//       genderDiversity: {
//         womenInWorkforce: { value: womenInWorkforce, unit: "%" },
//         womenInManagement: { value: womenInManagement, unit: "%" },
//         womenInBoard: { value: womenInBoard, unit: "%" }
//       },
//       representationOfMinorities: {
//         minorityEmployees: { value: minorityEmployees, unit: "%" }
//       },
//       payEquity: {
//         malePayGap: malePayGap,
//         femalePayGap: femalePayGap,
//         minorityPayGap: minorityPayGap,
//         majorityPayGap: majorityPayGap
//       },
//       initiativesForDiversityAndInclusion: [
//         { initiative: initiative1, effectiveness: effectiveness1 }
//       ],
//       companyId: companyId
//     };
  
//     try {
//       let response;
//       if (isUpdating) {
//         // PUT request to update the data
//         response = await fetch(`${api}/social/diversity_inclusion/${companyId}`, {
//           method: 'PUT',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(data),
//         });
  
//         if (response.ok) {
//           alert('Data updated successfully');
//           console.log("Data updated successfully");
//         } else {
//           alert('Failed to update data');
//           console.error("Failed to update data");
//         }
//       } else {
//         // POST request to save the data
//         response = await fetch('${api}/social/diversity_inclusion', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(data),
//         });
  
//         if (response.ok) {
//           alert('Data saved successfully');
//           console.log("Data saved successfully");
//         } else {
//           alert('Failed to save data');
//           console.error("Failed to save data");
//         }
//       }
  
//       if (response.ok) {
//         navigate("/community");
//       }
//     } catch (error) {
//       console.error("Error during fetch:", error);
//     }
//   };
  
//   const Back = () => {
//     navigate("/employeedevelopment");
//   };

//   const handleAddClick =() =>{

//   }

//   return (

// <Box display="flex" width="100%" bgcolor="rgb(214, 215, 216)" sx={{ height: '130vh'}}>
// <Sidebar title="Diversity and Inclusion" />
// <Box width="100%" ml={1} mt={6} position="relative">
//   <Typography variant="h5" gutterBottom sx={{ marginTop: "-20px", marginBottom: "20px", fontWeight: 'bold' }}>
//     SOCIAL: DIVERSITY AND INCLUSION
//   </Typography>

//   <Box>
//     <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold' }}>
//       Gender Diversity
//     </Typography>

//     <TextField
//       fullWidth
//       placeholder="% of women in workforce"
//       value={womenInWorkforce}
//       onChange={(e) => setWomenInWorkforce(e.target.value)}
//       InputProps={{
//         endAdornment: <InputAdornment position="end">
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
//           %
//         </Box>
//       </InputAdornment>,
//         className: 'inputfield',
//       }}
//       sx={{ 
//         mb: 2, 
//         width: '70%',
//         '& .MuiInputBase-input': {
//           height: '25px', // Ensure input text height matches
//           padding: '0 14px', // Adjust padding if necessary
//         },
//       }}
//     />

//     <TextField
//       fullWidth
//       placeholder="% of women in management"
//       value={womenInManagement}
//       onChange={(e) => setWomenInManagement(e.target.value)}
//       InputProps={{
//         endAdornment: <InputAdornment position="end">
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
//           %
//         </Box>
//       </InputAdornment>,
//         className: 'inputfield',
//       }}
//       sx={{ 
//         mb: 2, 
//         width: '70%',
//         '& .MuiInputBase-input': {
//           height: '25px', // Ensure input text height matches
//           padding: '0 14px', // Adjust padding if necessary
//         },
//       }}
//     />

//     <TextField
//       fullWidth
//       placeholder="% of women in board"
//       value={womenInBoard}
//       onChange={(e) => setWomenInBoard(e.target.value)}
//       InputProps={{
//         endAdornment: <InputAdornment position="end">
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
//           %
//         </Box>
//       </InputAdornment>,
//         className: 'inputfield',
//       }}
//       sx={{ 
//         mb: 2, 
//         width: '70%',
//         '& .MuiInputBase-input': {
//           height: '25px', // Ensure input text height matches
//           padding: '0 14px', // Adjust padding if necessary
//         },
//       }}
//     />

//     <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold', marginTop: '10px'  }}>
//       Representation of Minorities
//     </Typography>

//     <TextField
//       fullWidth
//       placeholder="% of minority employees"
//       value={minorityEmployees}
//       onChange={(e) => setMinorityEmployees(e.target.value)}
//       InputProps={{
//         endAdornment: <InputAdornment position="end">
//         <Box sx={{ display: 'flex', alignItems: 'center' }}>
//           <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
//           %
//         </Box>
//       </InputAdornment>,
//         className: 'inputfield',
//       }}
//       sx={{ 
//         mb: 2, 
//         width: '70%',
//         '& .MuiInputBase-input': {
//           height: '25px', // Ensure input text height matches
//           padding: '0 14px', 
//         },
//       }}
//     />

//     <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold', marginTop: '10px' }}>
//       Pay Equity
//     </Typography>

//     <Box display="flex" gap={2} mb={2} sx={{ 
//         width: '70%',
//         '& .MuiInputBase-input': {
//           height: '44px',
//           padding: '0 14px', 
//         },
//       }}>
//       <TextField
//         fullWidth
//         placeholder="Male pay gap"
//         value={malePayGap}
//         onChange={(e) => setMalePayGap(e.target.value)}
//         InputProps={{ className: 'inputfieldEquity' }}
//       />
//       <TextField
//         fullWidth
//         placeholder="Female pay gap"
//         value={femalePayGap}
//         onChange={(e) => setFemalePayGap(e.target.value)}
//         InputProps={{ className: 'inputfieldEquity' }}
//       />
//     </Box>

//     <Box display="flex" gap={2} mb={2} sx={{ 
//         width: '70%',
//         '& .MuiInputBase-input': {
//           height: '44px',
//           padding: '0 14px', 
//         },
//       }}>
//       <TextField
//         fullWidth
//         placeholder="Minority pay gap"
//         value={minorityPayGap}
//         onChange={(e) => setMinorityPayGap(e.target.value)}
//         InputProps={{ className: 'inputfieldEquity' }}
//       />
//       <TextField
//         fullWidth
//         placeholder="Majority pay gap"
//         value={majorityPayGap}
//         onChange={(e) => setMajorityPayGap(e.target.value)}
//         InputProps={{ className: 'inputfieldEquity' }}
//       />
//     </Box>

//     <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold', marginTop: '30px'  }}>
//       Initiatives for Diversity and Inclusion
//     </Typography>

//     <Box display="flex" gap={2} mb={2} sx={{width: '70%'}}>
//       <TextField
//         fullWidth
//         multiline
//         rows={3}
//         placeholder="Initiative"
//         value={initiative1}
//         onChange={(e) => setInitiative1(e.target.value)}
//         InputProps={{ className: 'brief' }}
//       />
//       <TextField
//         fullWidth
//         multiline
//         rows={3}
//         placeholder="Effectiveness"
//         value={effectiveness1}
//         onChange={(e) => setEffectiveness1(e.target.value)}
//         InputProps={{ className: 'brief' }}
//       />
//     </Box>
//     <Button
//       variant="outlined"
//       startIcon={<AddIcon />}
//       onClick={handleAddClick}
//       sx={{
//         backgroundColor: 'transparent',
//         color: '#204769',
//         borderColor: '#204769',
//         borderRadius: '4px',
//         padding: '6px 16px',
//         fontSize: '0.875rem',
//         fontWeight: 500,
//         lineHeight: 1.75,
//         letterSpacing: '0.02857em',
//         textTransform: 'uppercase',
//         margin: '2px 0px 0px 590px',
//         '&:hover': {
//           backgroundColor: 'rgba(25, 118, 210, 0.04)',
//           color: '#115293',
//           borderColor: '#115293',
//         },
//         display: 'inline-flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         transition: 'background-color 0.3s, color 0.3s',
//       }}
//     >
//       Add
//     </Button>
//     <Box display="flex" justifyContent="space-between" sx={{ margin: '70px 10px 25px 0px' }}>
//           <Button
//             variant="outlined"
//             startIcon={<ArrowBackIcon />}
//             onClick={Back}
//             sx={{
//               color: '#204769',
//               borderColor: '#204769',
//               '&:hover': {
//                 backgroundColor: 'rgba(25, 118, 210, 0.04)',
//                 color: '#115293',
//                 borderColor: '#115293',
//               },
//             }}
//           >
//             Back
//           </Button>
//           <Button
//             variant="contained"
//             endIcon={<EastIcon />}
//             onClick={Save}
//             sx={{
//               backgroundColor: '#1C486B',
//               '&:hover': {
//                 backgroundColor: '#163B57',
//               },
//             }}
//           >
//             Save and Continue
//           </Button>
//         </Box>
//   </Box>
// </Box>
// </Box>




// );
// };


// export default DevelopmentAndIncusion;


///////////////////////////////////////////////////

import React, {useState, useEffect} from 'react';
import Sidebar from  '../sidebar';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EastIcon from '@mui/icons-material/East';
import AddIcon from '@mui/icons-material/Add';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { companyId } from "../../../utils/companyId";
import { api } from "../../../utils/api";

const DevelopmentAndIncusion= () => {
  const navigate = useNavigate();

  const [womenInWorkforce, setWomenInWorkforce] = useState('');
  const [womenInManagement, setWomenInManagement] = useState('');
  const [womenInBoard, setWomenInBoard] = useState('');
  const [minorityEmployees, setMinorityEmployees] = useState('');
  const [malePayGap, setMalePayGap] = useState('');
  const [femalePayGap, setFemalePayGap] = useState('');
  const [minorityPayGap, setMinorityPayGap] = useState('');
  const [majorityPayGap, setMajorityPayGap] = useState('');
  const [initiative1, setInitiative1] = useState('');
  const [effectiveness1, setEffectiveness1] = useState('');
  const [fields, setFields] = useState([{ initiative: '', effectiveness: '' }]);
  
  // const [initiative2, setInitiative2] = useState('');
  // const [effectiveness2, setEffectiveness2] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [isExist, setIsExist] = useState(false);
 

  useEffect(() => {
    if (companyId) {
      fetchData();
    }
  }, [companyId]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${api}/social/diversity_inclusion/${companyId}`);
      if (response.ok) {
        const data = await response.json();
        console.log('Fetched data:', data); // Debug: Check the structure of data

        setWomenInWorkforce(data.genderDiversity?.womenInWorkforce?.value || '');
        setWomenInManagement(data.genderDiversity?.womenInManagement?.value || '');
        setWomenInBoard(data.genderDiversity?.womenInBoard?.value || '');
        setMinorityEmployees(data.representationOfMinorities?.minorityEmployees?.value || '');
        setMalePayGap(data.payEquity?.malePayGap || '');
        setFemalePayGap(data.payEquity?.femalePayGap || '');
        setMinorityPayGap(data.payEquity?.minorityPayGap || '');
        setMajorityPayGap(data.payEquity?.majorityPayGap || '');

        // Check if initiativesForDiversityAndInclusion is part of data
        setFields(data.initiativesForDiversityAndInclusion || [{ initiative: '', effectiveness: '' }]);

        setIsUpdating(true);
        setIsExist(true);
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const Save = async () => {
    const payload = {
      genderDiversity: {
        womenInWorkforce: { value: womenInWorkforce, unit: "%" },
        womenInManagement: { value: womenInManagement, unit: "%" },
        womenInBoard: { value: womenInBoard, unit: "%" }
      },
      representationOfMinorities: {
        minorityEmployees: { value: minorityEmployees, unit: "%" }
      },
      payEquity: {
        malePayGap: malePayGap,
        femalePayGap: femalePayGap,
        minorityPayGap: minorityPayGap,
        majorityPayGap: majorityPayGap
      },
      initiativesForDiversityAndInclusion: fields, // Ensure `fields` is correctly formatted
      companyId: companyId
    };
  
    // Determine the request method and URL based on isExist
    const method = isExist ? 'PUT' : 'POST';
    const url = isExist
      ? `${api}/social/diversity_inclusion/${companyId}`
      : `${api}/social/diversity_inclusion`;
  
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        const successMessage = isExist ? 'Data updated successfully' : 'Data saved successfully';
        alert(successMessage);
        console.log(successMessage, responseData);
        navigate("/social/community");
      } else {
        const errorText = await response.text();
        const errorMessage = isExist ? 'Failed to update data' : 'Failed to save data';
        alert(errorMessage);
        console.error(errorMessage, errorText);
      }
    } catch (error) {
      console.error('Error saving data:', error);
      const alertMessage = isExist ? 'An error occurred while updating data' : 'An error occurred while saving data';
      alert(alertMessage);
    }
  };
  
  
  
  const Back = () => {
    navigate("/social/employee-development");
  };

  

  const handleAddClick = () => {
    setFields([...fields, { initiative: '', effectiveness: '' }]);
  };


  const handleFieldChange = (index, field, value) => {
    const newFields = [...fields];
    newFields[index][field] = value;
    setFields(newFields);
  };


  return (

<Box display="flex" width="100%" bgcolor="rgb(214, 215, 216)" sx={{ height: '100%'}}>
<Sidebar title="Diversity and inclusion" />
<Box width="100%" ml={1} mt={6} position="relative">
  <Typography variant="h5" gutterBottom sx={{ marginTop: "-20px", marginBottom: "20px", fontWeight: 'bold' }}>
    SOCIAL: DIVERSITY AND INCLUSION
  </Typography>

  <Box>
    <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold' }}>
      Gender Diversity
    </Typography>

    <TextField
      fullWidth
      placeholder="% of women in workforce"
      value={womenInWorkforce}
      onChange={(e) => setWomenInWorkforce(e.target.value)}
      InputProps={{
        endAdornment: <InputAdornment position="end">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
          %
        </Box>
      </InputAdornment>,
        className: 'inputfield',
      }}
      sx={{ 
        mb: 2, 
        width: '70%',
        '& .MuiInputBase-input': {
          height: '40px', // Ensure input text height matches
          padding: '0 14px', // Adjust padding if necessary
        },
      }}
    />

    <TextField
      fullWidth
      placeholder="% of women in management"
      value={womenInManagement}
      onChange={(e) => setWomenInManagement(e.target.value)}
      InputProps={{
        endAdornment: <InputAdornment position="end">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
          %
        </Box>
      </InputAdornment>,
        className: 'inputfield',
      }}
      sx={{ 
        mb: 2, 
        width: '70%',
        '& .MuiInputBase-input': {
          height: '40px', // Ensure input text height matches
          padding: '0 14px', // Adjust padding if necessary
        },
      }}
    />

    <TextField
      fullWidth
      placeholder="% of women in board"
      value={womenInBoard}
      onChange={(e) => setWomenInBoard(e.target.value)}
      InputProps={{
        endAdornment: <InputAdornment position="end">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
          %
        </Box>
      </InputAdornment>,
        className: 'inputfield',
      }}
      sx={{ 
        mb: 2, 
        width: '70%',
        '& .MuiInputBase-input': {
          height: '40px', // Ensure input text height matches
          padding: '0 14px', // Adjust padding if necessary
        },
      }}
    />

    <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold', marginTop: '10px'  }}>
      Representation of Minorities
    </Typography>

    <TextField
      fullWidth
      placeholder="% of minority employees"
      value={minorityEmployees}
      onChange={(e) => setMinorityEmployees(e.target.value)}
      InputProps={{
        endAdornment: <InputAdornment position="end">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ height: '30px', width: '1px', bgcolor: '#6b6c6d', marginRight: '8px' }} />
          %
        </Box>
      </InputAdornment>,
        className: 'inputfield',
      }}
      sx={{ 
        mb: 2, 
        width: '70%',
        '& .MuiInputBase-input': {
          height: '40px', // Ensure input text height matches
          padding: '0 14px', 
        },
      }}
    />

    <Typography variant="body1" className="heading" sx={{ fontWeight: 'bold', marginTop: '10px' }}>
      Pay Equity
    </Typography>

    <Box display="flex" gap={2} mb={2} sx={{ 
        width: '70%',
        '& .MuiInputBase-input': {
          height: '44px',
          padding: '0 14px', 
        },
      }}>
      <TextField
        fullWidth
        placeholder="Male pay gap"
        value={malePayGap}
        onChange={(e) => setMalePayGap(e.target.value)}
        InputProps={{ className: 'inputfieldEquity' }}
      /> <Typography variant="body1" fontWeight="bold" sx={{margin:'1%'}}>
      :
    </Typography>
      <TextField
        fullWidth
        placeholder="Female pay gap"
        value={femalePayGap}
        onChange={(e) => setFemalePayGap(e.target.value)}
        InputProps={{ className: 'inputfieldEquity' }}
      />
    </Box>

    <Box display="flex" gap={2} mb={2} sx={{ 
        width: '70%',
        '& .MuiInputBase-input': {
          height: '44px',
          padding: '0 14px', 
        },
      }}>
      <TextField
        fullWidth
        placeholder="Minority pay gap"
        value={minorityPayGap}
        onChange={(e) => setMinorityPayGap(e.target.value)}
        InputProps={{ className: 'inputfieldEquity' }}
      /> <Typography variant="body1" fontWeight="bold" sx={{margin:'1%'}}>
      :
    </Typography>
      <TextField
        fullWidth
        placeholder="Majority pay gap"
        value={majorityPayGap}
        onChange={(e) => setMajorityPayGap(e.target.value)}
        InputProps={{ className: 'inputfieldEquity' }}
      />
    </Box>


<Typography variant="body1" className="heading" sx={{ fontWeight: 'bold', marginTop: '30px' }}>
          Initiatives for Diversity and Inclusion
        </Typography>

        {fields.map((field, index) => (
          <Box display="flex" gap={2} mb={2} sx={{ width: '70%' }} key={index}>
            <TextField
              fullWidth
              multiline
              rows={3}
              placeholder="Initiative"
              value={field.initiative}
              onChange={(e) => handleFieldChange(index, 'initiative', e.target.value)}
              InputProps={{ className: 'brief' }}
            />
            <TextField
              fullWidth
              multiline
              rows={3}
              placeholder="Effectiveness"
              value={field.effectiveness}
              onChange={(e) => handleFieldChange(index, 'effectiveness', e.target.value)}
              InputProps={{ className: 'brief' }}
            />
          </Box>
        ))}

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleAddClick}
          sx={{
            backgroundColor: 'transparent',
            color: '#204769',
            borderColor: '#204769',
            borderRadius: '4px',
            padding: '6px 16px',
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: 1.75,
            letterSpacing: '0.02857em',
            textTransform: 'uppercase',
            margin: '2px 0px 0px 590px',
            '&:hover': {
              backgroundColor: 'rgba(25, 118, 210, 0.04)',
              color: '#115293',
              borderColor: '#115293',
            },
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'background-color 0.3s, color 0.3s',
          }}
        >
          Add
        </Button>

    <Box display="flex" justifyContent="space-between" sx={{ margin: '70px 10px 25px 0px' }}>
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            onClick={Back}
            sx={{
              color: '#204769',
              borderColor: '#204769',
              '&:hover': {
                backgroundColor: 'rgba(25, 118, 210, 0.04)',
                color: '#115293',
                borderColor: '#115293',
              },
            }}
          >
            Back
          </Button>
          <Button
            variant="contained"
            endIcon={<EastIcon />}
            onClick={Save}
            sx={{
              backgroundColor: '#1C486B',
              '&:hover': {
                backgroundColor: '#163B57',
              },
            }}
          >
            Save and Continue
          </Button>
        </Box>
  </Box>
</Box>
</Box>




);
};


export default DevelopmentAndIncusion;


