import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Box,
  Grid,
  Menu,
  MenuItem,
  Pagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  PaginationItem,
  Drawer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import axios from "axios";
import AddFacility from './AddFacility';
import EditFacility from './ViewFacility';
import DateRangePickerComponent from "../Roles and Permissions/Filters/Date";
import { api } from "../../../utils/api";
import FilterSidebar from "../../../Components/Masters/Filter/FilterSidebar";
import ApplyButton from "../Roles and Permissions/Filters/ApplyButton";
import CountryFilter from "../Roles and Permissions/Filters/UserFilters/CountryFilter";
import FacilityFilter from "../Roles and Permissions/Filters/FacilityFilter";

const Facility = () => {
  const [facilities, setFacilities] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFacility, setSelectedFacility] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [addFacility,setAddFacility] = useState(false);

  const [viewFacility,setViewFacility] = useState(false);
  const[dataView,setdataView]=useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [limit] = useState(10); // Number of items per page
  const [selectedFilter, setSelectedFilter] = useState("Date");
  const [drawerFilterOpen, setDrawerFilterOpen] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState([]); // Store selected countries
  const [selectedFacilities, setSelectedFacilities] = useState([]); // Store selected facility name
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(6);

  const filters = ["Date", "Facility", "Country"];

  const facilityData=[
    {header:"Date",field:"date"}, 
    {header:"Facility Name",field:"facilityName"},
    {header:"Incharge",field:"incharge"},
    {header: "Outsource Facility",field: "outsourceFacility"},
    {header:"Country",field:"country"},
    {header:"Action"}
  ]
  // Function to render the selected filter component based on user's choice
  const renderSelectedFilter = () => {
    switch (selectedFilter) {
      case "Date":
        return (
          <DateRangePickerComponent
            url={`${api}/master/getAllFacility`}
            {...{ startDate, endDate, setStartDate, setEndDate }}
          />
        );
      case "Facility":
        return (
          <FacilityFilter
            url={`${api}/master/getAllFacility`}
            selectedFacilities={selectedFacilities}
            setSelectedFacilities={setSelectedFacilities}
          />
        ); //
      case "Country":
        return (
          <CountryFilter
            url={`${api}/master/getAllFacility`}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
          />
        );
      default:
        return (
          <DateRangePickerComponent
            setDrawerFilterOpen={setDrawerFilterOpen}
            // setFilteredRoles={setFilteredRoles}
          />
        );
    }
  };
  const handleMenuClick = (event, facility) => {
    setAnchorEl(event.currentTarget);
    setSelectedFacility(facility);
    setdataView(facility)
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedFacility(null);
  };

  const handleEdit = () => {
    console.log("Edit facility:", selectedFacility);
    handleMenuClose();
    setAddFacility(true)
  };

  const handleView = () => {
    console.log("View facility:", selectedFacility);
    handleMenuClose();
    setViewFacility(true);
  };

  const handleDelete = () => {
    console.log("Delete facility:", selectedFacility);
    handleMenuClose();
    setDeleteDialog(true);
  };


  const handleDeleteAggree = () => {
     console.log("indexhandleDeleteAggree",dataView,dataView._id)
    axios.put(`${api}/master/deleteFacility`,{deactived:true},{
      params: {
        Id: dataView._id // Pass as query parameter
      }
  }).then((res)=>{
    console.log(res,"res++")
    setDeleteDialog(false);
    setFacilities(facilities.filter((item)=>item._id !== res.data.data._id));
  //   setAnchorEl(null);
  
  //   setSelectedUserIndex(null);
  }).catch((err)=>{
    console.log(err)
  })
   
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const fetchFacilities = async (filters = {}) => {
    try {
      console.log(sortConfig)
      const queryParams = new URLSearchParams({
        ...filters,
        page, // Add the current page
        limit: rowsPerPage, // Add the number of rows per page
        sortField: sortConfig.key, // Sort configuration
        sortOrder: sortConfig.direction,
      }).toString();
      console.log("Query Params sent to API:", queryParams, filters); // Debugging line

      const response = await axios.get(
        `${api}/master/getAllFacility?${queryParams}`
      );

      console.log("Fetched data after filtering:", response.data); // Debugging line

      setFacilities(response.data.data.filter((item)=>item.deactived === false)); // Update the user list
      setTotalPages(response.data.totalPages); // Update total pages for pagination
      
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchFacilities();
  }, [page, rowsPerPage, sortConfig]);

  const handleApplyFilters = () => {
    console.log("Selected countries:", selectedCountries);

    setPage(1); // Reset to first page on filter application

    fetchFacilities({
      country: selectedCountries.join(","),
      facilityName: selectedFacilities.join(","),
      startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
      endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    });
    setDrawerFilterOpen(false);
  };

  // Handle Sorting function
  const handleSort = (column, direction) => {
    setSortConfig({ key: column, direction });
  };


  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#ededed",
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
       <AddFacility addFacility={addFacility} setAddFacility={setAddFacility} selectedFacility={dataView}/>
       <EditFacility viewFacility={viewFacility} setViewFacility={setViewFacility} selectedFacility={dataView}/>
        {deleteDialog && (
        <Dialog
          open={deleteDialog}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          maxWidth="lg"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to Delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ textTransform: "none" }}
               onClick={() => setDeleteDialog(false)}
            >
              Disagree
            </Button>
            <Button sx={{ textTransform: 'none' }} 
             onClick={handleDeleteAggree}
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Toolbar sx={{ padding: "0 16px", marginBottom: "20px" }}>
        {drawerFilterOpen && (
          <Drawer
            anchor="right"
            open={drawerFilterOpen}
            onClose={() => setDrawerFilterOpen(false)}
            sx={{
              backgroundColor: "transparent",
              "& .MuiDrawer-paper": {
                // This targets the paper component within the Drawer
                backgroundColor: "transparent", // Make paper transparent
                boxShadow: "none", // Optional: Remove any shadow
                width: "50%",
              },
            }}
          >
            <Box
              display="flex"
              gap={3}
              sx={{
                width: "100%",
                display: "flex",
                overflowX: "hidden",
                maxWidth: "100%",
                justifyContent: "space-btween",
                height: "100%",
                backgroundColor: "transparent",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#f5f5f5",
                  height: "3%",
                  padding: "13px",
                  margin: "30px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "80%",
                  width: "4%",
                }}
              >
                <IconButton
                  onClick={() => setDrawerFilterOpen(false)}
                  sx={{ color: "black" }}
                >
                  <CloseIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </Box>
              <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
                {/* Sidebar for selecting the filter */}
                <FilterSidebar {...{setSelectedFilter,selectedFilter,filters}}/>

                {/* Filter content area */}
                <Box
                  sx={{
                    flexGrow: 1,
                    backgroundColor: "#F9F9F9",
                    padding: "20px",
                  }}
                >
                  {renderSelectedFilter()}
                  <ApplyButton handleApply={handleApplyFilters} />
                </Box>
              </Box>
            </Box>
          </Drawer>
        )}{" "}
        <Grid
          container
          alignItems="center"
          sx={{ width: "80%", margin: "5% 4% 0% 22%" }}
        >
          <Grid item xs={6}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", margin: "8px 0px 20px 0px" }}
            >
              Facility Management
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              onClick={() => setDrawerFilterOpen(true)}
              endIcon={<FilterListIcon />}
              sx={{
                margin: "10px 10px -15px 0px",
                backgroundColor: "#ffffff",
                color: "black",
              }}
            >
              Filter
            </Button>
            <Button
              variant="contained"
              endIcon={<AddIcon />}
              sx={{
                margin: "10px -36px -15px 0px",
                backgroundColor: "#1c486b",
                fontSize: "14px",
                textTransform: "none",
              }}
              onClick={()=>{setAddFacility(true);setdataView(null)}}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
      </Toolbar>

      <TableContainer
        component={Paper}
        sx={{ maxWidth: "74%", margin: "0% 5% 0% 23%" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={8}
                sx={{
                  fontWeight: "bold",
                  fontSize: "20px",
                  textAlign: "left",
                  padding: "16px",
                }}
              >
                List of Facilities
              </TableCell>
            </TableRow>
            <TableRow>
              {facilityData.map((column) => (
                <TableCell
                  key={column.header}
                  sx={{
                    padding: "12px",
                    textAlign: "center",
                    borderRight: "1px solid #ddd",
                    borderBottom: "2px solid #ddd",
                    "&:last-child": {
                      borderRight: "none",
                    },
                  }}
                >
                 <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: "bold", marginRight: "10px" }}
                    >
                      {column.header}
                    </Typography>
                    {column.header !== "Action" && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => handleSort(column.field, "asc")}
                        >
                          <ExpandLess
                            sx={{
                              color:
                                sortConfig.key === column.header &&
                                sortConfig.direction === "asc"
                                  ? "blue"
                                  : "inherit",
                              margin: "-10px",
                              fontSize: "20px",
                              color: "black",
                            }}
                          />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => handleSort(column.field, "desc")}
                        >
                          <ExpandMore
                            sx={{
                              color:
                                sortConfig.key === column.header &&
                                sortConfig.direction === "desc"
                                  ? "blue"
                                  : "inherit",
                              margin: "-10px",
                              fontSize: "20px",
                              color: "black",
                            }}
                          />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {facilities.length === 0 ? (
              <TableRow>
                <TableCell colSpan={8} sx={{ textAlign: "center" }}>
                  No facilities available.
                </TableCell>
              </TableRow>
            ) : (
              facilities.map((facility) => (
                <TableRow key={facility._id}>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid #ddd",
                      borderRight: "1px solid #ddd",
                      width: "10%",
                    }}
                  >
                    {new Date(facility.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid #ddd",
                      borderRight: "1px solid #ddd",
                      width: "15%",
                    }}
                  >
                    {facility.facilityName}
                  </TableCell>
                  {/* <TableCell
                    sx={{
                      borderBottom: "1px solid #ddd",
                      borderRight: "1px solid #ddd",
                      width: "20%",
                    }}
                  >
                    {facility.address}
                  </TableCell> */}
                  {/* <TableCell
                    sx={{
                      borderBottom: "1px solid #ddd",
                      borderRight: "1px solid #ddd",
                      width: "15%",
                    }}
                  >
                    {facility.nickname}
                  </TableCell> */}
                  <TableCell
                    sx={{
                      borderBottom: "1px solid #ddd",
                      borderRight: "1px solid #ddd",
                      width: "10%",
                    }}
                  >
                    {facility.incharge}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid #ddd",
                      borderRight: "1px solid #ddd",
                      width: "15%",
                    }}
                  >
                    {facility.outsourceFacility}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "1px solid #ddd",
                      borderRight: "1px solid #ddd",
                      width: "10%",
                    }}
                  >
                    {facility.country}
                  </TableCell>
                  <TableCell
                    sx={{ borderBottom: "1px solid #ddd", width: "5%" }}
                  >
                    <IconButton onClick={(e) => handleMenuClick(e, facility)}>
                      <MoreHorizIcon
                        sx={{ fontWeight: "bold", color: "black" }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ display: "flex", justifyContent: "center", padding: "16px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 3,
            backgroundColor: "#ffffff",
            width: "80%",
            margin: "2% 5% 0% 23%",
            borderRadius: "2%",
            padding: "3px",
          }}
        >
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                components={{
                  previous: () => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: 2,
                      }}
                    >
                      <ArrowBackIcon sx={{ mr: 0.5, color: "green" }} />
                      Previous
                    </Box>
                  ),
                  next: () => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        ml: 2,
                      }}
                    >
                      Next
                      <ArrowForwardIcon sx={{ ml: 0.5, color: "green" }} />
                    </Box>
                  ),
                }}
                sx={{
                  "& .MuiPaginationItem-root": {
                    mx: 1,
                  },
                  "& .MuiPagination-ul": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>

      {/* Menu for Actions */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: "120px",
            border: "1px solid #ddd",
            marginLeft: "-47px",
          },
        }}
      >
        <MenuItem onClick={handleEdit} sx={{ fontSize: "14px" }}>
          <EditIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Edit
        </MenuItem>
        <MenuItem onClick={handleView} sx={{ fontSize: "14px" }}>
          <AssignmentIcon sx={{ marginRight: "8px", fontSize: "large" }} /> View
          Logs
        </MenuItem>
        <MenuItem onClick={handleDelete} sx={{ fontSize: "14px" }}>
          <DeleteIcon sx={{ marginRight: "8px", fontSize: "large" }} /> Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Facility;
