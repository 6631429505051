import React from 'react';
import { Box, Button } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

const ActionButtons = ({ Back, Move }) => (
  <Box
    sx={{
      width: "130%",
      mt:"10%",
      //  mb:"10%",
      display: "flex",
      justifyContent: "space-between",
    }}
  >
    <Button
      variant="outlined"
      sx={{ color: "#1C486B", textTransform: "none" }}
      onClick={Back}
    >
      <WestIcon sx={{ mr: 2 }} /> Back
    </Button>
    <Button
      variant="contained"
      sx={{ bgcolor: "#1C486B", textTransform: "none" }}
      onClick={Move}
    >
      Save and Continue <EastIcon sx={{ ml: 2 }} />
    </Button>
  </Box>
);

export default ActionButtons;
