import  React ,{useState,useEffect} from 'react';
import { Box, TextField, Typography,MenuItem,Button ,IconButton,
  Radio, RadioGroup,FormControlLabel,
}  from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import axios from 'axios';
import contries from '../Roles and Permissions/Country.json';
import { api } from '../../../utils/api';
import { companyId } from '../../../utils/companyId';

function AddFacility(props) {
      const [moduleName, setmoduleName] = useState([]);
      const [alreadyFacility,setAlreadyFacility] = useState(false);
  const [fromValue,setFromValue]= useState({
    companyName:companyId,
    facilityName:"",
    address:"",
    nickname:"",
    incharge:"",
    outsourceFacility:"yes",
    country:"India",
  })


  useEffect(()=>{
    setFromValue({
      companyName:companyId,
      facilityName:props.selectedFacility?.facilityName??"",
      address:props.selectedFacility?.address??"",
      nickname:props.selectedFacility?.nickname??"",
      incharge:props.selectedFacility?.incharge??"",
      outsourceFacility:props.selectedFacility?.outsourceFacility??"yes",
      country:props.selectedFacility?.country??"India",
    })
    setAlreadyFacility(props.selectedFacility===null?false:true)
  },[props.selectedFacility])
  const handleChange =(e)=>{
    const {name,value}=e.target
    console.log("nam===e",name,value)
    setFromValue({
      ...fromValue,
      [name]:value
    })
  }
 
console.log(props.selectedFacility?._id,"props.selectedFacility")
const Save =()=>{
  console.log(fromValue)
  axios({
    method: alreadyFacility ? 'put' : 'post',
    url: alreadyFacility?`${api}/master/updateFacility`:`${api}/master/addFacility`,
    data:  fromValue,  // Send data only if it's a POST request
    params: alreadyFacility ? { Id: props.selectedFacility?._id} : null  // Send params only for PUT request
  })
  .then((res)=>{
    console.log(res,res.data.data)
    props.setAddFacility(false)
    //  localStorage.setItem("roleId",res.data.data._id)
  }).catch(err=>{
    console.log(err)
  })
}


  const list = () => (
    <Box display="flex" gap={3} sx={{width:420,height:"100%", display:"flex",justifyContent:"space-btween",
        backgroundColor:"transparent"
    }}>
        <Box sx={{backgroundColor:"#fff",height:"3%",padding:"13px",margin:"30px 0 0 0",display:"flex",justifyContent:"center",alignItems:"center",
            borderRadius:"80%", width:"5%"}}>
        <IconButton onClick={()=>props.setAddFacility(false)} sx={{color:"black"}}>
          <CloseIcon sx={{fontSize:"1.2rem"}} />
        </IconButton>
        </Box>
    <Box sx={{width:"90%",p:3, backgroundColor:"#fff"}}>
      <Typography sx={{ textDecoration: 'underline',
          fontWeight: 'bold', 
          
          mb: 2 
       }}>Add New Facility</Typography>  
       
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
        Name of Facility     
          </Typography>
         
          <CustomTextField
        //   select
      variant="outlined"
      value={fromValue.facilityName}
      onChange={handleChange}
      fullWidth
      name="facilityName"
      placeholder="Enter Name"
    >
    </CustomTextField>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
       Address    
          </Typography>
          <CustomTextField
          //  select
      variant="outlined"
       value={fromValue.address}
      onChange={handleChange}
      fullWidth
      name="address"
      placeholder="Address"
    >
    </CustomTextField>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
       Nickname    
          </Typography>
         {console.log("console.log(fromValue.allowed_permissions)",fromValue.allowed_permissions)}
          <CustomTextField
          //  select
      variant="outlined"
     value={fromValue.nickname}
      onChange={handleChange}
      fullWidth
      name="nickname"
      placeholder="Nickname"
    >
    </CustomTextField>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
       Incharge    
          </Typography>
         {console.log("console.log(fromValue.allowed_permissions)",fromValue.allowed_permissions)}
          <CustomTextField
          //  select
      variant="outlined"
        value={fromValue.incharge}
      onChange={handleChange}
      fullWidth
      name="incharge"
      placeholder="Incharge"
    >
    </CustomTextField>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
       Outsource Facility  
          </Typography>
         {console.log("console.log(fromValue.allowed_permissions)",fromValue.allowed_permissions)}
          {/* <CustomTextField
          //  select
      variant="outlined"
        value={fromValue.outsourceFacility}
      onChange={handleChange}
      fullWidth
      name="outsourceFacility"
      placeholder="Outsource"
    >
    </CustomTextField> */}

<RadioGroup row  
value={fromValue.outsourceFacility} 
 name="outsourceFacility" 
 onChange={handleChange}>
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
       Country    
          </Typography>
         {console.log("console.log(fromValue.allowed_permissions)",fromValue.allowed_permissions)}
         
         <CustomTextField
      variant="outlined"
       select
    //   label="Select Country"
      value={fromValue.country}
       onChange={handleChange}
      fullWidth
      name="country"
      placeholder="Select Country"
      
    >
      {contries.countries.map((country) => (
        // console.log(country.name,country.code)
        <MenuItem key={country.code} value={country.name}>
          {country.name}
        </MenuItem>
      ))}
    </CustomTextField>
       <Box sx={{display:"flex",justifyContent:"space-between"}}>
       <Button variant="contained" sx={{backgroundColor:"#0a3f73",textTransform:"none",    marginTop: "10%"}} 
             onClick={()=>props.setAddFacility(false)}

            >
              Cancel
            </Button>
       <Button variant="contained" sx={{backgroundColor:"#0a3f73",textTransform:"none",    marginTop: "10%"}} 
             onClick={Save}

            >
              Save
            </Button>
       </Box>
    </Box>
    </Box>
  );
 
    return (
        <div>
            

              <Drawer
               sx={{
                backgroundColor: "transparent",
            '& .MuiDrawer-paper': { // This targets the paper component within the Drawer
                   backgroundColor: "transparent", // Make paper transparent
                   boxShadow: 'none', // Optional: Remove any shadow
                 }
             }}
                anchor="right"
                open={props.addFacility}
                onClose={()=>props.setAddFacility(false)}
              >
                {list()}
              </Drawer>
              
      
      
        </div>
      );
}

export default AddFacility;





//// custom textfield

const CustomTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
      height: '40px', // Adjust height as needed
    },
    '& .MuiSelect-select': {
      height: '56px', // Adjust height as needed
      display: 'flex',
      alignItems: 'center',
    },
  }));
  